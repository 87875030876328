/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Typography, Box, Grid, AppBar } from '@mui/material';
import { useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import Page from '../../../../components/Page';
import { dispatch } from '../../../../redux/store';
import { getUserPortfolio } from '../../../../redux/slices/trades';
import TradeCard from './TradeCard';
import useTrade from '../../../../hooks/useTrade';
import PortfolioHeader from './PortFolioHeader';
import TradesRow from '../trades/TradesRow';

// Reusable TabPanel component
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      style={{ height: '100%', overflowY: 'auto', padding: 0 }}
    >
      {value === index && <Box sx={{ pt: 0.5 }}>{children}</Box>}
    </div>
  );
}

function Portfolio() {
  const { userTrades, quotesData, balance, bookedPl, blockedMargin, brokerage, priceChangeConfig, showAccountDetails } =
    useSelector((state) => state?.trade);

  const { socket } = useTrade();
  const [tabValue, setTabValue] = useState(0); // Use index 0 for "ACTIVE" and 1 for "ORDERS"

  const activeTrades = userTrades?.OPEN
    ? userTrades.OPEN.map((trade) => {
        // eslint-disable-next-line no-unused-vars
        const { exchange, ...restQuoteData } = quotesData[trade?.script?.tv_token] || {};
        return {
          ...trade,
          script: { ...trade.script, ...restQuoteData },
        };
      })
    : [];

  const orderTrades = userTrades?.ORDER
    ? userTrades.ORDER.map((trade) => {
        // eslint-disable-next-line no-unused-vars
        const { exchange, ...restQuoteData } = quotesData[trade?.script?.tv_token] || {};
        return {
          ...trade,
          script: { ...trade.script, ...restQuoteData },
        };
      })
    : [];


  // Update tabValue when the user clicks on a tab
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getUserPortfolio());
      } catch (error) {
        console.error('Error fetching trades:', error);
      }
    };

    fetchData();
  }, []);

  // WebSocket subscription logic
  useEffect(() => {
    const tokenArray =
      userTrades && Object.keys(userTrades).length > 0
        ? [
            ...userTrades?.OPEN?.map((trade) => trade?.script?.tv_token),
            ...userTrades?.PENDING?.map((trade) => trade?.script?.tv_token),
            ...userTrades?.ORDER?.map((trade) => trade?.script?.tv_token),
          ]
        : [];
    if (tokenArray.length) {
      const payload = tokenArray.map((token) => token);

      const sendSubscribeMessage = () => {
        socket.emit('subscribe', payload);
      };

      if (socket && socket.connected) {
        sendSubscribeMessage();
      } else if (socket) {
        socket.on('connect', () => {
          sendSubscribeMessage();
        });
      }
    }

    return () => {
      if (socket && socket.connected) {
        if (tokenArray.length > 0) {
          socket.emit('unsubscribe', tokenArray);
        }
      }
    };
  }, [userTrades, socket, socket?.readyState]);

  // Handle tab changes
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Handle swipe index change
  const handleSwipeChangeIndex = (index) => {
    setTabValue(index);
  };

  return (
    <Page title="Portfolio" sx={{ 
      px: { xs: 1, sm: 2 }, 
      backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#0f172a' : '#f8fafc',
      minHeight: '100%',
      py: 2
    }}>
      <Box mt={0}>
        <PortfolioHeader
          balance={balance}
          bookedPL={bookedPl}
          blockedMargin={blockedMargin}
          brokerage={brokerage}
          activeTrades={activeTrades}
          priceChangeConfig={priceChangeConfig}
          showAccountDetails={showAccountDetails}
        />
        <Box sx={{ 
          width: '100%'          
        }}>
          <AppBar 
                  position="static" 
                  color="transparent" 
                  elevation={0}
                  sx={{
                    borderRadius: '12px',
                    overflow: 'hidden',
                    marginTop: 2,
                    backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.5)' : '#ffffff',
                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
                  }}
                >
            <Tabs
                      value={tabValue}
                      onChange={handleChangeTab}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      sx={{ 
                        width: '100%', 
                        display: 'flex',
                        minHeight: '42px',
                        '& .MuiTabs-indicator': {
                          backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb',
                          height: '3px'
                        },
                        '& .MuiTab-root': {
                          fontFamily: '"Inter", "Roboto", sans-serif',
                          fontSize: '0.85rem',
                          // fontWeight: 600,
                          color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
                          textTransform: 'uppercase',
                          letterSpacing: '0.05em',
                          minHeight: '42px',
                          padding: '4px 12px',
                          '&.Mui-selected': {
                            color: (theme) => theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb',
                            // fontWeight: 700
                          },
                          '&:hover': {
                            color: (theme) => theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb',
                            opacity: 0.8
                          }
                        }
                      }}
                    >
              <Tab 
                label={`ACTIVE TRADES (${activeTrades.length})`}
                value={0} 
                sx={{ flexGrow: 1, maxWidth: 'none' }} 
              />
              <Tab 
                label={`CLOSED TRADES (${orderTrades.length})`} 
                value={1} 
                sx={{ flexGrow: 1, maxWidth: 'none' }} 
              />
            </Tabs>
          </AppBar>
          <SwipeableViews
            index={tabValue}
            onChangeIndex={handleSwipeChangeIndex}
            style={{ minHeight: 'calc(100vh - 200px)', marginTop: '10px' }}
          >
            <TabPanel value={tabValue} index={0}>
              <Grid container spacing={0.25}>
                {activeTrades.length > 0 ? (
                  activeTrades.map((trade) => (
                    <Grid item xs={12} key={trade?.script?._id}>
                      <TradeCard
                        trade={{
                          ...trade,
                          script: {
                            ...trade.script,
                            ...(() => {
                              // eslint-disable-next-line no-unused-vars
                              const { exchange, ...rest } = quotesData[trade?.script?.tv_token] || {};
                              return rest;
                            })(),
                          },
                          type: 'OPEN',
                        }}
                        closeFromPortfolio
                        priceChangeConfig={priceChangeConfig}
                        allowQuantityModification
                      />
                    </Grid>
                  ))
                ) : (
                  <Typography
                    variant="h6"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      textAlign: 'center',
                      py: 3,
                      width: '100%',
                      color: 'text.secondary',
                      fontSize: { xs: '0.95rem', sm: '1.125rem' },
                      // fontWeight: 500,
                    }}
                  >
                    NO ACTIVE TRADES
                  </Typography>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Grid container spacing={0.25}>
                {orderTrades.length > 0 ? (
                  orderTrades.map((trade) => (
                    <Grid item xs={12} key={trade?.script?._id}>
                      <TradesRow trade={trade} key={trade?.script?._id} />
                    </Grid>
                  ))
                ) : (
                  <Typography
                    variant="h6"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      textAlign: 'center',
                      py: 3,
                      width: '100%',
                      color: 'text.secondary',
                      fontSize: { xs: '0.95rem', sm: '1.125rem' },
                      // fontWeight: 500,
                    }}
                  >
                    NO CLOSED TRADES
                  </Typography>
                )}
              </Grid>
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Box>
    </Page>
  );
}

export default Portfolio;
