import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Alert, IconButton, InputAdornment, Card, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

export default function LoginForm() {
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().min(6).required('Password is required'),
  });

  const defaultValues = {
    username: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await login(data.username, data.password);
    } catch (error) {
      console.log({ message: error.message });
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', { message: error.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card
        sx={{
          maxWidth: 400,
          margin: 'auto',
          padding: 3,
          boxShadow: 3,
          borderRadius: 2,
          backgroundColor: 'white',
          border: '1px solid #e0e0e0',
          mb: 18,
        }}
      >
        {/* App Name with Tagline */}
        <Typography variant="h5" align="center" sx={{ mb: 3, fontSize: '24px', fontWeight: 'bold' }}>
          <span style={{ color: 'blue', textTransform: 'uppercase' }}>Path-</span>
          <span style={{ color: 'blueviolet', textTransform: 'uppercase' }}>Shala</span>
        </Typography>

        {/* Form Fields */}
        <Stack spacing={2}>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

          <RHFTextField
            name="username"
            label="Username"
            // inputProps={{ style: { textTransform: 'lowercase' } }}
            sx={{ backgroundColor: '#f9f9f9' }}
            inputProps={{ 
              style: { 
                textTransform: 'lowercase',
                color: '#000000'  // This sets the input text color to black
              } 
            }}
          />
          <RHFTextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
              style: { 
                color: '#000000'  // This sets the input text color to black
              } 
            }}
            sx={{ backgroundColor: '#f9f9f9' }}
          />
        </Stack>

        {/* Login Button */}
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{
            mt: 3,
            background: 'linear-gradient(90deg, rgba(0,123,255,1) 20%, rgba(0,63,138,1) 80%)',
            textTransform: 'uppercase',
            padding: '12px 0',
            // fontWeight: 600,
            fontSize: '14px',
            '&:hover': {
              background: 'linear-gradient(90deg, rgba(0,63,138,1) 20%, rgba(0,123,255,1) 80%)',
            },
          }}
        >
          Start Trading
        </LoadingButton>

        {/* Disclaimer for Paper Trading */}
        <Box mt={2} sx={{ textAlign: 'center' }}>
          <Typography variant="body2" color="textSecondary" sx={{ fontSize: '12px' }}>
            A Paper Trading Application.
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ fontSize: '12px' }}>
            No Real Money Involved
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ fontSize: '10px' }}>
            Simulate trading, learn the market, and sharpen your skills.
          </Typography>
        </Box>
      </Card>
    </FormProvider>
);
}