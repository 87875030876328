import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Paper } from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme, userType }) => ({
  // flexGrow: 1,
  paddingTop: 0,
  marginTop: userType?.isClient ? '5px' : '80px', // Default margin for mobile, 80px for non-client
  // paddingBottom: userType?.isClient ? '64px' : '0', // Add padding bottom only for client
  overflowY: 'auto',
  height: userType?.isClient ? `calc(100vh - 70px)` : `calc(100vh - 80px)`, // Adjust height for client
  [theme.breakpoints.up('lg')]: {
    marginTop: userType?.isClient ? '5px' : '80px', // Larger margin for desktop, 80px for non-client
    paddingLeft: 16,
    paddingRight: 16,
    width: userType?.isClient ? '100%' : `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
    // paddingBottom: userType?.isClient ? '64px' : '0',
    overflowY: 'auto',
    height: userType?.isClient ? `calc(100vh - 70px)` : `calc(100vh - 80px)`,
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const { themeLayout } = useSettings();
  const { userType } = useAuth();
  const isDesktop = useResponsive('up', 'lg');
  console.log("user type",userType)
  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  // console.log({
  //   pt: { xs: 0, lg: 0 },
  //   pb: userType?.isClient
  //     ? { xs: `${HEADER.DASHBOARD_DESKTOP_HEIGHT}px` }
  //     : { xs: `${HEADER.DASHBOARD_DESKTOP_HEIGHT}px`, lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT}px` },
  // });

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {!userType?.isClient && isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: 0,
              lg: 0
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
            mt: {
              xs: '10px',
              lg: '100px'
            }
          }}
        >
          <Outlet />
        </Box>
        {userType?.isClient && (
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>{<NavbarHorizontal />}</Paper>
        )}
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
     }}
    >
      {!userType?.isClient && <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />}

      {!userType?.isClient && <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />}

      <MainStyle
        collapseClick={collapseClick}
        userType={userType}
        sx={{
          pt: { xs: 0, lg: 0 },
          // pb: userType?.isClient
          //   ? { xs: `${HEADER.DASHBOARD_DESKTOP_HEIGHT}px` }
          //   : { xs: `${HEADER.DASHBOARD_DESKTOP_HEIGHT}px`, lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT}px` },
        }}
      >
        <Outlet />
      </MainStyle>
      {userType?.isClient && (
        <NavbarHorizontal />
      )}
    </Box>
  );
}
