import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, useTheme } from '@mui/material';
import { fNumber } from '../../../../utils/formatNumber';

const PriceWithColor = ({ price, variant, digits = 2 }) => {
  const [color, setColor] = useState(null);
  const prevPriceRef = useRef(price);
  const theme = useTheme();

  useEffect(() => {
    const prevPrice = prevPriceRef.current;
    console.log("price",price,"prevPrice",prevPrice)
    if (price > prevPrice) {
      setColor(theme.palette.mode === 'dark' ? '#4ade80' : '#16A34A');
    } else if (price < prevPrice) {
      setColor(theme.palette.mode === 'dark' ? '#ef4444' : '#dc2626');
    } else {
      setColor(theme.palette.mode === 'dark' ? '#f8fafc' : '#1e293b');
    }

    prevPriceRef.current = price;
  }, [price, theme.palette.mode]);

  return (
    <Typography 
      variant={variant} 
      sx={{
        fontFamily: '"Inter", "Roboto", sans-serif',
        // fontWeight: 600,
        letterSpacing: '0.01em',
        color,
        fontSize: { xs: '0.85rem', sm: '0.875rem' },
        transition: 'color 0.2s ease',
        whiteSpace: 'nowrap'
      }}
    >
      {fNumber(price || 0, digits)}
    </Typography>
  );
};

PriceWithColor.propTypes = {
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  variant: PropTypes.string,
  digits: PropTypes.number,
};

export default PriceWithColor;
