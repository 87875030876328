import PropTypes from 'prop-types';
import { memo, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// @mui
import { Stack, Box } from '@mui/material';
//
import { NavListRoot } from './NavList';
import { getFlashMessages } from '../../../redux/slices/operator';

// ----------------------------------------------------------------------

const hideScrollbar = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

NavSectionHorizontal.propTypes = {
  navConfig: PropTypes.array,
};

function NavSectionHorizontal({ navConfig }) {
  const dispatch = useDispatch();

  // Fetch messages from Redux state
  const messages = useSelector((state) => state?.operator?.flashMessages?.data || []);

  useEffect(() => {
    dispatch(getFlashMessages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isPaused, setIsPaused] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Track loading state for the messages
  const scrollerRef = useRef(null); // Ref to access the scroll box

  // Combine messages into a single scrolling string
  const scrollerContent = messages.map((msg) => msg?.message).join(' | ');

  // Calculate animation duration based on the length of the messages
  const scrollSpeed = scrollerContent.length > 0 ? Math.max(10, scrollerContent.length * 0.1) : 50; // Fast scrolling

  const toggleAnimation = () => {
    setIsPaused((prev) => !prev);
  };

  // Reset the scroll position when the animation ends
  const handleAnimationEnd = () => {
    if (scrollerRef.current) {
      scrollerRef.current.style.animation = 'none'; // Remove animation
      // Force reflow to reset animation
      // eslint-disable-next-line no-void
      void scrollerRef.current.offsetWidth;
      scrollerRef.current.style.animation = `scroll ${scrollSpeed}s linear infinite`; // Reapply animation
    }
  };

  useEffect(() => {
    // If messages have been loaded, stop the loading indicator
    if (messages.length > 0) {
      setIsLoading(false);
    }
  }, [messages]);

  return (
    <Stack direction="column" sx={{ bgcolor: 'background.neutral', borderRadius: 1 }}>
      {/* Infinite Scroller */}
      {messages.length > 0 &&
        !isLoading && ( // Conditionally render scroller after messages are loaded
          <Box
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              position: 'relative',
              width: '100%',
              backgroundColor: 'background.default',
              border: '1px solid',
              borderColor: 'primary.main',
              color: '#16A34A',
              height: '30px',
            }}
          >
            <Box
              ref={scrollerRef}
              onClick={toggleAnimation}
              onAnimationEnd={handleAnimationEnd}
              sx={{
                display: 'inline-block',
                animation: `scroll ${scrollSpeed}s linear infinite`,
                animationPlayState: isPaused ? 'paused' : 'running',
                cursor: 'pointer',
                '@keyframes scroll': {
                  from: { transform: `translateX(${Math.min(20, scrollerContent.length * 0.5)}%)` }, // Dynamic start position
                  to: { transform: 'translateX(-100%)' }, // Always scroll out of view
                },
              }}
            >
              <span style={{ padding: '0 2rem', fontWeight: 'bold', fontSize: '14px' }}>{scrollerContent}</span>
            </Box>
          </Box>
        )}

      {/* Navigation Items */}
      <Stack direction="row" justifyContent="center" sx={{ py: 1, ...hideScrollbar }}>
        {navConfig.map((group, i) => (
          <Stack key={group.subheader || i} direction="row" flexShrink={0} flexGrow={1}>
            {group.items.map((list) => (
              <NavListRoot key={list.title + list.path} list={list} sx={{ flexGrow: 1 }} />
            ))}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export default memo(NavSectionHorizontal);
