/* eslint-disable react-hooks/exhaustive-deps */
// @mui
import {
  Card,
  Container,
  Box,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableBody,
} from '@mui/material';

// React and Redux
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';

// Routes and Utilities
import { ExpandMoreOutlined } from '@mui/icons-material';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';

// Components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { dispatch } from '../../../redux/store';
import { getAllBanScripts, getBanScripts } from '../../../redux/slices/operator';
import { getInstrumentList } from '../../../redux/slices/trades';
import BanScriptsForm from './BanScriptsForm';
// ----------------------------------------------------------------------

export default function BanScriptsList() {
  const { themeStretch } = useSettings();
  const location = useLocation();

  useEffect(() => {
    dispatch(getBanScripts());
    dispatch(getAllBanScripts());
    dispatch(getInstrumentList());
  }, []);

  const resultData = useSelector((state) => state?.operator?.banScripts);
  const allBanScripts = useSelector((state) => state?.operator?.superAdminBanScripts);
  const instrumentData = useSelector((state) => state?.trade?.instrumentData);
  const banScriptsInfo = resultData?.resultData || {};
  const banScripts = banScriptsInfo?.securities_ban_result || [];
  const entrants = banScriptsInfo?.possible_entrants_result || [];
  const exits = banScriptsInfo?.possible_exits_result || [];

  return (
    <Page title="Ban Scripts">
      <Container maxWidth={themeStretch ? false : 'lg'} sx={{ px: { xs: 2, md: 4 }, py: { xs: 2, md: 3 } }}>
        <HeaderBreadcrumbs
          heading="BAN-SCRIPTS REPORT"
          links={[
            {
              name: 'Dashboard',
              href: `/${location.pathname.slice(1).split('/')[0]}`,
            },
            { name: 'BAN-SCRIPTS', href: PATH_DASHBOARD.operatorRole.clients.new },
          ]}
        />

        {/* Add the form component above the list */}
        <BanScriptsForm instrumentList={instrumentData?.docs || []} allBanScripts={allBanScripts} />

        <Card
          sx={{
            p: { xs: 1, md: 2 },
            boxShadow: 2,
            borderRadius: 3,
            bgcolor: 'background.paper',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: { md: 'center' },
              gap: 3,
              mb: 3,
            }}
          >
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontSize: '0.875rem',
              }}
            >
              Data last updated on{' '}
              <Box component="span" sx={{ color: '#16A34A', display: 'inline' }}>
                {banScriptsInfo?.date || 'N/A'}
              </Box>
            </Typography>
          </Box>

          {/* Validate and display data or no-data message */}
          {!banScripts?.length && !entrants?.length && !exits?.length ? (
            <Typography
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '1.2rem',
                color: 'text.secondary',
                mt: 3,
              }}
            >
              No Data Found
            </Typography>
          ) : (
            <>
              {/* Reusable Accordion Component */}
              {[{ title: 'Securities Currently in Ban', data: banScripts }].map(({ title, data }, index) => (
                <Accordion key={index} defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls={`${title.toLowerCase().replace(' ', '-')}-content`}
                    id={`${title.toLowerCase().replace(' ', '-')}-header`}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      textTransform="uppercase"
                      color={title === 'Securities Currently in Ban' ? 'red' : ''}
                    >
                      {title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer>
                      <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                          <TableRow sx={{ bgcolor: 'primary.main' }}>
                            <TableCell>SYMBOL NAME</TableCell>
                            <TableCell>CURRENT-MWPL %</TableCell>
                            <TableCell>PREVIOUS-MWPL %</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.length > 0 ? (
                            data.map((item, idx) => (
                              <TableRow
                                key={idx}
                                sx={{
                                  bgcolor: idx % 2 === 0 ? 'background.default' : 'action.hover',
                                }}
                              >
                                <TableCell sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                                  {item?.symbol_name || 'N/A'}
                                </TableCell>
                                <TableCell sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                                  {item?.current_percent || 'N/A'}
                                </TableCell>
                                <TableCell sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                                  {item?.previous_percent || 'N/A'}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={3}>
                                <Typography align="center">No data found for this category.</Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              ))}
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
