import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { useSnackbar } from 'notistack';
// import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// @mui
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { useLocation } from 'react-router';

// hooks
import useTabs from '../../../hooks/useTabs';
import useSettings from '../../../hooks/useSettings';
import useTable, { emptyRows } from '../../../hooks/useTable';

// redux-slice
import { dispatch } from '../../../redux/store';
import { deleteActivityLogs, getActivityLogs } from '../../../redux/slices/operator';

// components
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../../components/table';
import TableLoader from '../../../components/table/TableLoader';
import LogFilterToolbar from './LogFilterToolbar';
// sections
import ActivityLogTableRow from './ActivityLogTableRow';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

const ACTIVITY_OPTIONS = ['all', 'trade', 'limit', 'fund', 'account'];

const TABLE_HEAD = [
  { id: 'type', label: 'Type', align: 'left' },
  { id: 'message', label: 'Description', align: 'left' },
  { id: 'creator.username', label: 'CreatedBy', align: 'left' },
  { id: 'userId.username', label: 'ForUser', align: 'left' },
  { id: 'createdAt', label: 'DateTime', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function ActivityLogList() {
  const {
    dense,
    page,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
  } = useTable({
    defaultRowsPerPage: 100,
    defaultOrderBy: 'createdAt',
    defaultOrder: 'desc',
  });

  const { themeStretch } = useSettings();

  // const navigate = useNavigate();

  // const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const { user } = useAuth();

  const [filterName, setFilterName] = useState('');

  const [openDialog, setOpenDialog] = useState(false);

  const [order, setOrder] = useState('desc');

  const OperatorData = useSelector((state) => state.operator);

  // const [currentPage, setCurrentPage] = useState(1);

  // const tableData = OperatorData?.activityLogs?.docs || [];
  const [tableData, setTableData] = useState([]);

  const { currentTab: filterActivity, setCurrentTab } = useTabs('all');

  useEffect(() => {
    // dispatch(getActivityLogs(10, 1, filterActivity, order));
    dispatch(
      getActivityLogs({
        page: 1,
        pageSize: 100,
        sortBy: 'createdAt',
        sortOrder: 'desc',
        search: filterName,
        type: filterActivity,
      })
    );
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTableData(OperatorData?.activityLogs?.docs || []);
  }, [OperatorData?.activityLogs?.docs]);

  const handleFilterName = (filterObj) => {
    const { description, createdBy, forUser, startDate, endDate, userRoles, statuses } = filterObj;
    try {
      dispatch(
        getActivityLogs({
          page: 1,
          pageSize: 100,
          sortBy: orderBy,
          sortOrder: order,
          search: filterName,
          type: filterActivity,
          description,
          createdBy,
          forUser,
          startDate,
          endDate,
          userRoles,
          statuses,
        })
      );
      setFilterName(filterName);
    } catch (error) {
      if (axios.isCancel(error) || error) {
        console.log(error);
      }
    }
  };

  // const handleFilterActivity = (name) => {
  //   setCurrentTab(name);
  //   dispatch(getActivityLogs(rowsPerPage, 1, name, order));
  // };

  const handleDeleteRow = (id) => {
    const deleteRow = tableData.filter((row) => row.id !== id);
    setSelected([]);
    setTableData(deleteRow);
  };

  const handleDeleteRows = async (selected) => {
    try {
      const response = await dispatch(deleteActivityLogs(selected));
      if (response) {
        dispatch(
          getActivityLogs({
            page: 1,
            pageSize: rowsPerPage,
            sortBy: orderBy,
            sortOrder: order,
            search: filterName,
            type: filterActivity,
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
    setSelected([]);
  };

  // const dataFiltered = applySortFilter({
  //   tableData,
  //   comparator: getComparator(order, orderBy),
  //   filterName,
  //   filterActivity,
  //   rowsPerPage,
  //   currentPage,
  // });
  const dataFiltered = tableData;

  const denseHeight = dense ? 52 : 72;

  const isNotFound = (!dataFiltered.length && !!filterName) || (!dataFiltered.length && !!filterActivity);

  return (
    <Page title="Activity Logs">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Activity Logs"
          links={[
            { name: 'Dashboard', href: `/${location.pathname.slice(1).split('/')[0]}` },
            {
              name: 'logs',
            },
          ]}
        />
        <Card>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterActivity}
            onChange={(e, value) => {
              setCurrentTab(value);

              dispatch(
                getActivityLogs({ page: 1, pageSize: rowsPerPage, sortBy: orderBy, sortOrder: order, type: value })
              );
            }}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {ACTIVITY_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab} value={tab} />
            ))}
          </Tabs>

          <Divider />

          <LogFilterToolbar
            handleFilterName={(firlterObj) => {
              handleFilterName(firlterObj);
            }}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row._id)
                    )
                  }
                  actionsPosition="left"
                  actions={
                    <>
                      <Tooltip title="Delete">
                        <IconButton color="default" onClick={() => setOpenDialog(true)}>
                          <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                      </Tooltip>
                      <Dialog
                        open={openDialog}
                        onClose={() => setOpenDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">{'Confirm Deletion'}</DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete the selected rows?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => setOpenDialog(false)} color="primary">
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              handleDeleteRows(selected);
                              setOpenDialog(false);
                            }}
                            color="primary"
                            autoFocus
                          >
                            Confirm
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={user?.role?.rolename === 'superAdmin' ? [{ id: '#' }, ...TABLE_HEAD] : TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={(id) => {
                    onSort(id);
                    dispatch(
                      getActivityLogs({
                        pageSize: rowsPerPage,
                        page: 1,
                        search: filterName,
                        type: filterActivity,
                        sortOrder: order === 'asc' ? 'desc' : 'asc',
                        sortBy: id,
                      })
                    );
                    setOrder(order === 'asc' ? 'desc' : 'asc');
                  }}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row._id)
                    )
                  }
                />

                <TableBody>
                  {dataFiltered?.length > 0 &&
                    !OperatorData?.isLoading &&
                    dataFiltered.map((row) => (
                      <ActivityLogTableRow
                        key={row?._id}
                        row={row}
                        filterActivity={filterActivity}
                        selected={selected.includes(row?._id)}
                        onSelectRow={() => onSelectRow(row?._id)}
                        onDeleteRow={() => handleDeleteRow(row?.id)}
                        loggedUserRole={user?.role?.rolename}
                      />
                    ))}

                  {OperatorData?.isLoading && <TableLoader />}

                  <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />

                  {dataFiltered?.length === 0 && !OperatorData?.isLoading && <TableNoData isNotFound={isNotFound} />}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              component="div"
              rowsPerPageOptions={[100, 300, 500]}
              count={OperatorData?.activityLogs?.totalDocs || 100}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={false}
              page={OperatorData?.activityLogs?.page - 1}
              onPageChange={(e) => {
                if (e.target.dataset.testid === 'KeyboardArrowRightIcon') {
                  // setCurrentPage(OperatorData?.activityLogs?.page + 1);
                  dispatch(
                    getActivityLogs({
                      pageSize: rowsPerPage,
                      page: OperatorData?.activityLogs?.page + 1,
                      type: filterActivity,
                      sortBy: orderBy,
                      sortOrder: order,
                      search: filterName,
                    })
                  );
                } else {
                  // setCurrentPage(OperatorData?.activityLogs?.page - 1);
                  dispatch(
                    getActivityLogs({
                      pageSize: rowsPerPage,
                      page: OperatorData?.activityLogs?.page - 1,
                      type: filterActivity,
                      sortBy: orderBy,
                      sortOrder: order,
                      search: filterName,
                    })
                  );
                }
              }}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(event.target.value);
                dispatch(
                  getActivityLogs({
                    pageSize: event.target.value,
                    page: 1,
                    type: filterActivity,
                    sortBy: orderBy,
                    sortOrder: order,
                    search: filterName,
                  })
                );
              }}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

// function applySortFilter({ tableData, comparator, filterName, filterActivity }) {
//   const stabilizedThis = tableData?.length > 0 && tableData.map((el, index) => [el, index]);

//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });

//   tableData = stabilizedThis.map((el) => el[0]);

//   if (filterName) {
//     tableData = tableData.filter((item) => item.description.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
//   }

//   if (filterActivity !== 'all') {
//     tableData = tableData.filter((item) => item.activityType === filterActivity);
//   }

//   return tableData;
// }
