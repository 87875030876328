import React, { useState, useEffect } from 'react';
import { Box, Card, Grid, Typography, Button, Container, alpha, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axiosInstance from '../../utils/axios';
import Page from '../../components/Page';
import { fDateTime, fTime } from '../../utils/formatTime';

const MarketTiming = () => {
  const [marketTimings, setMarketTimings] = useState([]);
  const [currentTime] = useState(new Date());
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    fetchMarketTimings();
  }, []);

  const fetchMarketTimings = async () => {
    try {
      const response = await axiosInstance.get('segement/user/market-timings');
      setMarketTimings(response.data.data);
    } catch (error) {
      console.error('Error fetching market timings:', error);
    }
  };

  const checkMarketStatus = (session) => {
    if (session.isHoliday) {
      return {
        status: 'Holiday',
        color: theme.palette.warning.main,
        bgcolor: alpha(theme.palette.warning.main, 0.16)
      };
    }

    const now = currentTime;
    const sessionStart = new Date(session.startTime);
    const sessionEnd = new Date(session.endTime);

    console.log("now",sessionStart,sessionEnd,now)

    if (now >= sessionStart && now <= sessionEnd) {
      return {
        status: 'Open',
        color: theme.palette.success.main,
        bgcolor: alpha(theme.palette.success.main, 0.16)
      };
    }
    return {
      status: 'Closed',
      color: theme.palette.error.main,
      bgcolor: alpha(theme.palette.error.main, 0.16)
    };
  };

  const formatStartEndTime = (startTime, endTime) => {
    const today = new Date();
    const [startHours, startMinutes] = startTime.split(':');
    const [endHours, endMinutes] = endTime.split(':');

    const startDate = new Date(today.setHours(startHours, startMinutes, 0));
    const endDate = new Date(today.setHours(endHours, endMinutes, 0));

    const startFormatted = fTime(startTime);
    const endFormatted = fTime(endTime);

    if (startDate.toDateString() !== endDate.toDateString()) {
      return {
        start: `${startFormatted} (${fDateTime(startTime)})`,
        end: `${endFormatted} (${fDateTime(endTime)})`
      };
    }

    return {
      start: startFormatted,
      end: endFormatted
    };
  };

  const currentDayDate = format(new Date(), 'EEE, dd MMM yyyy');

  return (
    <Page title="Market Timings">
      <Container>
        <Box sx={{ mb: 3, mt: 2, position: 'relative' }}>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography
              variant="h6"
              sx={{
                color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155',
                fontSize: '1rem',
                fontWeight: 600
              }}
            >
              Market Timings
            </Typography>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
              sx={{
                color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
                borderColor: (theme) => theme.palette.mode === 'dark' ? '#334155' : '#e2e8f0',
                '&:hover': {
                  borderColor: (theme) => theme.palette.mode === 'dark' ? '#475569' : '#cbd5e1',
                  backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(51, 65, 85, 0.08)' : 'rgba(226, 232, 240, 0.08)'
                }
              }}
            >
              Back
            </Button>
          </Box>

          <Typography 
              variant="subtitle1" 
              sx={{ 
                color: 'text.secondary',
                fontFamily: theme.typography.fontFamily,
                fontWeight: 500
              }}
            >
              {currentDayDate}
            </Typography>
          
          <Grid container spacing={3}>
            {marketTimings.map((segment, index) => {
              const marketStatus = checkMarketStatus(segment);
              const { start, end } = formatStartEndTime(segment.startTime, segment.endTime);

              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    sx={{
                      p: 2,
                      height: '100%',
                      position: 'relative',
                      boxShadow: theme.shadows[3],
                      borderRadius: 2,
                      transition: theme.transitions.create(['box-shadow', 'transform'], {
                        duration: theme.transitions.duration.shorter
                      }),
                      '&:hover': {
                        boxShadow: theme.shadows[6],
                        transform: 'scale(1.02)'
                      }
                    }}
                  >
                    <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography variant="h6" sx={{ color: 'text.primary', fontWeight: 600 }}>
                        {segment.name}
                      </Typography>
                      <Box
                        sx={{
                          px: 2,
                          py: 0.5,
                          borderRadius: '48px',
                          color: marketStatus.color,
                          bgcolor: marketStatus.bgcolor,
                          display: 'inline-flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontWeight: 700,
                          fontSize: '0.875rem',
                          textTransform: 'uppercase',
                          boxShadow: theme.shadows[1]
                        }}
                      >
                        {marketStatus.status}
                      </Box>
                    </Box>
                    
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1,alignItems: 'center' }}>
                        <Typography 
                          variant="caption" 
                          sx={{ 
                            color: 'text.secondary',
                            display: 'block',
                          }}
                        >
                          Opens
                        </Typography>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            color: 'text.primary',
                            fontWeight: 500
                          }}
                        >
                          {start}
                        </Typography>
                      </Box>
                      
                      <Box  sx={{ display: 'flex', flexDirection: 'row', gap: 1,alignItems: 'center' }}>  
                        <Typography 
                          variant="caption" 
                          sx={{ 
                            color: 'text.secondary',
                            display: 'block',
                          }}
                        >
                          Closes
                        </Typography>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            color: 'text.primary',
                            fontWeight: 500
                          }}
                        >
                          {end}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default MarketTiming;