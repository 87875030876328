import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from '../../../redux/store';
import { getExchangeTimings } from '../../../redux/slices/operator';
import { fDateTime } from '../../../utils/formatTime';
import axiosInstance from '../../../utils/axios';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';

const ExchangeTimingList = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { exchangeTimings } = useSelector((state) => state.operator);
  const [editedTimings, setEditedTimings] = useState({});
  const [errors, setErrors] = useState({});
  const [closedSessions, setClosedSessions] = useState({});
  const [hasSessionChanges, setHasSessionChanges] = useState({});
  const [sessionErrors, setSessionErrors] = useState({});

  console.log('sessionErrors', sessionErrors);
  console.log('editedTimings', editedTimings);

  useEffect(() => {
    dispatch(getExchangeTimings());
  }, [dispatch]);

  useEffect(() => {
    const initialClosedSessions = {};
    exchangeTimings?.forEach((exchange) => {
      initialClosedSessions[exchange._id] = exchange.closed_sessions || [];
    });
    setClosedSessions(initialClosedSessions);
  }, [exchangeTimings]);

  const validateSession = (startTime, endTime) => {
    if (!startTime || !endTime) {
      return 'Both start and end time are required';
    }
    if (startTime >= endTime) {
      return 'Start time must be before end time';
    }
    return null;
  };

  const handleDelayChange = (exchangeId, value, field) => {
    setEditedTimings((prev) => ({
      ...prev,
      [exchangeId]: {
        ...prev[exchangeId],
        [field]: value,
      },
    }));

    const delay = parseInt(value, 10);
    if (Number.isNaN(delay) || delay < 0 || delay > 60) {
      setErrors((prev) => ({
        ...prev,
        [exchangeId]: {
          ...prev[exchangeId],
          [field]: 'Delay must be between 0 and 60 minutes',
        },
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        [exchangeId]: {
          ...prev[exchangeId],
          [field]: null,
        },
      }));
    }
  };

  const handleClosedSessionChange = (exchangeId, sessionIndex, field, value) => {
    setClosedSessions((prev) => {
      const sessions = [...prev[exchangeId]];
      sessions[sessionIndex] = {
        ...sessions[sessionIndex],
        [field]: value,
      };

      // Validate session after update
      const error = validateSession(sessions[sessionIndex].startTime, sessions[sessionIndex].endTime);
      setSessionErrors((prev) => ({
        ...prev,
        [exchangeId]: {
          ...prev?.[exchangeId],
          [sessionIndex]: error,
        },
      }));

      return {
        ...prev,
        [exchangeId]: sessions,
      };
    });
    setHasSessionChanges((prev) => ({
      ...prev,
      [exchangeId]: true,
    }));
  };

  const addClosedSession = (exchangeId) => {
    setClosedSessions((prev) => ({
      ...prev,
      [exchangeId]: [...(prev[exchangeId] || []), { startTime: '', endTime: '' }],
    }));
    setHasSessionChanges((prev) => ({
      ...prev,
      [exchangeId]: true,
    }));
  };

  const removeClosedSession = (exchangeId, index) => {
    setClosedSessions((prev) => ({
      ...prev,
      [exchangeId]: prev[exchangeId].filter((_, i) => i !== index),
    }));
    setSessionErrors((prev) => {
      const newErrors = { ...prev };
      if (newErrors[exchangeId]) {
        delete newErrors[exchangeId][index];
      }
      return newErrors;
    });
    setHasSessionChanges((prev) => ({
      ...prev,
      [exchangeId]: true,
    }));
  };

  const handleUpdate = async (exchangeId) => {
    if (errors[exchangeId]?.startDelayTime || errors[exchangeId]?.endEarlyTime) {
      return;
    }

    // Check for session errors
    const hasSessionErrors = Object.values(sessionErrors[exchangeId] || {}).some((error) => error !== null);
    if (hasSessionErrors) {
      enqueueSnackbar('Please fix closed session errors before updating', { variant: 'error' });
      return;
    }

    const updatedTiming = {
      startDelayTime: editedTimings[exchangeId]?.startDelayTime,
      endEarlyTime: editedTimings[exchangeId]?.endEarlyTime,
      closed_sessions: closedSessions[exchangeId].filter((session) => session.startTime && session.endTime),
    };

    try {
      await axiosInstance.put(`/segement/${exchangeId}`, updatedTiming);
      setEditedTimings((prev) => {
        const newState = { ...prev };
        delete newState[exchangeId];
        return newState;
      });
      setHasSessionChanges((prev) => ({
        ...prev,
        [exchangeId]: false,
      }));
      enqueueSnackbar('Exchange timing updated successfully', { variant: 'success' });
      dispatch(getExchangeTimings());
    } catch (error) {
      console.error('Failed to update timing:', error);
      enqueueSnackbar('Failed to update exchange timing', { variant: 'error' });
    }
  };

  return (
    <Page title="Exchange Timings">
      <HeaderBreadcrumbs
        heading="Exchange Timings"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Exchange Timings', href: PATH_DASHBOARD.operator.exchangeTimings },
        ]}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Exchange</TableCell>
              <TableCell>Today's Start Time</TableCell>
              <TableCell>Today's End Time</TableCell>
              <TableCell>Start Delay (minutes)</TableCell>
              <TableCell>End Early (minutes)</TableCell>
              <TableCell>Closed Sessions</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exchangeTimings?.map((exchange) => (
              <TableRow key={exchange._id}>
                <TableCell>{exchange.exchange}</TableCell>
                <TableCell>{fDateTime(exchange.startTime)}</TableCell>
                <TableCell>{fDateTime(exchange.endTime)}</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    type="number"
                    defaultValue={exchange.startDelayTime || 0}
                    onChange={(e) => handleDelayChange(exchange._id, e.target.value, 'startDelayTime')}
                    error={!!errors[exchange._id]?.startDelayTime}
                    helperText={errors[exchange._id]?.startDelayTime}
                    inputProps={{ min: 0, max: 60 }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    type="number"
                    defaultValue={exchange.endEarlyTime || 0}
                    onChange={(e) => handleDelayChange(exchange._id, e.target.value, 'endEarlyTime')}
                    error={!!errors[exchange._id]?.endEarlyTime}
                    helperText={errors[exchange._id]?.endEarlyTime}
                    inputProps={{ min: 0, max: 60 }}
                  />
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
                    {closedSessions[exchange._id]?.map((session, index) => (
                      <Box key={index} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                          <TextField
                            size="small"
                            type="time"
                            placeholder="Start Time"
                            value={session.startTime}
                            onChange={(e) =>
                              handleClosedSessionChange(exchange._id, index, 'startTime', e.target.value)
                            }
                            error={!!sessionErrors[exchange._id]?.[index]}
                            inputProps={{
                              step: 60,
                            }}
                          />
                          <TextField
                            size="small"
                            type="time"
                            placeholder="End Time"
                            value={session.endTime}
                            onChange={(e) => handleClosedSessionChange(exchange._id, index, 'endTime', e.target.value)}
                            error={!!sessionErrors[exchange._id]?.[index]}
                            inputProps={{
                              step: 60,
                            }}
                          />
                          <IconButton onClick={() => removeClosedSession(exchange._id, index)} size="small">
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                        {sessionErrors[exchange._id]?.[index] && (
                          <Box sx={{ color: 'error.main', fontSize: '0.85rem' }}>
                            {sessionErrors[exchange._id][index]}
                          </Box>
                        )}
                      </Box>
                    ))}
                    <Button startIcon={<AddIcon />} onClick={() => addClosedSession(exchange._id)} size="small">
                      Add Closed Session
                    </Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    onClick={() => handleUpdate(exchange._id)}
                    disabled={
                      !!errors[exchange._id]?.startDelayTime ||
                      !!errors[exchange._id]?.endEarlyTime ||
                      (!editedTimings[exchange._id]?.startDelayTime &&
                        !editedTimings[exchange._id]?.endEarlyTime &&
                        !hasSessionChanges[exchange._id]) ||
                      Object.values(sessionErrors[exchange._id] || {}).some((error) => error !== null)
                    }
                  >
                    Update
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Page>
  );
};

export default ExchangeTimingList;
