import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Download } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { TableRow, TableCell, IconButton, Tooltip, CircularProgress } from '@mui/material';
import { fDateTime } from '../../../utils/formatTime';

UserFileListRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
};

export default function UserFileListRow({ row, selected }) {
  const { s3Key, username, firstname, lastname, uploadedAt } = row;
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleDownload = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        '/trade/download-pdf', // Ensure this endpoint handles both PDF and XLSX
        { fileKey: s3Key },
        {
          responseType: 'blob',
        }
      );

      // Determine the file extension to set the correct type and handle download
      const fileExtension = s3Key.split('.').pop().toLowerCase(); // Get the file extension

      let fileType;
      if (fileExtension === 'pdf') {
        fileType = 'application/pdf';
      } else if (fileExtension === 'xlsx') {
        fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      } else {
        throw new Error('Unsupported file type');
      }

      // Create a new Blob object with the correct type
      const newFileBlob = new Blob([response.data], { type: fileType });

      // Create a URL for the Blob
      const fileURL = window.URL.createObjectURL(newFileBlob);

      // For PDF, open in a new tab; for XLSX, prompt download
      if (fileExtension === 'pdf') {
        window.open(fileURL, '_blank');
      } else if (fileExtension === 'xlsx') {
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = s3Key; // Set the file name for download
        document.body.appendChild(a);
        a.click();
        a.remove(); // Clean up the DOM
      }
    } catch (error) {
      // setLoading(false);

      if (error.response && error.response.data instanceof Blob) {
        const { data } = error.response;

        if (data.type === 'application/json') {
          data.text().then((text) => {
            const jsonError = JSON.parse(text);
            console.error('Error response JSON:', jsonError);
            enqueueSnackbar(jsonError.message || 'An error occurred.', { variant: 'error' });
          });
        } else {
          console.error('Unexpected Blob type:', data.type);
          enqueueSnackbar('Unexpected error format received.', { variant: 'error' });
        }
      } else if (error.response) {
        console.error('Server error:', error.response);
        enqueueSnackbar(`Error: ${error.response.status} - ${error.response.statusText}`, { variant: 'error' });
      } else if (error.request) {
        console.error('No response from server:', error.request);
        enqueueSnackbar('No response from the server. Please try again.', { variant: 'error' });
      } else {
        console.error('Request error:', error.message);
        enqueueSnackbar(`Request error: ${error.message}`, { variant: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell align="left">{username}</TableCell>
        <TableCell align="left">{firstname || lastname ? `${firstname} ${lastname}` : '-'}</TableCell>
        <TableCell align="left">{s3Key}</TableCell>
        <TableCell align="left">{fDateTime(uploadedAt)}</TableCell>
        <TableCell align="center">
          <Tooltip title="Download">
            <IconButton onClick={handleDownload} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : <Download />}
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  );
}
