/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Grid,
  useTheme,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Close, Delete, Edit, ExpandMore, MoreVert, Restore } from '@mui/icons-material';
import { fDateTime } from '../../../utils/formatTime';
import CloseTradeModal from '../../../sections/@dashboard/Trade/portfolio/CloseTradeModal';
import DeleteTradeModal from './DeleteTradeModal';
import EditTradeModal from './EditTradeModal';
import useAuth from '../../../hooks/useAuth';
import { fColorNumber, fNumber, fSideColor } from '../../../utils/formatNumber';
import RestoreTradeodal from './RestoreTradeodal';
import ExecuteLimitModal from './ExecuteLimitModal';

const TradesTable = ({ trades, onUpdateSuccess, portfolio }) => {
  const { user } = useAuth();
  const openTrades = trades.filter((trade) => trade.type === 'OPEN');
  const limitTrades = trades
    .filter((trade) => ['PENDING', 'EXECUTED', 'CANCELLED'].includes(trade.type))
    .filter((trade) => ['EXECUTED', 'CANCELLED', 'PENDING'].includes(trade.type))
    .sort((a, b) => {
      // Define priority for each type
      const priority = { PENDING: 1, EXECUTED: 2, CANCELLED: 3 };
      // Compare based on the defined priority
      return priority[a.type] - priority[b.type];
    });
  const closedTrades = trades.filter((trade) => trade.type === 'ORDER');
  const activeGroupedPositions = portfolio.filter((trade) => trade.type === 'OPEN');
  const closedGroupedPositions = portfolio.filter((trade) => trade.type === 'ORDER');
  const theme = useTheme();
  const { mode } = theme.palette;
  const showOption = user.role.rolename === 'admin';
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTrade, setSelectedTrade] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleMenuOpen = (event, trade) => {
    setAnchorEl(event.currentTarget);
    setSelectedTrade(trade);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // setSelectedTrade(null);
  };

  const handleMenuItemClick = (action) => {
    setModalType(action);
    setIsModalOpen(true);
    handleMenuClose();
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalType(null);
    // setSelectedTrade(null);
  };

  const renderOpenTrades = () =>
    openTrades.map((trade, index) => (
      <TableRow key={index}>
        {showOption && (
          <TableCell>
            <IconButton onClick={(event) => handleMenuOpen(event, trade)}>
              <MoreVert />
            </IconButton>
          </TableCell>
        )}
        <TableCell>{trade?.script?.tradingsymbol}</TableCell>
        <TableCell sx={{ color: fSideColor(trade?.side) }}>{trade?.side}</TableCell>
        <TableCell>{fNumber(trade?.buy || 0)}</TableCell>
        <TableCell>{fNumber(trade?.sell || 0)}</TableCell>
        <TableCell>{fNumber(trade?.quantity || 0)}</TableCell>
        <TableCell>{fNumber(trade?.currentPrice || 0)}</TableCell>
        <TableCell sx={{ color: fColorNumber(trade?.activeProfitLoss || 0) }}>
          {fNumber(trade?.activeProfitLoss || 0)}
        </TableCell>
        <TableCell>{fNumber(trade?.brokerage || 0)}</TableCell>
        <TableCell>{fNumber(trade?.adminBrokerage || 0)}</TableCell>
        <TableCell>{fNumber(trade?.refundBrokerage || 0)}</TableCell>
        <TableCell>{fNumber(trade?.margin || 0)}</TableCell>
        <TableCell>{trade?.boughtAt ? fDateTime(trade.boughtAt) : '-'}</TableCell>
        <TableCell>{trade?.soldAt ? fDateTime(trade.soldAt) : '-'}</TableCell>
      </TableRow>
    ));
  const renderOpenTradesGrouped = () =>
    activeGroupedPositions.map((trade, index) => (
      <TableRow key={index}>
        {/* {showOption && (
          <TableCell>
            <IconButton onClick={(event) => handleMenuOpen(event, trade)}>
              <MoreVert />
            </IconButton>
          </TableCell>
        )} */}
        <TableCell>{trade?.script?.tradingsymbol}</TableCell>
        <TableCell sx={{ color: fSideColor(trade?.side) }}>{trade?.side}</TableCell>
        <TableCell>{fNumber(trade?.buy || 0)}</TableCell>
        <TableCell>{fNumber(trade?.sell || 0)}</TableCell>
        <TableCell>{fNumber(trade?.quantity || 0)}</TableCell>
        <TableCell>{fNumber(trade?.currentPrice || 0)}</TableCell>
        <TableCell sx={{ color: fColorNumber(trade?.activeProfitLoss || 0) }}>
          {fNumber(trade?.activeProfitLoss || 0)}
        </TableCell>
        <TableCell>{fNumber(trade?.brokerage || 0)}</TableCell>
        <TableCell>{fNumber(trade?.adminBrokerage || 0)}</TableCell>
        <TableCell>{fNumber(trade?.refundBrokerage || 0)}</TableCell>
        <TableCell>{fNumber(trade?.margin || 0)}</TableCell>
        {/* <TableCell>{trade?.boughtAt ? fDateTime(trade.boughtAt) : '-'}</TableCell>
        <TableCell>{trade?.soldAt ? fDateTime(trade.soldAt) : '-'}</TableCell> */}
      </TableRow>
    ));

  const renderClosedTrades = () =>
    closedTrades.map((trade, index) => (
      <TableRow key={index}>
        {showOption && (
          <TableCell>
            <IconButton onClick={(event) => handleMenuOpen(event, trade)}>
              <MoreVert />
            </IconButton>
          </TableCell>
        )}
        <TableCell>{trade?.script?.tradingsymbol}</TableCell>
        <TableCell sx={{ color: fSideColor(trade?.side === 'BUY' ? 'SELL' : 'BUY') }}>
          {trade?.side === 'BUY' ? 'SELL' : 'BUY'}
        </TableCell>
        <TableCell>{fNumber(trade?.buy || 0)}</TableCell>
        <TableCell>{fNumber(trade?.sell || 0)}</TableCell>
        <TableCell>{fNumber(trade?.quantity || 0)}</TableCell>
        <TableCell sx={{ color: fColorNumber(trade?.profit || 0) }}>{fNumber(trade?.profit || 0)}</TableCell>
        <TableCell>{fNumber(trade?.brokerage || 0)}</TableCell>
        <TableCell>{fNumber(trade?.adminBrokerage || 0)}</TableCell>
        <TableCell>{fNumber(trade?.refundBrokerage || 0)}</TableCell>
        <TableCell>{fNumber(trade?.timeDifference || 0)}</TableCell>
        <TableCell>{trade?.boughtAt ? fDateTime(trade.boughtAt) : '-'}</TableCell>
        <TableCell>{trade?.boughtAt ? trade?.boughtType : '-'}</TableCell>
        <TableCell>{trade?.soldAt ? fDateTime(trade.soldAt) : '-'}</TableCell>
        <TableCell>{trade?.soldAt ? trade?.soldType : '-'}</TableCell>
      </TableRow>
    ));
  const renderClosedGroupedTrades = () =>
    closedGroupedPositions.map((trade, index) => (
      <TableRow key={index}>
        {/* {showOption && (
          <TableCell>
            <IconButton onClick={(event) => handleMenuOpen(event, trade)}>
              <MoreVert />
            </IconButton>
          </TableCell>
        )} */}
        <TableCell>{trade?.script?.tradingsymbol}</TableCell>
        <TableCell>{fNumber(trade?.buy || 0)}</TableCell>
        <TableCell>{fNumber(trade?.sell || 0)}</TableCell>
        <TableCell>{fNumber(trade?.quantity || 0)}</TableCell>
        <TableCell sx={{ color: fColorNumber(trade?.profit || 0) }}>{fNumber(trade?.profit || 0)}</TableCell>
        <TableCell>{fNumber(trade?.brokerage || 0)}</TableCell>
        <TableCell>{fNumber(trade?.adminBrokerage || 0)}</TableCell>
        <TableCell>{fNumber(trade?.refundBrokerage || 0)}</TableCell>
        {/* <TableCell>{trade?.boughtAt ? fDateTime(trade.boughtAt) : '-'}</TableCell>
        <TableCell>{trade?.soldAt ? fDateTime(trade.soldAt) : '-'}</TableCell> */}
      </TableRow>
    ));

  const renderLimitTrades = () =>
    limitTrades.map((trade, index) => (
      <TableRow key={index}>
        {
          // eslint-disable-next-line no-nested-ternary
          showOption && trade.type === 'PENDING' ? (
            <TableCell>
              <IconButton onClick={(event) => handleMenuOpen(event, trade)}>
                <MoreVert />
              </IconButton>
            </TableCell>
          ) : showOption ? (
            <TableCell />
          ) : null
        }
        <TableCell>{trade?.script?.tradingsymbol}</TableCell>
        <TableCell>{trade?.script?.exchange?.name}</TableCell>
        <TableCell sx={{ color: fSideColor(trade?.side) }}>{trade?.side}</TableCell>
        <TableCell>{fNumber(trade?.quantity || 0)}</TableCell>
        <TableCell sx={{ color: trade?.type === 'EXECUTED' ? '#16A34A' : trade?.type === 'CANCELLED' ? 'red' : 'blue' }}>
          {trade?.type}
        </TableCell>
        <TableCell>{fNumber(trade?.limit || 0)}</TableCell>
        <TableCell>{fDateTime(trade?.createdAt)}</TableCell>
      </TableRow>
    ));

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      {/* Active Position Grouped */}
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            backgroundColor: mode === 'light' ? theme.palette.primary.lighter : theme.palette.primary.darker,
            textAlign: 'center',
            minHeight: 0,
          }}
        >
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" textTransform="uppercase" textAlign="center">
                Active Positions (Grouped)
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {/* {showOption && <TableCell />} */}
                  <TableCell>TradingSymbol</TableCell>
                  <TableCell>Side</TableCell>
                  <TableCell>BuyRate</TableCell>
                  <TableCell>SellRate</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>CurrentPrice</TableCell>
                  <TableCell>Profit</TableCell>
                  <TableCell>Brokerage</TableCell>
                  <TableCell>AdminBrokerage</TableCell>
                  <TableCell>RefundBrokerage</TableCell>
                  <TableCell>Margin</TableCell>
                  {/* <TableCell>Bought At</TableCell>
                  <TableCell>Sold At</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>{renderOpenTradesGrouped()}</TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      {/* Closed Position Grouped */}
      <Accordion sx={{ py: 1 }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            backgroundColor: mode === 'light' ? theme.palette.primary.lighter : theme.palette.primary.darker,
            textAlign: 'center',
          }}
        >
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" textTransform="uppercase" textAlign="center">
                Closed position (grouped)
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {/* {showOption && <TableCell />} */}
                  <TableCell>TradingSymbol</TableCell>
                  <TableCell>Buy</TableCell>
                  <TableCell>Sell</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Profit</TableCell>
                  <TableCell>Brokerage</TableCell>
                  <TableCell>AdminBrokerage</TableCell>
                  <TableCell>Refund Brokerage</TableCell>
                  {/* <TableCell>Bought At</TableCell>
                  <TableCell>Sold At</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>{renderClosedGroupedTrades()}</TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      {/* Open Trades */}
      <Accordion sx={{ py: 1 }} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            backgroundColor: mode === 'light' ? theme.palette.primary.lighter : theme.palette.primary.darker,
            textAlign: 'center',
          }}
        >
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" textTransform="uppercase" textAlign="center">
                Open Trades
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {showOption && <TableCell />}
                  <TableCell>TradingSymbol</TableCell>
                  <TableCell>Side</TableCell>
                  <TableCell>BuyRate</TableCell>
                  <TableCell>SellRate</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>CurrentPrice</TableCell>
                  <TableCell>Profit</TableCell>
                  <TableCell>Brokerage</TableCell>
                  <TableCell>AdminBrokerage</TableCell>
                  <TableCell>RefundBrokerage</TableCell>
                  <TableCell>Margin</TableCell>
                  <TableCell>BoughtAt</TableCell>
                  <TableCell>SoldAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderOpenTrades()}</TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      {/* Closed Trades */}
      <Accordion sx={{ py: 1 }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            backgroundColor: mode === 'light' ? theme.palette.primary.lighter : theme.palette.primary.darker,
            textAlign: 'center',
          }}
        >
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" textTransform="uppercase" textAlign="center">
                Close Trades
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {showOption && <TableCell />}
                  <TableCell>TradingSymbol</TableCell>
                  <TableCell>Side</TableCell>
                  <TableCell>Buy</TableCell>
                  <TableCell>Sell</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Profit</TableCell>
                  <TableCell>Brokerage</TableCell>
                  <TableCell>AdminBrokerage</TableCell>
                  <TableCell>RefundBrokerage</TableCell>
                  <TableCell>Duration(Min)</TableCell>
                  <TableCell>BoughtAt</TableCell>
                  <TableCell>BoughtType</TableCell>
                  <TableCell>SoldAt</TableCell>
                  <TableCell>SoldType</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderClosedTrades()}</TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      {/* Limit Trades */}
      <Accordion sx={{ py: 1 }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            backgroundColor: mode === 'light' ? theme.palette.primary.lighter : theme.palette.primary.darker,
            textAlign: 'center',
          }}
        >
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" textTransform="uppercase" textAlign="center">
                Limit Trades
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {showOption && <TableCell />}
                  <TableCell>TradingSymbol</TableCell>
                  <TableCell>Exchange</TableCell>
                  <TableCell>Side</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Limit</TableCell>
                  <TableCell>CreatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderLimitTrades()}</TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      {/* Menu Options for Open, Closed and Limit Trades by conditions */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {selectedTrade?.type === 'OPEN' && (
          <>
            <MenuItem onClick={() => handleMenuItemClick('close')}>
              <ListItemIcon>
                <Close color="primary" />
              </ListItemIcon>
              <ListItemText primary="Close" />
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('delete')}>
              <ListItemIcon>
                <Delete color="primary" />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('edit')}>
              <ListItemIcon>
                <Edit color="primary" />
              </ListItemIcon>
              <ListItemText primary="Edit" />
            </MenuItem>
          </>
        )}
        {selectedTrade?.type === 'ORDER' && (
          <>
            <MenuItem onClick={() => handleMenuItemClick('edit')}>
              <ListItemIcon>
                <Edit color="primary" />
              </ListItemIcon>
              <ListItemText primary="Edit" />
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('delete')}>
              <ListItemIcon>
                <Delete color="primary" />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('restore')}>
              <ListItemIcon>
                <Restore color="primary" />
              </ListItemIcon>
              <ListItemText primary={`${selectedTrade.side === 'BUY' ? 'Restore Sell' : 'Restore Buy'}`} />
            </MenuItem>
          </>
        )}
        {['PENDING'].includes(selectedTrade?.type) && (
          <>
            <MenuItem onClick={() => handleMenuItemClick('cancel')}>Modify/Cancel</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('execute')}>Execute</MenuItem>
          </>
        )}
      </Menu>
      {/* Conditions for Open popups of clicked menu option */}
      {isModalOpen && modalType === 'cancel' && (
        <CloseTradeModal
          isOpen={modalType === 'cancel'}
          onClose={handleModalClose}
          trade={selectedTrade}
          onUpdateSuccess={onUpdateSuccess}
          isManualTrade
        />
      )}
      {isModalOpen && modalType === 'close' && (
        <CloseTradeModal
          isOpen={modalType === 'close'}
          onClose={handleModalClose}
          trade={selectedTrade}
          onUpdateSuccess={onUpdateSuccess}
          closeFromTrade
          isManualTrade
        />
      )}
      {isModalOpen && modalType === 'delete' && (
        <DeleteTradeModal
          isOpen={modalType === 'delete'}
          onClose={handleModalClose}
          trade={selectedTrade}
          onUpdateSuccess={onUpdateSuccess}
        />
      )}
      {isModalOpen && modalType === 'execute' && (
        <ExecuteLimitModal
          isOpen={modalType === 'execute'}
          onClose={handleModalClose}
          trade={selectedTrade}
          onUpdateSuccess={onUpdateSuccess}
        />
      )}
      {isModalOpen && modalType === 'restore' && (
        <RestoreTradeodal
          isOpen={modalType === 'restore'}
          onClose={handleModalClose}
          trade={selectedTrade}
          onUpdateSuccess={onUpdateSuccess}
        />
      )}
      {isModalOpen && modalType === 'edit' && (
        <EditTradeModal
          open={modalType === 'edit'}
          onClose={handleModalClose}
          trade={selectedTrade}
          onUserUpdate={onUpdateSuccess}
        />
      )}
    </Box>
  );
};

TradesTable.propTypes = {
  trades: PropTypes.arrayOf(PropTypes.object),
  portfolio: PropTypes.arrayOf(PropTypes.object),
  onUpdateSuccess: PropTypes.func.isRequired,
};

export default TradesTable;
