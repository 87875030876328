const modifyScriptName = (tradingsymbol) =>
  `${tradingsymbol.slice(0, -8)} ${tradingsymbol.slice(-6, -5)}${tradingsymbol.slice(-5, -3).toUpperCase()}`;

export const calculateActiveProfitLoss = (activeTrades, priceConfig) => {
  try {
    // Initialize overall profit or loss
    let overallProfitLoss = 0;
    let blockedMargin = 0;
    // console.log('ACTIVE TRADE', activeTrades);

    // Iterate through each active trade
    activeTrades.forEach((trade) => {
      let multiplicationFactor = 1;
      // Get the buy price and sell price based on the side of the trade
      if (trade.script.exchange.name === 'MCX' || trade.script.exchange.name === 'COMEX') {
        const scriptName = trade.script.name;
        const factor =
          priceConfig[trade.script.exchange.name][scriptName] || priceConfig[trade.script.exchange.name].default || 1;
        multiplicationFactor = factor;
      }
      const price = trade.side === 'BUY' ? trade.script?.depth?.buy[0].price : trade.script?.depth?.sell[0].price;
      // Calculate profit or loss for the trade
      const tradeProfitLoss =
        trade.side === 'BUY'
          ? (price - trade.buy) * multiplicationFactor * trade.quantity
          : (trade.sell - price) * multiplicationFactor * trade.quantity;
      // console.log(tradeProfitLoss);
      // Add the trade profit or loss to the overall profit or loss
      overallProfitLoss += tradeProfitLoss;
      blockedMargin += trade.margin;
    });
    return { overallProfitLoss, blockedMargin };
  } catch (error) {
    console.error('Error calculating overall profit or loss:', error);
    throw error;
  }
};

export default modifyScriptName;
