import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Stack,
  Grid,
  FormControl,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  Paper,
  Typography, // Import Typography for heading
} from '@mui/material';
import { FilterList as FilterListIcon } from '@mui/icons-material';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFTextField } from '../../../components/hook-form';

// Validation schema using Yup
const schema = yup.object().shape({
  tradingSymbol: yup.string().nullable(),
  name: yup.string().nullable(),
  boughtAt: yup.string().nullable(),
  soldAt: yup.string().nullable(),
  timeDuration: yup.number().typeError('Duration must be a number').required('Time Duration is required'), // Added validation for timeDuration
});

ClientLineTradesToolbar.propTypes = {
  onFilterName: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string,
};

export default function ClientLineTradesToolbar({ onFilterName }) {
  const [segments, setSegments] = useState({
    NSE: false,
    MCX: false,
  });
  const [anchorEl, setAnchorEl] = useState(null);

  // Initialize useForm with validation schema
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      tradingSymbol: '',
      name: '',
      boughtAt: '',
      soldAt: '',
      timeDuration: 5, // Default value for timeDuration
    },
  });

  const { handleSubmit, reset, setError } = methods;

  const handleSegmentChange = (event) => {
    setSegments({
      ...segments,
      [event.target.name]: event.target.checked,
    });
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = (data) => {
    // Check for boughtAt and soldAt condition in submit function
    if ((data.boughtAt && !data.soldAt) || (!data.boughtAt && data.soldAt)) {
      if (data.boughtAt && !data.soldAt) {
        setError('soldAt', {
          type: 'manual',
          message: 'Sold At is required if Bought At is provided.',
        });
      } else {
        setError('boughtAt', {
          type: 'manual',
          message: 'Bought At is required if Sold At is provided.',
        });
      }
      return;
    }

    onFilterName({
      ...data,
      segments: Object.keys(segments).filter((segment) => segments[segment]),
    });
  };

  const handleClearAll = () => {
    reset({
      tradingSymbol: '',
      name: '',
      boughtAt: '',
      soldAt: '',
      timeDuration: 5, // Reset the timeDuration field
    });
    setSegments({ NSE: false, MCX: false });

    // Call the onFilterName function with the cleared state
    onFilterName({
      tradingSymbol: '',
      name: '',
      boughtAt: '',
      soldAt: '',
      timeDuration: 5, // Clear timeDuration in the filter
      segments: [],
    });
  };

  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, marginBottom: 2 }}>
      <Typography variant="h6" gutterBottom>
        Filter Trades
      </Typography>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <Grid container spacing={2}>
              {/* Search by Trading Symbol */}
              <Grid item xs={6} md={2}>
                <RHFTextField name="tradingSymbol" label="Script" />
              </Grid>

              {/* Search by Name */}
              <Grid item xs={6} md={2}>
                <RHFTextField name="name" label="User" />
              </Grid>

              {/* Bought At */}
              <Grid item xs={6} md={2}>
                {' '}
                {/* Changed to md={2} */}
                <RHFTextField name="boughtAt" label="Bought At" type="date" InputLabelProps={{ shrink: true }} />
              </Grid>

              {/* Sold At */}
              <Grid item xs={6} md={2}>
                {' '}
                {/* Changed to md={2} */}
                <RHFTextField name="soldAt" label="Sold At" type="date" InputLabelProps={{ shrink: true }} />
              </Grid>

              {/* Time Duration */}
              <Grid item xs={6} md={2}>
                <RHFTextField
                  name="timeDuration"
                  label="Duration (min)"
                  placeholder="Enter duration in minutes"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* Segments Filter */}
              <Grid item xs={6} md={2}>
                <FormControl>
                  <Button
                    aria-controls="segment-menu"
                    aria-haspopup="true"
                    onClick={handleMenuClick}
                    startIcon={<FilterListIcon />}
                    fullWidth
                    sx={{ padding: 2, fontSize: 16 }}
                  >
                    Exchanges
                  </Button>
                  <Menu id="segment-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                    <MenuItem>
                      <FormControlLabel
                        control={<Checkbox checked={segments.NSE} onChange={handleSegmentChange} name="NSE" />}
                        label="NSE"
                      />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel
                        control={<Checkbox checked={segments.MCX} onChange={handleSegmentChange} name="MCX" />}
                        label="MCX"
                      />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel
                        control={<Checkbox checked={segments.COMEX} onChange={handleSegmentChange} name="COMEX" />}
                        label="COMEX"
                      />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel
                        control={<Checkbox checked={segments.WINDEX} onChange={handleSegmentChange} name="WINDEX" />}
                        label="WINDEX"
                      />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel
                        control={<Checkbox checked={segments.NSEIX} onChange={handleSegmentChange} name="NSEIX" />}
                        label="NSEIX"
                      />
                    </MenuItem>
                  </Menu>
                </FormControl>
              </Grid>

              {/* Submit and Clear Buttons */}
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  <Button type="submit" variant="contained" color="primary">
                    Filter
                  </Button>
                  <Button variant="outlined" color="secondary" onClick={handleClearAll}>
                    Clear All
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </FormProvider>
    </Paper>
  );
}
