import { TableRow, TableCell, Typography } from '@mui/material';
import PropTypes from 'prop-types'; // Importing PropTypes
import { fColorNumber, fNumber } from '../../../utils/formatNumber';
import useAuth from '../../../hooks/useAuth';
// import { useTheme } from '@mui/material/styles';

function RiskUserTableRow({ row, onViewRow, selected }) {
  const {
    trader, // Trader field is an object
    broker,
    admin, // Broker field is an object
    balance, // Balance value
    availableBalance, // Available balance
    activeProfitLoss, // Active P&L
    totalProfit, // Total profit
    totalBrokerage, // Total brokerage
  } = row;

  const { user } = useAuth();

  const traderName = trader?.username || 'N/A'; // Get trader name from object
  const brokerName = broker?.username || 'N/A'; // Get broker name from object
  const adminName = admin?.username || 'N/A'; // Get broker name from object

  const showError = trader?.config?.tradeConfig?.autoCloseIntraday === true && availableBalance < 0;
  // Calculate available balance as a percentage of balance
  const availablePercentage = balance ? (availableBalance / balance) * 100 : 0;

  // Determine row background color based on available balance

  return (
    <TableRow hover selected={selected} sx={{ backgroundColor: availableBalance < 0 ? 'red' : 'inherit' }}>
      {/* Trader Name */}
      <TableCell onClick={() => onViewRow(row.trader._id)} sx={{ cursor: 'pointer' }}>
        <Typography variant="subtitle2" color="primary" noWrap>
          {traderName}
        </Typography>
      </TableCell>

      {/* Broker Name */}
      {user?.role?.rolename !== 'broker' && (
        <TableCell>
          <Typography variant="subtitle2" noWrap>
            {brokerName}
          </Typography>
        </TableCell>
      )}

      {/* Admin Name */}
      {user?.role?.rolename !== 'admin' && user?.role?.rolename !== 'broker' && (
        <TableCell>
          <Typography variant="subtitle2" noWrap>
            {adminName}
          </Typography>
        </TableCell>
      )}

      {/* Balance */}
      <TableCell>
        <Typography variant="body2" noWrap>
          {fNumber(balance)} {/* Format balance as currency/number */}
        </Typography>
      </TableCell>

      {/* Available Balance with Percentage */}
      <TableCell>
        <Typography
          variant="body2"
          noWrap
          sx={{ backgroundColor: showError ? 'error.main' : 'inherit', color: showError ? 'yellow' : 'inherit' }}
        >
          {fNumber(availableBalance)} ({availablePercentage.toFixed(2)}%){' '}
          {/* Display available balance with percentage */}
        </Typography>
      </TableCell>

      {/* Active Profit/Loss */}
      <TableCell>
        <Typography variant="body2" color={fColorNumber(activeProfitLoss)} noWrap>
          {fNumber(activeProfitLoss)} {/* Active P&L with color coding */}
        </Typography>
      </TableCell>

      {/* Total Profit */}
      <TableCell>
        <Typography variant="body2" color={fColorNumber(totalProfit)} noWrap>
          {fNumber(totalProfit)} {/* Total profit with color coding */}
        </Typography>
      </TableCell>

      {/* Total Brokerage */}
      <TableCell>
        <Typography variant="body2" noWrap>
          {fNumber(totalBrokerage)} {/* Display total brokerage */}
        </Typography>
      </TableCell>

      {/* IntraDay Config */}
      <TableCell>
        <Typography
          variant="body2"
          noWrap
          sx={{ color: trader?.config?.tradeConfig?.autoCloseIntraday ? '#16A34A' : 'red' }}
        >
          {trader?.config?.tradeConfig?.autoCloseIntraday === true ? 'ON' : 'OFF'} {/* Display total brokerage */}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

RiskUserTableRow.propTypes = {
  row: PropTypes.object.isRequired, // Validate row prop as an object
  onViewRow: PropTypes.func.isRequired, // Function to view row details
  selected: PropTypes.bool.isRequired, // Indicates if the row is selected
};

export default RiskUserTableRow;
