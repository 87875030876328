import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// Main Colors
const PRIMARY = {
  lighter: '#dbeafe', // Light blue
  light: '#60a5fa',  // Blue 400
  main: '#2563eb',   // Blue 600
  dark: '#1d4ed8',   // Blue 700
  darker: '#1e40af', // Blue 800
};

const SECONDARY = {
  lighter: '#bfdbfe', // Blue 200
  light: '#60a5fa',   // Blue 400
  main: '#3b82f6',    // Blue 500
  dark: '#2563eb',    // Blue 600
  darker: '#1d4ed8',  // Blue 700
};

// Semantic Colors
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#16A34A',
  dark: '#229A16',
  darker: '#08660D',
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
};

// Greys for light/dark mode
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.80),
};

// Gradients
const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  secondary: createGradient(SECONDARY.light, SECONDARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

// Chart Colors
const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2563eb', '#60a5fa', '#93c5fd', '#dbeafe'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_12],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: {
      primary: '#1e293b', // Slate 800
      secondary: '#475569', // Slate 600
      disabled: '#94a3b8', // Slate 400
    },
    background: {
      paper: '#fff',
      default: '#fff',
      neutral: GREY[200],
    },
    action: {
      active: GREY[600],
      ...COMMON.action,
    },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#f8fafc', // Slate 50
      secondary: '#e2e8f0', // Slate 200 
      disabled: '#94a3b8', // Slate 400
    },
    background: {
      paper: '#1e293b', // Slate 800
      default: '#0f172a', // Slate 900
      neutral: alpha(GREY[500], 0.16),
    },
    action: {
      active: GREY[500],
      ...COMMON.action,
    },
  },
};

export default palette;
