import React, { useState, useEffect } from 'react';
import { TextField,  Typography, Button, Box } from '@mui/material';
import { FixedSizeList as List } from 'react-window';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { ExpandMore } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import Iconify from '../../../../components/Iconify';
import { dispatch } from '../../../../redux/store';
import {
  addToWatchListSuccess,
  getInstrumentList,
  getWatchList,
  startTradeLoading,
} from '../../../../redux/slices/trades';
import useAuth from '../../../../hooks/useAuth';
import modifyScriptName from '../../../../utils/modifyScriptName';

const segements = ['NSE', 'MCX', 'COMEX', 'WINDEX', 'NSEIX'];

function AddToWatchlist() {
  const [searchText, setSearchText] = useState('');
  const [filteredScripts, setFilteredScripts] = useState([]);
  const tradeData = useSelector((state) => state?.trade);
  const { watchlist, instrumentData } = tradeData;
  const [selectedExchange, setSelectedExchange] = useState(null);
  const { user } = useAuth();
  console.log('user', user);
  const navigate = useNavigate();
  const scripts = instrumentData?.docs || [];
  const userSegements = segements.filter((segement) => {
    if (user[segement]) return user[segement].allow;
    return false;
  });

  useEffect(() => {
    dispatch(getInstrumentList());
    dispatch(getWatchList(false));
  }, []);

  useEffect(() => {
    filterScripts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, watchlist, instrumentData]);

  const filterScripts = () => {
    const allScripts = watchlist ? [].concat(...Object.values(watchlist)) : [];

    const filtered = searchText
      ? scripts.filter(
          (script) =>
            script.tradingsymbol.toLowerCase().includes(searchText.toLowerCase()) &&
            !allScripts.some((s) => s?._id === script._id) &&
            userSegements.includes(script?.exchange?.name)
        )
      : scripts.filter(
          (script) => !allScripts.some((s) => s?._id === script._id) && userSegements.includes(script?.exchange?.name)
        );
    setFilteredScripts(filtered);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleBackclick = () => {
    navigate(-1);
  };

  const handleExchangeClick = (exchange) => {
    setSelectedExchange(selectedExchange === exchange ? null : exchange);
  };

  const handleAddToWatchlist = (script) => {
    dispatch(startTradeLoading());

    const updatedWatchlist = JSON.parse(localStorage.getItem('watchlist')) || {};

    updatedWatchlist[script.exchange.name] = updatedWatchlist[script.exchange.name] || [];

    const scriptIds = new Set(updatedWatchlist[script.exchange.name].map((s) => s._id));

    if (!scriptIds.has(script._id)) {
      updatedWatchlist[script.exchange.name].push(script);
      localStorage.setItem('watchlist', JSON.stringify(updatedWatchlist));
      dispatch(addToWatchListSuccess(updatedWatchlist));
    }

    const updatedFilteredScripts = filteredScripts.filter((s) => s._id !== script._id);
    setFilteredScripts(updatedFilteredScripts);
  };

  // const renderRow = ({ index, style }) => {
  //   const script = filteredScripts[index];
  //   return (
  //     <Grid
  //       container
  //       style={style}
  //       key={script._id}
  //       alignItems="center"
  //       spacing={2}
  //       sx={{ cursor: 'pointer', ':hover': { backgroundColor: 'action.selected' } }}
  //       className="list-item"
  //       onClick={() => handleAddToWatchlist(script)}
  //     >
  //       <Grid item xs={8}>
  //         <Typography>{modifyScriptName(script.tradingsymbol)}</Typography>
  //       </Grid>
  //       <Grid item xs={2}>
  //         <Typography align="center">{script.exchange.name}</Typography>
  //       </Grid>
  //       <Grid item xs={2}>
  //         <Box textAlign="right">
  //           <LoadingButton variant="contained" onClick={() => handleAddToWatchlist(script)}>
  //             Add
  //           </LoadingButton>
  //         </Box>
  //       </Grid>
  //     </Grid>
  //   );
  // };

  return (
    <Box sx={{ 
      backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#0f172a' : '#f8fafc',
      minHeight: '100%',
      padding: { xs: 1, sm: 2 }
    }}>
      <Box sx={{
        position: 'sticky',
        top: 0,
        zIndex: 1100,
        backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#1e293b' : '#ffffff',
        borderRadius: { xs: 0, sm: 1 },
        p: 2,
        mb: 2,
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.06)'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Button
            startIcon={<Iconify icon={'eva:arrow-back-outline'} />}
            variant="contained"
            onClick={handleBackclick}
            sx={{
              backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#2563eb' : '#1d4ed8'
              },
              boxShadow: 'none',
              minWidth: 'auto',
              px: 2,
              py: 1
            }}
          >
            Back
          </Button>
          <Typography 
            variant="h6" 
            sx={{ 
              fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, sans-serif',
              // fontWeight: 600,
              fontSize: { xs: '0.9375rem', sm: '1rem' },
              color: (theme) => theme.palette.mode === 'dark' ? '#f8fafc' : '#1e293b',
              letterSpacing: '-0.01em'
            }}
          >
            ADD TO WATCHLIST
          </Typography>
        </Box>

        <TextField
          fullWidth
          placeholder="Search Scripts"
          value={searchText}
          onChange={handleSearchChange}
          sx={{
            mt: 2,
            '& .MuiOutlinedInput-root': {
              backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(15, 23, 42, 0.3)' : '#f8fafc',
              height: 40,
              '& input': {
                color: (theme) => theme.palette.mode === 'dark' ? '#f8fafc' : '#1e293b',
                '&::placeholder': {
                  color: (theme) => theme.palette.mode === 'dark' ? '#64748b' : '#94a3b8',
                  opacity: 1
                }
              },
              '& fieldset': {
                borderColor: (theme) => theme.palette.mode === 'dark' ? '#334155' : '#e2e8f0',
                borderRadius: 1.5,
                transition: 'border-color 0.2s'
              },
              '&:hover fieldset': {
                borderColor: (theme) => theme.palette.mode === 'dark' ? '#4ade80' : '#16A34A'
              },
              '&.Mui-focused fieldset': {
                borderWidth: '1.5px',
                borderColor: (theme) => theme.palette.mode === 'dark' ? '#4ade80' : '#16A34A'
              }
            }
          }}
        />
      </Box>

      {tradeData.isLoading ? (
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '40vh',
          gap: 2,
          backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#1e293b' : '#ffffff',
          borderRadius: { xs: 0, sm: 1 },
          py: 4
        }}>
          <LoadingButton
            loading
            sx={{
              '.MuiCircularProgress-root': {
                color: (theme) => theme.palette.mode === 'dark' ? '#4ade80' : '#16A34A',
                width: '28px !important',
                height: '28px !important'
              }
            }}
          />
          <Typography
            sx={{
              fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, sans-serif',
              color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
              fontSize: '0.85rem',
              // fontWeight: 500,
              letterSpacing: '0.02em'
            }}
          >
            Loading scripts...
          </Typography>
        </Box>
      ) : (
        <Box sx={{ 
          backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#1e293b' : '#ffffff',
          borderRadius: { xs: 0, sm: 1 },
          overflow: 'hidden'
        }}>
          {searchText ? (
            <List
              height={500}
              itemCount={filteredScripts.length}
              itemSize={64}
              width="100%"
            >
              {({ index, style }) => {
                const script = filteredScripts[index];
                return (
                  <Box
                    style={style}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      px: 2,
                      py: 1,
                      borderBottom: '1px solid',
                      borderColor: (theme) => theme.palette.mode === 'dark' ? '#1e293b' : '#f1f5f9',
                      backgroundColor: (theme) => {
                        if (index % 2 === 0) {
                          return theme.palette.mode === 'dark' ? 'rgba(15, 23, 42, 0.3)' : '#f8fafc';
                        }
                        return 'transparent';
                      },
                      '&:hover': {
                        backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#334155' : '#f1f5f9'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, sans-serif',
                          color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#1e293b',
                          fontSize: '0.875rem'
                        }}
                      >
                        {modifyScriptName(script.tradingsymbol)}
                      </Typography>
                    </Box>
                    
                    <LoadingButton
                      variant="contained"
                      size="small"
                      onClick={() => handleAddToWatchlist(script)}
                      sx={{
                        minWidth: '64px',
                        height: '32px',
                        backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb',
                        '&:hover': {
                          backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#2563eb' : '#1d4ed8'
                        },
                        boxShadow: 'none',
                        fontSize: '0.85rem',
                        fontWeight: 600
                      }}
                    >
                      Add
                    </LoadingButton>
                  </Box>
                );
              }}
            </List>
          ) : (
            <Box>
              {Object.entries(
                filteredScripts.reduce((acc, script) => {
                  const exchange = script.exchange.name || 'Unknown';
                  if (!acc[exchange]) {
                    acc[exchange] = [];
                  }
                  acc[exchange].push(script);
                  return acc;
                }, {})
              ).map(([exchange, scripts]) => (
                <Box key={exchange}>
                  <Box
                    onClick={() => handleExchangeClick(exchange)}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      px: 2,
                      py: 1.5,
                      cursor: 'pointer',
                      borderBottom: '1px solid',
                      borderColor: (theme) => theme.palette.mode === 'dark' ? '#334155' : '#e2e8f0',
                      backgroundColor: (theme) => {
                        if (selectedExchange === exchange) {
                          return theme.palette.mode === 'dark' ? 'rgba(148, 163, 184, 0.1)' : '#f8fafc';
                        }
                        return 'transparent';
                      },
                      '&:hover': {
                        backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(148, 163, 184, 0.05)' : '#f1f5f9'
                      }
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, sans-serif',
                        // fontWeight: 600,
                        fontSize: '0.875rem',
                        color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#1e293b'
                      }}
                    >
                      {exchange}
                    </Typography>
                    <ExpandMore
                      sx={{
                        transform: selectedExchange === exchange ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.2s',
                        color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b'
                      }}
                    />
                  </Box>
                  
                  {selectedExchange === exchange && (
                    <List
                      height={400}
                      itemCount={scripts.length}
                      itemSize={56}
                      width="100%"
                    >
                      {({ index, style }) => {
                        const script = scripts[index];
                        return (
                          <Box
                            style={style}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              px: 2,
                              py: 1,
                              borderBottom: '1px solid',
                              borderColor: (theme) => theme.palette.mode === 'dark' ? '#1e293b' : '#f1f5f9',
                              backgroundColor: (theme) => {
                                if (index % 2 === 0) {
                                  return theme.palette.mode === 'dark' ? 'rgba(15, 23, 42, 0.3)' : '#f8fafc';
                                }
                                return 'transparent';
                              },
                              '&:hover': {
                                backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#334155' : '#f1f5f9'
                              }
                            }}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, sans-serif',
                                  color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#1e293b',
                                  fontSize: '0.875rem'
                                }}
                              >
                                {modifyScriptName(script.tradingsymbol)}
                              </Typography>
                            </Box>
                            
                            <LoadingButton
                              variant="contained"
                              size="small"
                              onClick={() => handleAddToWatchlist(script)}
                              sx={{
                                minWidth: '64px',
                                height: '32px',
                                backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb',
                                '&:hover': {
                                  backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#2563eb' : '#1d4ed8'
                                },
                                boxShadow: 'none',
                                fontSize: '0.85rem',
                                fontWeight: 600
                              }}
                            >
                              Add
                            </LoadingButton>
                          </Box>
                        );
                      }}
                    </List>
                  )}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default AddToWatchlist;
