// @mui
import { styled } from '@mui/material/styles';
import { Button, Popover } from '@mui/material';
// config
import { NAVBAR } from '../../../config';

// ----------------------------------------------------------------------

export const ListItemStyle = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'activeRoot' && prop !== 'activeSub' && prop !== 'subItem' && prop !== 'open',
})(({ activeRoot, activeSub, subItem, open, theme }) => {
  const activeRootStyle = {
    color: theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb', // Professional blue for active items
    backgroundColor: 'transparent',
  };

  return {
    fontSize: '0.75rem',
    margin: theme.spacing(0, 0.3),
    padding: theme.spacing(0, 1),
    color: theme.palette.mode === 'dark' ? '#e2e8f0' : '#475569', // Default text color
    fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, sans-serif',
    letterSpacing: '0.01em',
    height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
    textTransform: 'uppercase',
    transition: theme.transitions.create(['color', 'background-color'], {
      duration: theme.transitions.duration.shorter,
    }),
    '&:hover': {
      color: theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb', // Professional blue on hover
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(96, 165, 250, 0.08)' : 'rgba(37, 99, 235, 0.08)',
    },
    // activeRoot
    ...(activeRoot && {
      ...activeRootStyle,
      '&:hover': { ...activeRootStyle },
    }),
    // activeSub
    ...(activeSub && {
      ...theme.typography.subtitle2,
      color: theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb', // Professional blue for active sub-items
    }),
    // subItem
    ...(subItem && {
      width: '100%',
      margin: 0,
      paddingRight: 0,
      paddingLeft: theme.spacing(1),
      justifyContent: 'space-between',
    }),
    // open
    ...(open && {
      color: theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb', // Professional blue for open menus
    }),
  };
});

// ----------------------------------------------------------------------

export const PaperStyle = styled(Popover)(({ theme }) => ({
  pointerEvents: 'none',
  '& .MuiPopover-paper': {
    width: 160,
    pointerEvents: 'auto',
    padding: theme.spacing(1),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    boxShadow: theme.customShadows.dropdown,
    backgroundColor: theme.palette.mode === 'dark' ? '#1e293b' : '#ffffff',
    borderColor: theme.palette.mode === 'dark' ? '#334155' : '#e2e8f0',
  },
}));
