/* eslint-disable react/jsx-no-undef */
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { useTheme } from '@mui/material/styles';
import { Stack, Card, Typography, Box, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useEffect } from 'react';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import axios from '../../../../utils/axios';
import useAuth from '../../../../hooks/useAuth';
import useSettings from '../../../../hooks/useSettings';

// ----------------------------------------------------------------------

export default function AccountChangePassword() {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const { themeMode } = useSettings();
  const ChangePassWordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('OLD PASSWORD IS REQUIRED'),
    newPassword: Yup.string().min(6, 'PASSWORD MUST BE AT LEAST 6 CHARACTERS').required('NEW PASSWORD IS REQUIRED'),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'CONFIRM PASSWORD AND NEW PASSWORD MUST MATCH')
      .required('CONFIRM PASSWORD IS REQUIRED'),
  });

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(ChangePassWordSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (payload) => {
    try {
      console.log('PAYLOAD', payload);
      const response = await axios.put('/user/change-password', payload);
      reset();
      enqueueSnackbar(response?.data?.message.toUpperCase());
    } catch (error) {
      enqueueSnackbar(error.message.toUpperCase(), { variant: 'error' }); // Displaying error message
    }
  };
  const theme = useTheme();
  const isLaptop = useMediaQuery(theme.breakpoints.up('md')); // Check if screen width is >= 900px

  // Prevent scrolling on mount
  useEffect(() => {
    document.body.style.overflow = 'hidden'; // Disable scroll
    return () => {
      document.body.style.overflow = 'auto'; // Re-enable scroll when component unmounts
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        p: 0,
        overflow: 'visible'
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ width: '100%' }}>
          <RHFTextField
            name="oldPassword"
            type="password"
            label="Old Password"
            InputLabelProps={{ 
              style: { 
                textTransform: 'uppercase',
                color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b'
              } 
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: (theme) => 
                  theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.4)' : 'rgba(241, 245, 249, 0.6)',
                '&:hover': {
                  backgroundColor: (theme) => 
                    theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.6)' : 'rgba(241, 245, 249, 0.8)'
                }
              }
            }}
          />

          <RHFTextField
            name="newPassword"
            type="password"
            label="New Password"
            InputLabelProps={{ 
              style: { 
                textTransform: 'uppercase',
                color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b'
              } 
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: (theme) => 
                  theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.4)' : 'rgba(241, 245, 249, 0.6)',
                '&:hover': {
                  backgroundColor: (theme) => 
                    theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.6)' : 'rgba(241, 245, 249, 0.8)'
                }
              }
            }}
          />

          <RHFTextField
            name="confirmNewPassword"
            type="password"
            label="Confirm New Password"
            InputLabelProps={{ 
              style: { 
                textTransform: 'uppercase',
                color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b'
              } 
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: (theme) => 
                  theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.4)' : 'rgba(241, 245, 249, 0.6)',
                '&:hover': {
                  backgroundColor: (theme) => 
                    theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.6)' : 'rgba(241, 245, 249, 0.8)'
                }
              }
            }}
          />

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{
              alignSelf: 'center',
              textTransform: 'uppercase',
              width: '100%',
              fontFamily: '"Inter", "Roboto", sans-serif',
              fontSize: '0.85rem',
              // fontWeight: 600,
              backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#3b82f6' : '#1d4ed8'
              }
            }}
          >
            Update Password
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Box>
  );
}
