/* eslint-disable no-unused-vars */
import {
  Box,
  IconButton,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputLabel,
  FormControl,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Backspace, ExpandMore } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { PATH_DASHBOARD } from '../../../routes/paths';

const AddToComexWatchlist = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [exchanges, setExchanges] = useState(['ALL', 'CBOT', 'CME', 'COMEX', 'MCX', 'NSE', 'NSEIX', 'NYMEX']);
  const [selectedExchange, setSelectedExchange] = useState('ALL');
  const [scripts, setScripts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedScripts, setSelectedScripts] = useState(() => {
    const storedScripts = localStorage.getItem('TradingView');
    return storedScripts ? JSON.parse(storedScripts) : [];
  });

  const handleWatchListClick = () => {
    // navigate(PATH_DASHBOARD.insurerRole.tradingView.root);
    navigate(-1);
  };

  const handleExchangeChange = (event) => {
    setSelectedExchange(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleAddScript = (scriptId) => {
    setSelectedScripts((prevSelected) => {
      const updatedScripts = prevSelected.includes(scriptId)
        ? prevSelected.filter((id) => id !== scriptId)
        : [...prevSelected, scriptId];

      // Save the updated list of selected scripts to localStorage
      localStorage.setItem('TradingView', JSON.stringify(updatedScripts));
      return updatedScripts;
    });
  };

  const fetchData = async (exchange = '') => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://tv-socket-dev.devqix.in/api/v1/script/all?active=true&limit=300${
          exchange !== 'ALL' ? `&exchange=${exchange}` : ''
        }`
      );
      setScripts(response?.data?.docs || []);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(selectedExchange);
  }, [selectedExchange]);

  const filteredScripts = scripts.filter((script) =>
    script.tradingsymbol.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box sx={{ px: 2, py: 4, backgroundColor: '#f5f5f5', minHeight: 'calc(100% - 64px)' }}>
      {/* Header Box */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          padding: '16px',
          backgroundColor: '#6200ea',
          color: '#fff',
          borderRadius: '8px',
          marginBottom: '16px',
        }}
      >
        <IconButton onClick={handleWatchListClick} sx={{ color: '#fff' }}>
          <Backspace />
        </IconButton>

        <Typography variant="h6" fontWeight="bold">
          ADD SCRIPTS TO WATCHLIST
        </Typography>

        <IconButton sx={{ color: '#fff' }} />
      </Box>

      {/* Exchange Selection and Search Box */}
      <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <FormControl sx={{ minWidth: 140 }}>
          <InputLabel>Select Exchange</InputLabel>
          <Select value={selectedExchange} label="Select Exchange" onChange={handleExchangeChange} fullWidth>
            {exchanges.map((exchange) => (
              <MenuItem key={exchange} value={exchange}>
                {exchange}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Search"
          variant="outlined"
          size="small"
          sx={{ width: '200px' }}
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </Box>

      {/* Loader while API is loading */}
      {loading ? (
        <Box display="flex" justifyContent="center" sx={{ my: 5 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {selectedExchange === 'ALL' ? (
            exchanges.map((exchange) => (
              <Accordion key={exchange} sx={{ borderBottom: '1px solid #ddd' }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>{exchange}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    {filteredScripts
                      .filter((script) => script.exchange === exchange)
                      .map((script) => (
                        <Box
                          key={script._id}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '12px 16px',
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            marginBottom: '12px',
                            backgroundColor: selectedScripts.includes(script._id) ? '#f0f0f0' : 'transparent',
                            '&:hover': { backgroundColor: '#f9f9f9' },
                          }}
                        >
                          <Typography variant="body1">{script.tradingsymbol}</Typography>
                          <IconButton onClick={() => handleAddScript(script._id)}>
                            {selectedScripts.includes(script._id) ? (
                              <Typography color="primary">✔</Typography>
                            ) : (
                              <Typography color="primary">+</Typography>
                            )}
                          </IconButton>
                        </Box>
                      ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Accordion key={selectedExchange} sx={{ borderBottom: '1px solid #ddd' }}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>{selectedExchange}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {filteredScripts
                  .filter((script) => script.exchange === selectedExchange)
                  .map((script) => {
                    console.log('Selected scripts:', selectedScripts);
                    console.log('Script ID:', script._id);
                    return (
                      <Box
                        key={script._id}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '12px 16px',
                          border: '1px solid #ddd',
                          borderRadius: '8px',
                          marginBottom: '12px',
                          backgroundColor: selectedScripts.includes(script._id) ? '#f0f0f0' : 'transparent',
                          '&:hover': { backgroundColor: '#f9f9f9' },
                        }}
                      >
                        <Typography variant="body1">{script.tradingsymbol}</Typography>
                        <IconButton onClick={() => handleAddScript(script._id)}>
                          {selectedScripts.includes(script._id) ? (
                            <Typography color="primary">✔</Typography>
                          ) : (
                            <Typography color="primary">+</Typography>
                          )}
                        </IconButton>
                      </Box>
                    );
                  })}
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
      )}
    </Box>
  );
};

export default AddToComexWatchlist;
