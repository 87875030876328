import { memo } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { AppBar } from '@mui/material';
// components
import { NavSectionHorizontal } from '../../../components/nav-section';
import clientNavConfig from './ClientNavConfig';

// ----------------------------------------------------------------------
const RootStyle = styled(AppBar)(({ theme }) => ({
  transition: theme.transitions.create(['top', 'background-color', 'box-shadow'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.standard,
  }),
  width: '100%',
  position: 'fixed',
  zIndex: theme.zIndex.appBar,
  top: 'auto',
  bottom: 0,
  height: 56, // Fixed height
  backgroundColor: theme.palette.mode === 'dark' ? '#1e293b' : '#ffffff',
  boxShadow: '0 -1px 2px rgba(0, 0, 0, 0.06)',
}));

// const NavWrapper = styled(Box)(({ theme }) => ({
//   height: '100%',
//   width: '100%',
//   display: 'flex',
//   alignItems: 'center',
//   '& .MuiButton-root': {
//     fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, sans-serif',
//     fontSize: '0.85rem',
//     // fontWeight: 600,
//     letterSpacing: '0.02em',
//     color: theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
//     transition: theme.transitions.create(['color', 'background-color'], {
//       duration: theme.transitions.duration.shorter,
//     }),
//     '&:hover': {
//       backgroundColor: theme.palette.mode === 'dark' ? 'rgba(148, 163, 184, 0.08)' : 'rgba(100, 116, 139, 0.08)',
//     },
//     '&.Mui-selected': {
//       color: theme.palette.mode === 'dark' ? '#4ade80' : '#16A34A',
//       backgroundColor: 'transparent',
//     }
//   }
// }));

// ----------------------------------------------------------------------
function NavbarHorizontal() {
  return (
    <RootStyle>
      {/* <NavWrapper> */}
        <NavSectionHorizontal navConfig={clientNavConfig} />
      {/* </NavWrapper> */}
    </RootStyle>
  );
}

export default memo(NavbarHorizontal);
