import React from 'react';
import { Typography, Box, IconButton, Grid, Button } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
// import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import axiosInstance from '../../../utils/axios';
import { Wrapper } from '../../../sections/@dashboard/Trade/watchlist/BuyScriptDrawer';

const DeleteTradeModal = ({ trade, isOpen, onClose, onUpdateSuccess }) => {
  //   const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteTrade = async () => {
    try {
      const response = await axiosInstance.delete(`/trade/manual/delete/${trade._id}`);
      enqueueSnackbar(response.data.message, { variant: 'success' });
      onUpdateSuccess();
      onClose();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return (
    <Wrapper open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: {
            xs: 'calc(100% - 32px)',
            sm: '450px',
            md: '500px'
          },
          maxHeight: {
            xs: 'calc(100vh - 32px)',
            sm: '90vh'
          },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: { xs: 2, sm: 3 },
          borderRadius: 2,
          overflow: 'auto'
        }}
      >
        <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" textAlign="center" pb={2}>
          Delete Trade
        </Typography>
        <Typography variant="body1" textAlign="center" pb={2}>
          Are you sure you want to delete this trade?
        </Typography>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={6}>
            <Button variant="contained" fullWidth size="large" onClick={handleDeleteTrade} color="error">
              Yes
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" fullWidth size="large" onClick={onClose}>
              No
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Wrapper>
  );
};

DeleteTradeModal.propTypes = {
  trade: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdateSuccess: PropTypes.func,
};

export default DeleteTradeModal;
