import { pxToRem, responsiveFontSizes } from '../utils/getFontValue';

// ----------------------------------------------------------------------

const FONT_PRIMARY = '"Inter", -apple-system, BlinkMacSystemFont, sans-serif'; // Main font
const FONT_SECONDARY = '"IBM Plex Sans", sans-serif'; // Financial data font
const FONT_MONO = '"IBM Plex Mono", monospace'; // For numbers/data

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  
  h1: {
    fontFamily: FONT_PRIMARY,
    // fontWeight: 700,
    lineHeight: 1.2,
    fontSize: pxToRem(40),
    letterSpacing: '-0.02em',
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },
  h2: {
    fontFamily: FONT_PRIMARY,
    // fontWeight: 700,
    lineHeight: 1.3,
    fontSize: pxToRem(32),
    letterSpacing: '-0.01em',
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h3: {
    fontFamily: FONT_PRIMARY,
    // fontWeight: 600,
    lineHeight: 1.4,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
  },
  h4: {
    fontFamily: FONT_PRIMARY,
    // fontWeight: 600,
    lineHeight: 1.4,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },
  h5: {
    fontFamily: FONT_PRIMARY,
    // fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },
  h6: {
    fontFamily: FONT_PRIMARY,
    // fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontFamily: FONT_SECONDARY,
    // fontWeight: 500,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
    letterSpacing: 0,
  },
  subtitle2: {
    fontFamily: FONT_SECONDARY,
    // fontWeight: 500,
    lineHeight: 1.5,
    fontSize: pxToRem(14),
    letterSpacing: 0,
  },
  body1: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
    letterSpacing: 0,
  },
  body2: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(14),
    letterSpacing: 0,
  },
  caption: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: 0,
  },
  overline: {
    fontFamily: FONT_PRIMARY,
    // fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
  },
  button: {
    fontFamily: FONT_PRIMARY,
    // fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(14),
    textTransform: 'none',
    letterSpacing: 0,
  },
  mono: {
    fontFamily: FONT_MONO,
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(14),
  },
};

export default typography;
