/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Box,
  Card,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
} from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// // hooks
// import useAuth from '../../../../hooks/useAuth';
// // utils
// import { fData } from '../../../../utils/formatNumber';
// // _mock
// import { countries } from '../../../../_mock';
// // components
// import {
//   FormProvider,
//   // RHFSwitch,
//   RHFSelect,
//   RHFTextField,
//   RHFUploadAvatar,
// } from '../../../../components/hook-form';

import ExchangeMargin from './ExchangeMargin';
import useResponsive from '../../../../hooks/useResponsive';

// ----------------------------------------------------------------------

export default function AccountConfig({ user }) {
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const isMobile = useResponsive('down', 'sm');

  const UpdateUserSchema = Yup.object().shape({
    displayName: Yup.string().required('Name is required'),
  });
  const defaultValues = {
    displayName: 'Mosam Gor' || '',
    email: 'mosam@gor.com' || '',
    phoneNumber: '8785002234' || '',
    country: 'India' || '',
    address: 'Isanpur' || '',
    state: 'Gujarat' || '',
    city: 'Ahmedabad' || '',
    zipCode: '380025' || '',
    about: '' || '',
    // isPublic: admin?.isPublic || false,
    // displayName: admin?.displayName || '',
    // email: admin?.email || '',
    // photoURL: admin?.photoURL || '',
    // phoneNumber: admin?.phoneNumber || '',
    // country: admin?.country || '',
    // address: admin?.address || '',
    // state: admin?.state || '',
    // city: admin?.city || '',
    // zipCode: admin?.zipCode || '',
    // about: admin?.about || '',
    // isPublic: admin?.isPublic || false,
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      enqueueSnackbar('Update success!');
    } catch (error) {
      console.error(error);
    }
  };
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'photoURL',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Card
        sx={{
          p: { xs: 1, sm: 2 },
          backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.5)' : '#ffffff',
          borderRadius: '8px',
        }}
      >
        <TableContainer
          sx={{
            overflowX: 'auto',
            backgroundColor: 'transparent',
            border: (theme) => `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}`,
            borderRadius: '8px',
            '& .MuiTableCell-root': {
              borderColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            },
            '& .MuiTableRow-root:hover': {
              backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.6)' : 'rgba(241, 245, 249, 0.8)',
              transition: 'background-color 0.2s ease-in-out'
            }
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: (theme) => 
                    theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.6)' : 'rgba(241, 245, 249, 0.8)',
                }}
              >
                <TableCell
                  sx={{
                    color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155',
                    fontFamily: '"Inter", "Roboto", sans-serif',
                    fontSize: '0.85rem',
                    // fontWeight: 600,
                    textAlign: 'center',
                    border: 'none',
                    padding: '12px 8px',
                  }}
                >
                  {isMobile ? 'EX' : 'EXCHANGE'}
                </TableCell>
                <TableCell
                  sx={{
                    color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155',
                    fontFamily: '"Inter", "Roboto", sans-serif',
                    fontSize: '0.85rem',
                    // fontWeight: 600,
                    textAlign: 'center',
                    border: 'none',
                    padding: '12px 8px',
                  }}
                >
                  {isMobile ? 'CO' : 'COMMODITY'}
                </TableCell>
                <TableCell
                  sx={{
                    color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155',
                    fontFamily: '"Inter", "Roboto", sans-serif',
                    fontSize: '0.85rem',
                    // fontWeight: 600,
                    textAlign: 'center',
                    border: 'none',
                    padding: '12px 8px',
                  }}
                >
                  {isMobile ? 'BR' : 'BROKERAGE'}
                </TableCell>
                <TableCell
                  sx={{
                    color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155',
                    fontFamily: '"Inter", "Roboto", sans-serif',
                    fontSize: '0.85rem',
                    // fontWeight: 600,
                    textAlign: 'center',
                    border: 'none',
                    padding: '12px 8px',
                  }}
                >
                  {isMobile ? 'IM' : 'INTRADAY MARGIN'}
                </TableCell>
                <TableCell
                  sx={{
                    color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155',
                    fontFamily: '"Inter", "Roboto", sans-serif',
                    fontSize: '0.85rem',
                    // fontWeight: 600,
                    textAlign: 'center',
                    border: 'none',
                    padding: '12px 8px',
                  }}
                >
                  {isMobile ? 'HM' : 'HOLDING MARGIN'}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {user?.NSE.allow && <ExchangeMargin type="NSE" config={user?.NSE} />}
              {user?.MCX.allow && <ExchangeMargin type="MCX" config={user?.MCX} />}
              {user?.COMEX.allow && <ExchangeMargin type="COMEX" config={user?.COMEX} />}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      {isMobile && (
        <Typography
          variant="caption"
          sx={{
            display: 'block',
            textAlign: 'center',
            mt: 2,
            color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
            fontFamily: '"Inter", "Roboto", sans-serif',
            fontSize: '0.75rem'
          }}
        >
          Ex-EXCHANGE || CO-COMMODITY || BR-BROKERAGE || IM-INTRADAY MARGIN || HM-HOLDING MARGIN
        </Typography>
      )}
    </Box>
  );
}
