/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { useState } from 'react';

// @mui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  // Checkbox,
  TableRow,
  TableCell,
  Typography,
  MenuItem,
  Checkbox,
  Tooltip,
  IconButton,
} from '@mui/material';
import { AutorenewOutlined, Edit, FileCopy, Refresh } from '@mui/icons-material';

// components
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import ActionTableCell from './ActionTableCell';
import { fNumber, fColorNumber } from '../../../../utils/formatNumber';
import RefreshBrokerageModal from '../../../../pages/dashboard/operator/RefreshBrokerageModal';

// ----------------------------------------------------------------------

UserTableRow.propTypes = {
  row: PropTypes.object,
  role: PropTypes.string,
  selected: PropTypes.bool,
  showEdit: PropTypes.bool,
  onViewRow: PropTypes.func,
  banUser: PropTypes.func,
  approveUser: PropTypes.func,
  rejectUser: PropTypes.func,
  onSelectRow: PropTypes.func,
  tableName: PropTypes.string,
  onClick: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onDuplicateRow: PropTypes.func,
  onUserUpdateSuccess: PropTypes.func,
};

export default function UserTableRow({
  row,
  role,
  selected,
  onViewRow,
  onClick,
  onDeleteRow,
  onEditRow,
  onSelectRow,
  onDuplicateRow,
  banUser,
  approveUser,
  rejectUser,
  tableName,
  onUserUpdateSuccess,
  showEdit = false,
}) {
  const theme = useTheme();

  // const {
  //   user_fname: userFName,
  //   user_lname: userLname,
  //   org_name: orgName,
  //   avatarUrl,
  //   user_number: userNumber,
  //   created_at: createdAt,
  //   role_title: roleTitle,
  //   // isVerified,
  //   status,
  // } = row;

  const {
    username,
    firstname,
    lastname,
    createdAt,
    isActive,
    balance,
    bookedPl,
    balanceDetail,
    profit,
    brokerage,
    netPl,
    manager,
    isDemoAccount,
    freeMargin,
  } = row;

  // const [openMenu, setOpenMenuActions] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  // const handleOpenMenu = (event) => {
  //   setOpenMenuActions(event.currentTarget);
  // };

  // const handleCloseMenu = () => {
  //   setOpenMenuActions(null);
  // };
  return (
    <TableRow hover selected={selected}>
      {/* {tableName && (
        <TableCell padding="checkbox">
          <Checkbox
            checked={selected && status === 'active'}
            onClick={onSelectRow}
            disabled={row?.status !== 'active'}
          />
        </TableCell>
      )} */}
      {showEdit && ['broker', 'admin'].includes(row?.role?.rolename) ? (
        <TableCell align="left" width={'10%'}>
          <Tooltip title="Edit">
            <IconButton onClick={onEditRow} sx={{ color: theme.palette.primary.main }}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Refresh Brokerage">
            <IconButton onClick={handleOpenModal} sx={{ color: theme.palette.primary.main }}>
              <AutorenewOutlined />
            </IconButton>
          </Tooltip>
        </TableCell>
      ) : (
        <ActionTableCell
          onDuplicateRow={onDuplicateRow}
          onEditRow={onEditRow}
          row={row}
          onUserUpdateSuccess={onUserUpdateSuccess}
        />
      )}
      <TableCell sx={{ cursor: 'pointer' }} onClick={onViewRow} align="left">
        {username || 'NA'}
      </TableCell>
      <TableCell sx={{ cursor: 'pointer' }} onClick={onViewRow} align="left">
        {firstname || lastname ? `${firstname} ${lastname}` : '-'}
      </TableCell>
      {((role === 'broker' && row.role.rolename !== 'client') ||
        (role === 'superAdmin' && row.role.rolename !== 'admin') ||
        (role === 'admin' && row.role.rolename !== 'admin')) && (
        <TableCell sx={{ cursor: 'pointer' }} align="left">
          {manager?.username || 'NA'}
        </TableCell>
      )}
      {row?.role?.rolename === 'client' && (
        <>
          <TableCell sx={{ cursor: 'pointer' }} align="left">
            {fNumber(balance || 0)}
          </TableCell>
          <TableCell
            sx={{ cursor: 'pointer', color: fColorNumber(balanceDetail?.totalProfit?.toFixed(0) || 0) }}
            align="left"
          >
            {fNumber(balanceDetail?.totalProfit?.toFixed(0) || 0)}
          </TableCell>
          <TableCell sx={{ cursor: 'pointer' }} align="left">
            {fNumber(balanceDetail?.totalBrokerage?.toFixed(0) || 0)}
          </TableCell>
          <TableCell
            sx={{
              cursor: 'pointer',
              color: fColorNumber(
                (balanceDetail?.totalProfit?.toFixed(0) || 0) - (balanceDetail?.totalBrokerage?.toFixed(0) || 0)
              ),
            }}
            align="left"
          >
            {fNumber((balanceDetail?.totalProfit?.toFixed(0) || 0) - (balanceDetail?.totalBrokerage?.toFixed(0) || 0))}
          </TableCell>
          <TableCell sx={{ cursor: 'pointer' }} align="left">
            {fNumber(
              (balanceDetail?.totalProfit?.toFixed(0) || 0) -
                (balanceDetail?.totalBrokerage?.toFixed(0) || 0) +
                balance -
                (balanceDetail?.blockedMargin || 0)
            )}
          </TableCell>
        </>
      )}
      {row.role.rolename === 'client' && (
        <TableCell sx={{ cursor: 'pointer' }} align="left">
          {isDemoAccount ? 'YES' : 'NO'}
        </TableCell>
      )}
      <TableCell sx={{ color: `${isActive ? '#16A34A' : 'red'}` }} align="left">
        {isActive ? 'Active' : 'Inactive'}
      </TableCell>
      <TableCell align="left">{(createdAt && new Date(createdAt).toLocaleDateString('en-GB')) || 'NA'}</TableCell>
      {openModal && (
        <RefreshBrokerageModal open={openModal} onClose={handleCloseModal} onUserUpdate={() => true} client={row} />
      )}
    </TableRow>
  );

  // return (
  //   <TableRow hover selected={selected}>
  //     {tableName && (
  //       <TableCell padding="checkbox">
  //         <Checkbox
  //           checked={selected && status === 'active'}
  //           onClick={onSelectRow}
  //           disabled={row?.status !== 'active'}
  //         />
  //       </TableCell>
  //     )}
  //     <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
  //       {/* <Avatar alt={userFName} src={avatarUrl} sx={{ mr: 2 }} /> */}
  //       <Typography variant="subtitle2" noWrap>
  //         {userFName && userLname ? `${userFName} ${userLname}` : 'NA'}
  //       </Typography>
  //     </TableCell>

  //     <TableCell align="left">{(orgName && orgName) || 'NA'}</TableCell>
  //     <TableCell align="left">{(userNumber && userNumber) || 'NA'}</TableCell>

  //     <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
  //       {(roleTitle && roleTitle) || 'NA'}
  //     </TableCell>
  //     <TableCell align="left">{(createdAt && new Date(createdAt).toLocaleDateString('en-GB')) || 'NA'}</TableCell>
  //     <TableCell align="center">
  //       <Iconify
  //         // icon={isVerified ? 'eva:checkmark-circle-fill' : 'eva:clock-outline'}
  //         icon={
  //           (status === 'active' && 'eva:checkmark-circle-fill') ||
  //           (status === 'pending' && 'eva:clock-outline') ||
  //           (status === 'rejected' && 'eva:close-circle-outline') ||
  //           (status === 'banned' && 'eva:alert-circle-outline')
  //         }
  //         sx={{
  //           width: 20,
  //           height: 20,
  //           color: 'success.main',
  //           // ...(!isVerified && { color: 'warning.main' }),
  //           ...((status === 'pending' && { color: 'warning.main' }) ||
  //             (status === 'rejected' && { color: 'reject.main' }) ||
  //             (status === 'banned' && { color: 'error.main' })),
  //         }}
  //       />
  //     </TableCell>

  //     <TableCell align="left">
  //       <Label
  //         variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
  //         color={
  //           (status === 'banned' && 'error') ||
  //           (status === 'pending' && 'warning') ||
  //           (status === 'rejected' && 'reject') ||
  //           'success'
  //         }
  //         sx={{
  //           textTransform: 'capitalize',
  //           // color: 'success.main',
  //           // ...((status === 'banned' && { color: 'error.main' }) ||
  //           //   (status === 'rejected' && { color: '#f57005' }) ||
  //           //   (status === 'pending' && { color: 'warning.main' })),
  //         }}
  //       >
  //         {status}
  //       </Label>
  //     </TableCell>

  //     <TableCell align="right">
  //       <TableMoreMenu
  //         open={openMenu}
  //         onOpen={handleOpenMenu}
  //         onClose={handleCloseMenu}
  //         actions={
  //           <>
  //             <MenuItem
  //               onClick={() => {
  //                 onViewRow();
  //                 handleCloseMenu();
  //               }}
  //             >
  //               <Iconify icon={'eva:eye-fill'} />
  //               View
  //             </MenuItem>
  //             <MenuItem
  //               onClick={async () => {
  //                 // onDeleteRow();
  //                 if (status === 'active') {
  //                   banUser();
  //                 } else {
  //                   approveUser();
  //                 }
  //                 handleCloseMenu();
  //               }}
  //               sx={{ color: `${status === 'active' ? 'error.main' : 'secondary.main'}` }}
  //             >
  //               <Iconify icon={status === 'active' ? 'eva:alert-circle-outline' : 'eva:checkmark-circle-2-outline'} />
  //               {status === 'active' ? 'Banned' : 'Approve'}
  //             </MenuItem>
  //             {status === 'pending' && (
  //               <MenuItem
  //                 onClick={async () => {
  //                   rejectUser();

  //                   handleCloseMenu();
  //                 }}
  //                 sx={{ color: 'error.main' }}
  //               >
  //                 <Iconify icon={'eva:close-circle-outline'} />
  //                 Reject
  //               </MenuItem>
  //             )}
  //           </>
  //         }
  //       />
  //     </TableCell>
  //   </TableRow>
  // );
}
