import React, { useEffect, useState } from 'react';
import { Tab, Tabs, AppBar, Box, Typography } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { useSelector } from 'react-redux';
import Page from '../../../../components/Page';
import { dispatch } from '../../../../redux/store';
import { getTradeHistory } from '../../../../redux/slices/trades';
import TradeCard from '../portfolio/TradeCard';
import useTrade from '../../../../hooks/useTrade';
import PortfolioHeader from '../portfolio/PortFolioHeader';

const TradesPage = () => {
  const {
    tradeHistory,
    quotesData,
    balance,
    bookedPl,
    blockedMargin,
    brokerage,
    priceChangeConfig,
    showAccountDetails,
  } = useSelector((state) => state?.trade);
  const [tabValue, setTabValue] = useState(0); // Use numeric index for tab management
  const { socket } = useTrade();

  // Update tabValue when the user clicks on a tab
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  // Update tabValue when the user swipes to a new tab
  const handleSwipeChangeIndex = (index) => {
    setTabValue(index);
  };

  // Filter trade history based on the selected tab value
  const filteredTradeHistory = (() => {
    switch (tabValue) {
      case 0: // ACTIVE
        return tradeHistory
          .filter((trade) => trade.type === 'OPEN')
          .map((trade) => ({
            ...trade,
            script: { ...quotesData[trade?.script?.tv_token], ...trade.script },
          }));

      case 1: // LIMITS
        return (
          tradeHistory
            .filter((trade) => ['PENDING'].includes(trade.type))
            .sort((a, b) => {
              // Define priority for each type
              const priority = { PENDING: 1, EXECUTED: 2, CANCELLED: 3 };
              // Compare based on the defined priority
              return priority[a.type] - priority[b.type];
            })
        );

      default:
        return tradeHistory; // Default case if none of the tabs match
    }
  })();

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getTradeHistory());
      } catch (error) {
        console.error('Error fetching trades:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const tokenArray =
      tradeHistory && tradeHistory.length > 0
        ? [
            ...tradeHistory
              .filter((trade) => ['OPEN', 'PENDING'].includes(trade.type))
              .map((trade) => trade?.script?.tv_token),
          ]
        : [];
    if (tokenArray.length) {
      const payload = tokenArray.map((token) => token);

      const sendSubscribeMessage = () => {
        socket.emit('subscribe', payload);
      };

      if (socket && socket.connected) {
        console.log('if send');
        sendSubscribeMessage();
      } else if (socket) {
        socket.on('connect', () => {
          sendSubscribeMessage();
        });
      }
    }

    return () => {
      if (socket && socket.connected) {
        if (tokenArray.length > 0) {
          socket.emit('unsubscribe', tokenArray);
        }
      }
    };
  }, [tradeHistory, socket, socket?.readyState]);

  return (
    <Page title="Portfolio" sx={{ 
      px: { xs: 1, sm: 2 }, 
      backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#0f172a' : '#f8fafc',
      minHeight: '100%',
      py: 2
    }}>
      <Box mt={0}>
        <PortfolioHeader
          balance={balance}
          bookedPL={bookedPl}
          blockedMargin={blockedMargin}
          brokerage={brokerage}
          activeTrades={tradeHistory
            .filter((trade) => trade.type === 'OPEN')
            .map((trade) => ({
              ...trade,
              script: { ...quotesData[trade?.script?.tv_token], ...trade.script },
            }))}
          priceChangeConfig={priceChangeConfig}
          showAccountDetails={showAccountDetails}
        />
      </Box>

      {/* Tab navigation */}
      <AppBar 
        position="static" 
        color="transparent" 
        elevation={0}
        sx={{
          borderRadius: '12px',
          overflow: 'hidden',
          marginTop: 2,
          backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.5)' : '#ffffff',
          boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          sx={{ 
            width: '100%', 
            display: 'flex',
            minHeight: '42px',
            '& .MuiTabs-indicator': {
              backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb',
              height: '3px'
            },
            '& .MuiTab-root': {
              fontFamily: '"Inter", "Roboto", sans-serif',
              fontSize: '0.85rem',
              // fontWeight: 600,
              color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
              textTransform: 'uppercase',
              letterSpacing: '0.05em',
              minHeight: '42px',
              padding: '4px 12px',
              '&.Mui-selected': {
                color: (theme) => theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb',
                // fontWeight: 700
              },
              '&:hover': {
                color: (theme) => theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb',
                opacity: 0.8
              }
            }
          }}
        >
          <Tab 
            label={`ACTIVE TRADES (${tradeHistory.filter(trade => trade.type === 'OPEN').length})`}
            value={0} 
            sx={{ flexGrow: 1, maxWidth: 'none' }} 
          />
          <Tab 
            label={`ACTIVE LIMITS (${tradeHistory.filter(trade => trade.type === 'PENDING').length})`}
            value={1} 
            sx={{ flexGrow: 1, maxWidth: 'none' }} 
          />
        </Tabs>
      </AppBar>

      {/* Swipeable views for tab content */}
      <SwipeableViews
        index={tabValue}
        onChangeIndex={handleSwipeChangeIndex}
        style={{ minHeight: 'calc(100vh - 200px)', marginTop: '-10px' }}
      >
        {/* Active Trades */}
        <Box 
          role="tabpanel" 
          hidden={tabValue !== 0} 
          sx={{ 
            minHeight: 'calc(100vh - 200px)',
            pt: 2,
            mt: '10px'  // Added to match Portfolio page
          }}
        >
          {filteredTradeHistory.length > 0 ? (
            filteredTradeHistory.map((trade) =>
              trade.type === 'OPEN' ? (
                <TradeCard
                  trade={{
                    ...trade,
                    script: { ...quotesData[trade?.script?.tv_token], ...trade.script },
                  }}
                  priceChangeConfig={priceChangeConfig}
                  key={trade._id}
                  closeFromTrade
                />
              ) : null
            )
          ) : (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '40vh',
              gap: 2,
              backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.4)' : '#ffffff',
              borderRadius: '12px',
              mt: 2
            }}>
              <Typography
                variant="h6"
                sx={{ 
                  fontFamily: '"Inter", "Roboto", sans-serif',
                  color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
                  fontSize: '0.875rem',
                  // fontWeight: 600,
                  textAlign: 'center'
                }}
              >
                NO ACTIVE TRADES
              </Typography>
            </Box>
          )}
        </Box>

        {/* Limits */}
        <Box 
          role="tabpanel" 
          hidden={tabValue !== 1} 
          sx={{ 
            minHeight: 'calc(100vh - 200px)',
            pt: 2
          }}
        >
          {filteredTradeHistory.length > 0 ? (
            filteredTradeHistory.map((trade) => {
              if (trade.type === 'PENDING') {
                return (
                  <TradeCard
                    key={trade._id}
                    trade={{
                      ...trade,
                      script: { ...quotesData[trade?.script?.tv_token], ...trade.script },
                    }}
                    priceChangeConfig={priceChangeConfig}
                    closeFromTrade
                  />
                );
              }
              return null;
            })
          ) : (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '40vh',
              gap: 2,
              backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.4)' : '#ffffff',
              borderRadius: '12px',
              mt: 2
            }}>
              <Typography
                variant="h6"
                sx={{ 
                  fontFamily: '"Inter", "Roboto", sans-serif',
                  color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
                  fontSize: '0.875rem',
                  // fontWeight: 600,
                  textAlign: 'center'
                }}
              >
                NO ACTIVE LIMITS
              </Typography>
            </Box>
          )}
        </Box>
      </SwipeableViews>
    </Page>
  );
};

export default TradesPage;
