import React, { useState, useEffect, useCallback, Suspense, lazy } from 'react';
import { TextField, Grid, IconButton, Box, Tabs, Tab, Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import SwipeableViews from 'react-swipeable-views';
import { AddOutlined, FileDownloadDoneOutlined, ModeEditOutlineOutlined } from '@mui/icons-material';
import Iconify from '../../../../components/Iconify';
import useTrade from '../../../../hooks/useTrade';
import Page from '../../../../components/Page';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useAuth from '../../../../hooks/useAuth';
import WatchListItem from './WatchListItem';
import LoadingState from './LoadingState';
import { dispatch } from '../../../../redux/store';
import { 
  getWatchList, 
  getUserPortfolio, 
  getWatchlistSuccess,
  addToWatchListSuccess 
} from '../../../../redux/slices/trades';

const StaticWatchListItem = lazy(() => 
  Promise.resolve()
    .then(() => import('./StaticWatchListItem'))
    .catch(error => {
      console.error('Error loading StaticWatchListItem:', error);
      return () => <div>Error loading content</div>;
    })
);

const LOCAL_STORAGE_KEY = 'selectedTab'; // Define a key to store the selected tab

const WatchlistRow = () => {
  const [searchText, setSearchText] = useState('');
  const { user } = useAuth();
  const segments = ['MCX', 'NSE', 'COMEX', 'WINDEX', 'NSEIX'];
  const userSegments = user ? segments.filter((segment) => user[segment]?.allow) : [];

  // Get initial tab from local storage or default to the first segment
  const getInitialTab = () => {
    const storedTab = localStorage.getItem(LOCAL_STORAGE_KEY);
    return storedTab && userSegments.includes(storedTab) ? storedTab : userSegments[0];
  };

  const [selectedTab, setSelectedTab] = useState(getInitialTab());
  const [showDelete, setShowDelete] = useState(false);
  // const { watchlist, userTrades } = useSelector((state) => state?.trade);
  const watchlist = useSelector((state) => state?.trade?.watchlist);
  const userTrades = useSelector((state) => state?.trade?.userTrades);
  const { socket } = useTrade();
  const navigate = useNavigate();

  // Fetch watchlist data from local storage or API
  useEffect(() => {
    dispatch(getWatchList());
    if (user.role.rolename === 'client') {
      dispatch(getUserPortfolio());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Sync selectedTab to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (userTrades?.OPEN && selectedTab) {
      const openTradesSymbols = userTrades.OPEN.filter((trade) => trade.script.exchange.name === selectedTab);
      const currentWatchlistScripts = watchlist && watchlist[selectedTab] ? Object.values(watchlist[selectedTab]) : [];
      const newScripts = openTradesSymbols
        .filter(
          (trade) => !currentWatchlistScripts.some((script) => script.tradingsymbol === trade.script.tradingsymbol)
        )
        .map((trade) => trade.script);

      if (newScripts.length > 0) {
        const updatedWatchlist = {
          ...watchlist,
          [selectedTab]: [...currentWatchlistScripts, ...newScripts],
        };
        const isUpdated = JSON.stringify(currentWatchlistScripts) !== JSON.stringify(updatedWatchlist[selectedTab]);

        if (isUpdated) {
          dispatch(getWatchlistSuccess(updatedWatchlist));
          localStorage.setItem('watchlist', JSON.stringify(updatedWatchlist));
        }
      }
    }
  }, [userTrades?.OPEN, selectedTab, watchlist]);

  useEffect(() => {
    const tokenArray =
      watchlist && watchlist[selectedTab] ? Object.values(watchlist[selectedTab]).map((script) => script.tv_token) : [];

    if (tokenArray.length) {
      const payload = tokenArray.map((token) => token);
      console.log('subscribing token', payload);
      const sendSubscribeMessage = () => {
        socket.emit('subscribe', payload);
      };

      if (socket && socket.connected) {
        sendSubscribeMessage();
        // setTimeout(() => {
        //   sendSubscribeMessage();
        // }, 100);
      } else if (socket) {
        console.log('else', socket);
        socket.on('connect', () => {
          sendSubscribeMessage();
          // setTimeout(() => {
          //   sendSubscribeMessage();
          // }, 100);
        });
      }
    }

    return () => {
      if (socket && socket.connected) {
        if (tokenArray.length > 0) {
          socket.emit('unsubscribe', tokenArray);
        }
      }
    };
  }, [watchlist, selectedTab, socket]);

  useEffect(() => {
    const currentDate = new Date();

    const removeExpiredScripts = () => {
      if (watchlist && selectedTab && watchlist[selectedTab]) {
        const updatedWatchlist = { ...watchlist };

        updatedWatchlist[selectedTab] = updatedWatchlist[selectedTab].filter((script) => {
          const expiryDate = new Date(script.squareoff_date);
          return expiryDate >= currentDate;
        });

        // Check if any scripts were removed
        if (updatedWatchlist[selectedTab].length !== watchlist[selectedTab].length) {
          localStorage.setItem('watchlist', JSON.stringify(updatedWatchlist));
          dispatch(getWatchlistSuccess(updatedWatchlist));
        }
      }
    };

    removeExpiredScripts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchlist, selectedTab, dispatch]);

  const filteredItems =
    watchlist && watchlist[selectedTab]
      ? Object.values(watchlist[selectedTab]).filter((script) => script.name.toLowerCase().includes(searchText.toLowerCase()))
      : [];

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleChangeTab = (event, newTab) => {
    setSelectedTab(newTab);
  };

  const handleWatchListClick = () => {
    if (user.role.rolename === 'client') {
      navigate(PATH_DASHBOARD.bankerRole.watchlist.add);
    }
    if (user.role.rolename === 'admin') {
      navigate(PATH_DASHBOARD.insurerRole.watchlist.add);
    }
    if (user.role.rolename === 'broker') {
      navigate(PATH_DASHBOARD.operatorRole.watchlist.add);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const items = [...filteredItems];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedWatchlist = { ...watchlist, [selectedTab]: items };
    localStorage.setItem('watchlist', JSON.stringify(updatedWatchlist));
    dispatch(getWatchlistSuccess(updatedWatchlist));
  };

  const handleDeleteItem = useCallback(
    (itemId) => {
      const updatedWatchlist = { ...watchlist };
      updatedWatchlist[selectedTab] = updatedWatchlist[selectedTab].filter((item) => item._id !== itemId);
      localStorage.setItem('watchlist', JSON.stringify(updatedWatchlist));
      dispatch(addToWatchListSuccess(updatedWatchlist));
    },
    [selectedTab, watchlist]
  );

  const handleEditChange = () => {
    setShowDelete((prev) => !prev);
  };

  return (
    <Page title={'Watchlist'} sx={{ 
      px: { xs: 0, sm: 1 }, 
      backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#0f172a' : '#f8fafc',
      minHeight: '100%',
      pt: 0
    }}>
      <Grid container spacing={0}>
        {user.role.rolename === 'client' ? (
          <Box sx={{ 
            position: 'sticky', 
            top: 0, 
            zIndex: 1100, 
            backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#1e293b' : '#ffffff',
            width: '100%',
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.06)',
            borderRadius: { xs: 0, sm: 1 },
            mt: 0
          }}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  py: 1.5,
                  px: { xs: 1.5, sm: 2 }
                }}>
                  {!showDelete && (
                    <IconButton 
                      sx={{ 
                        color: (theme) => theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb',
                        p: 0.75,
                        '&:hover': { 
                          backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(96, 165, 250, 0.08)' : 'rgba(37, 99, 235, 0.08)'
                        }
                      }} 
                      onClick={handleWatchListClick}
                    >
                      <AddOutlined sx={{ fontSize: 22 }} />
                    </IconButton>
                  )}

                  <Typography 
                    variant="h6" 
                    sx={{
                      flexGrow: 1,
                      textAlign: 'center',
                      fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, sans-serif',
                      // fontWeight: 600,
                      fontSize: { xs: '0.9375rem', sm: '1rem' },
                      color: (theme) => theme.palette.mode === 'dark' ? '#f8fafc' : '#1e293b',
                      letterSpacing: '-0.01em'
                    }}
                  >
                    WATCHLIST
                  </Typography>

                  {watchlist && !!Object.keys(watchlist).length && (
                    <IconButton 
                      sx={{ 
                        color: (theme) => theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb',
                        p: 0.75,
                        '&:hover': { 
                          backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(96, 165, 250, 0.08)' : 'rgba(37, 99, 235, 0.08)'
                        }
                      }} 
                      onClick={handleEditChange}
                    >
                      {showDelete ? (
                        <FileDownloadDoneOutlined sx={{ fontSize: 22 }} />
                      ) : (
                        <ModeEditOutlineOutlined sx={{ fontSize: 22 }} />
                      )}
                    </IconButton>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sx={{ px: { xs: 1.5, sm: 2 } }}>
                <Tabs
                  value={selectedTab}
                  onChange={handleChangeTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="watchlist tabs"
                  sx={{
                    minHeight: 36,
                    borderBottom: 1,
                    borderColor: 'divider',
                    '& .MuiTabs-indicator': {
                      backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb',
                      height: 2
                    },
                    '& .MuiTab-root': {
                      fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, sans-serif',
                      fontSize: '0.85rem',
                      color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
                      textTransform: 'uppercase',
                      letterSpacing: '0.05em',
                      minHeight: 36,
                      padding: '0 16px',
                      '&.Mui-selected': {
                        color: (theme) => theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb'
                      },
                      '&:hover': {
                        color: (theme) => theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb',
                        opacity: 0.8
                      }
                    }
                  }}
                >
                  {userSegments.map((exchange) => (
                    <Tab
                      key={exchange}
                      label={exchange}
                      value={exchange}
                      sx={{ minWidth: 'auto' }}
                    />
                  ))}
                </Tabs>
              </Grid>

              <Grid item xs={12} sx={{ p: { xs: 1.5, sm: 2 }, pt: 1.5 }}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Search Symbols"
                  value={searchText}
                  onChange={handleSearchChange}
                  InputProps={{
                    sx: {
                      fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, sans-serif',
                      fontSize: '0.875rem',
                      backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(15, 23, 42, 0.3)' : '#f8fafc',
                      height: 40,
                      '& input': {
                        color: (theme) => theme.palette.mode === 'dark' ? '#f8fafc' : '#1e293b',
                        '&::placeholder': {
                          color: (theme) => theme.palette.mode === 'dark' ? '#64748b' : '#94a3b8',
                          opacity: 1
                        }
                      },
                      '& fieldset': {
                        borderColor: (theme) => theme.palette.mode === 'dark' ? '#334155' : '#e2e8f0',
                        borderRadius: 1.5,
                        transition: 'border-color 0.2s',
                        '&:hover': {
                          borderColor: (theme) => theme.palette.mode === 'dark' ? '#4ade80' : '#16A34A'
                        }
                      },
                      '&.Mui-focused fieldset': {
                        borderWidth: '1.5px',
                        borderColor: (theme) => theme.palette.mode === 'dark' ? '#4ade80' : '#16A34A'
                      }
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box sx={{ position: 'sticky', top: 0, zIndex: 1100, backgroundColor: 'background.paper', width: '100%' }}>
            <Grid container spacing={2}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  pt: 2,
                }}
              >
                {!showDelete && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton color="primary" sx={{ padding: 0, mx: 1 }} onClick={handleWatchListClick}>
                      <Iconify icon={'eva:plus-circle-outline'} sx={{ fontSize: '40px' }} />
                    </IconButton>
                  </Box>
                )}

                {watchlist && !!Object.keys(watchlist).length && (
                  <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
                    <Button variant="contained" onClick={handleEditChange}>
                      {showDelete ? 'DONE' : 'EDIT'}
                    </Button>
                  </Box>
                )}
              </Box>
              <Grid item xs={12}>
                <Tabs
                  value={selectedTab}
                  onChange={handleChangeTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="watchlist tabs"
                  sx={{ width: '100%', display: 'flex' }}
                >
                  {userSegments.map((exchange) => (
                    <Tab label={exchange} value={exchange} key={exchange} sx={{ flexGrow: 1, maxWidth: 'none' }} />
                  ))}
                </Tabs>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Search Symbols" value={searchText} onChange={handleSearchChange} />
              </Grid>
            </Grid>
          </Box>
        )}
        
        <Grid item xs={12}>
          <SwipeableViews
            index={userSegments.indexOf(selectedTab)}
            onChangeIndex={(index) => setSelectedTab(userSegments[index])}
            style={{ height: 'calc(100vh - 160px)' }}
          >
            {userSegments.map((exchange) => (
              <Box 
                key={exchange}
                sx={{
                  backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#1e293b' : '#ffffff',
                  borderRadius: { xs: 0, sm: 1 },
                  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.06)',
                  height: '100%',
                  overflow: 'hidden'
                }}
              >
                {showDelete ? (
                  <WatchListItem
                    filteredItems={filteredItems}
                    handleDeleteItem={handleDeleteItem}
                    handleDragEnd={handleDragEnd}
                    showDelete={showDelete}
                    user={user}
                    selectedTab={selectedTab}
                  />
                ) : (
                  <Suspense fallback={<LoadingState />}>
                    <StaticWatchListItem
                      filteredItems={filteredItems}
                      handleDeleteItem={handleDeleteItem}
                      handleDragEnd={handleDragEnd}
                      showDelete={showDelete}
                      user={user}
                    />
                  </Suspense>
                )}
              </Box>
            ))}
          </SwipeableViews>
        </Grid>
      </Grid>
    </Page>
  );
};

export default WatchlistRow;
