/* eslint-disable react/prop-types */
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Delete, DragIndicator } from '@mui/icons-material';
import useResponsive from '../../../../hooks/useResponsive';
import { dispatch } from '../../../../redux/store';
import { addToWatchListSuccess, getWatchlistSuccess } from '../../../../redux/slices/trades';
import modifyScriptName from '../../../../utils/modifyScriptName';
import { fNumber } from '../../../../utils/formatNumber';

const WatchListItem = ({ showDelete, filteredItems, selectedTab }) => {
  const isMobile = useResponsive('down', 'sm');
  const watchlist = useSelector((state) => state?.trade?.watchlist);
  const userTrades = useSelector((state) => state?.trade?.userTrades);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const items = [...watchlist[selectedTab]];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedWatchlist = { ...watchlist, [selectedTab]: items };
    localStorage.setItem('watchlist', JSON.stringify(updatedWatchlist));
    dispatch(getWatchlistSuccess(updatedWatchlist));
  };

  const handleDeleteItem = (itemId) => {
    const updatedWatchlist = { ...watchlist };
    updatedWatchlist[selectedTab] = updatedWatchlist[selectedTab].filter((item) => item._id !== itemId);
    localStorage.setItem('watchlist', JSON.stringify(updatedWatchlist));
    dispatch(addToWatchListSuccess(updatedWatchlist));
  };

  // Filter userTrades.OPEN by selectedTab
  const openTradesForSelectedTab =
    userTrades?.OPEN?.filter((trade) => trade.script.exchange.name === selectedTab) || [];
  const openTradesSymbols = openTradesForSelectedTab.map((trade) => trade.script.tradingsymbol);

  // eslint-disable-next-line no-unused-vars
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(15, 23, 42, 0.3)' : '#f8fafc',
    },
    '& td': {
      padding: '8px 16px',
      fontSize: '0.875rem',
      fontFamily: '"Inter", "Roboto", sans-serif',
      color: theme.palette.mode === 'dark' ? '#f8fafc' : '#1e293b',
      letterSpacing: '-0.01em'
    },
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#334155' : '#f1f5f9',
      cursor: 'pointer',
      transition: 'background-color 0.2s ease'
    }
  }));

  // eslint-disable-next-line no-unused-vars
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    '&.MuiTableCell-head': {
      backgroundColor: theme.palette.mode === 'dark' ? '#1e293b' : '#ffffff',
      color: theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
      fontSize: '0.85rem',
      // fontWeight: 600,
      padding: '12px 16px',
      letterSpacing: '0.05em',
      textTransform: 'uppercase',
      fontFamily: '"Inter", "Roboto", sans-serif',
      borderBottom: 'none',
      '&.Mui-selected': {
        color: theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb',
      }
    }
  }));

  return (
    <Grid item xs={12}>
      {isMobile ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="watchlist">
            {(provided) => (
              <List 
                dense 
                {...provided.droppableProps} 
                ref={provided.innerRef}
                sx={{
                  backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#1e293b' : '#ffffff',
                  borderRadius: '8px',
                  overflow: 'hidden'
                }}
              >
                {filteredItems.map((item, index) => (
                  <Draggable key={item._id} draggableId={item._id} index={index}>
                    {(provided) => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        sx={{ 
                          borderBottom: '1px solid',
                          borderColor: (theme) => theme.palette.mode === 'dark' ? '#334155' : '#e2e8f0',
                          backgroundColor: (theme) => {
                            if (index % 2 === 0) {
                              return theme.palette.mode === 'dark' ? 'rgba(15, 23, 42, 0.3)' : '#f8fafc';
                            }
                            return theme.palette.mode === 'dark' ? '#1e293b' : '#ffffff';
                          },
                          '&:last-child': {
                            borderBottom: 'none'
                          },
                          '&:hover': {
                            backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#334155' : '#f1f5f9'
                          }
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            display: 'grid',
                            gridTemplateColumns: '48px 1fr auto',
                            alignItems: 'center',
                            gap: 1
                          }}
                        >
                          <Box
                            {...provided.dragHandleProps}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: '#64748b'
                            }}
                          >
                            <DragIndicator sx={{ fontSize: '20px' }} />
                          </Box>
                          
                          <ListItemText
                            primary={
                              <Typography 
                                sx={{
                                  fontFamily: '"Inter", "Roboto", sans-serif',
                                  // fontWeight: 600,
                                  fontSize: '0.875rem',
                                  color: (theme) => theme.palette.mode === 'dark' ? '#f8fafc' : '#334155'
                                }}
                              >
                                {modifyScriptName(item.tradingsymbol)}
                              </Typography>
                            }
                          />
                          
                          {showDelete && !openTradesSymbols.includes(item.tradingsymbol) && (
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteItem(item._id);
                              }}
                              sx={{ 
                                color: '#ef4444',
                                padding: '6px',
                                '&:hover': {
                                  backgroundColor: 'rgba(239, 68, 68, 0.1)'
                                }
                              }}
                            >
                              <Delete sx={{ fontSize: '20px' }} />
                            </IconButton>
                          )}
                        </Box>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <TableContainer 
          component={Paper}
          sx={{
            backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#1e293b' : '#ffffff',
            borderRadius: '8px',
            overflow: 'hidden',
            '&::-webkit-scrollbar': {
              width: '6px',
              height: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#4a5568' : '#cbd5e1',
              borderRadius: '3px',
              transition: theme => theme.transitions.create(['background-color'])
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#2d3748' : '#f1f5f9'
            }
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell width="48px" />
                <StyledTableCell>Trading Symbol</StyledTableCell>
                <StyledTableCell align="right">LTP</StyledTableCell>
                <StyledTableCell align="right">Change</StyledTableCell>
                <StyledTableCell align="right">% Change</StyledTableCell>
                {showDelete && <StyledTableCell align="right">Action</StyledTableCell>}
              </TableRow>
            </TableHead>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="watchlist">
                {(provided) => (
                  <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                    {filteredItems.map((item, index) => (
                      <Draggable key={item._id} draggableId={item._id} index={index}>
                        {(provided) => (
                          <StyledTableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <TableCell>
                              <Box
                                {...provided.dragHandleProps}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: '#64748b'
                                }}
                              >
                                <DragIndicator />
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{
                                  fontFamily: '"Inter", "Roboto", sans-serif',
                                  // fontWeight: 600,
                                  color: (theme) => theme.palette.mode === 'dark' ? '#f8fafc' : '#334155',
                                  fontSize: '0.875rem'
                                }}
                              >
                                {modifyScriptName(item.tradingsymbol)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right" sx={{ fontWeight: 600 }}>
                              {fNumber(item?.last_price || 0, item?.exchange?.name === 'COMEX' ? 4 : 2)}
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                sx={{
                                  color: +item?.chg < 0 ? '#ef4444' : '#16A34A',
                                  // fontWeight: 600,
                                  fontSize: '0.875rem'
                                }}
                              >
                                {fNumber(item?.chg || 0, item?.exchange?.name === 'COMEX' ? 4 : 2)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                sx={{
                                  color: (theme) => +item?.chg_per < 0 ? 
                                    theme.palette.error.main : 
                                    theme.palette.success.main,
                                  // fontWeight: 600,
                                  fontSize: '0.875rem'
                                }}
                              >
                                {item?.chg_per ? `${fNumber(item.chg_per, 2)}%` : '0.00%'}
                              </Typography>
                            </TableCell>
                            {showDelete && !openTradesSymbols.includes(item.tradingsymbol) && (
                              <TableCell align="right">
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteItem(item._id);
                                  }}
                                  sx={{ 
                                    color: '#ef4444',
                                    padding: '6px',
                                    '&:hover': {
                                      backgroundColor: 'rgba(239, 68, 68, 0.1)'
                                    }
                                  }}
                                >
                                  <Delete sx={{ fontSize: '20px' }} />
                                </IconButton>
                              </TableCell>
                            )}
                          </StyledTableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
};

export default WatchListItem;
