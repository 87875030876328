/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Drawer, Box, Typography, IconButton, Modal, Grid, InputAdornment } from '@mui/material';
import { Add, Close as CloseIcon, Remove } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
// import PriceWithColor from './PriceWithColor';
import RHFToggleButtonGroup from '../../../../components/hook-form/RHFToggleButtonGroup';
import axiosInstance from '../../../../utils/axios';
import modifyScriptName from '../../../../utils/modifyScriptName';
import useResponsive from '../../../../hooks/useResponsive';

export const Wrapper = ({ children, open, onClose }) => {
  const isMobile = useResponsive('down', 'sm');
  return isMobile ? (
    <Modal 
      open={open} 
      onClose={onClose}
      sx={{
        transition: theme => theme.transitions.create(['opacity']), 
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: {
            xs: 'calc(100% - 32px)', // Full width minus margins on mobile
            sm: '90%'
          },
          maxWidth: '500px',
          height: 'auto',
          minHeight: '480px',
          maxHeight: {
            xs: 'calc(100vh - 32px)', // Full height minus margins on mobile
            sm: '90vh'
          },
          bgcolor: theme => theme.palette.mode === 'dark' ? '#1e293b' : '#ffffff',
          border: 'none',
          borderRadius: '12px',
          p: { xs: 2, sm: 3 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxShadow: theme => theme.customShadows.z24,
          overflow: 'auto',
          transition: theme => theme.transitions.create(['background-color', 'box-shadow', 'height', 'width'], {
            duration: theme.transitions.duration.standard,
          }),
          '&:focus': {
            outline: 'none',
          },
          '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme => theme.palette.mode === 'dark' ? '#4a5568' : '#cbd5e1',
            borderRadius: '3px',
            transition: theme => theme.transitions.create(['background-color']),
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: theme => theme.palette.mode === 'dark' ? '#2d3748' : '#f1f5f9',
            transition: theme => theme.transitions.create(['background-color']),
          }
        }}
      >
        {children}
      </Box>
    </Modal>
  ) : (
    <Modal 
      open={open} 
      onClose={onClose}
      sx={{
        transition: theme => theme.transitions.create(['opacity'])
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: {
            sm: '450px',
            md: '500px'
          },
          height: 'auto',
          minHeight: '480px',
          maxHeight: '90vh',
          bgcolor: theme => theme.palette.mode === 'dark' ? '#1e293b' : '#ffffff',
          border: 'none',
          borderRadius: '12px',
          p: { xs: 2, sm: 3 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxShadow: theme => theme.customShadows.z24,
          overflow: 'auto',
          transition: theme => theme.transitions.create(['background-color', 'box-shadow', 'height', 'width'], {
            duration: theme.transitions.duration.standard,
          }),
          '&:focus': {
            outline: 'none',
          },
          '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme => theme.palette.mode === 'dark' ? '#4a5568' : '#cbd5e1',
            borderRadius: '3px',
            transition: theme => theme.transitions.create(['background-color']),
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: theme => theme.palette.mode === 'dark' ? '#2d3748' : '#f1f5f9',
            transition: theme => theme.transitions.create(['background-color']),
          }
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};

const QuoteValueItem = ({ label, value }) => (
  <Grid container spacing={1} alignItems="center">
    <Grid item xs={4}>
      <Typography 
        variant="body2"
        sx={{
          fontFamily: '"Inter", "Roboto", sans-serif',
          color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
          fontSize: '0.85rem',
          letterSpacing: '0.01em',
          whiteSpace: 'nowrap'
        }}
      >
        {label}
      </Typography>
    </Grid>
    <Grid item xs={8}>
      <Typography 
        variant="body2" 
        sx={{ 
          color: (theme) => theme.palette.mode === 'dark' ? '#f8fafc' : '#1e293b',
          fontSize: '0.85rem',
          letterSpacing: '0.01em'
        }}
      >
        {value}
      </Typography>
    </Grid>
  </Grid>
);

function BuyScriptDrawer({ open, onClose, script, isMobile }) {
  const { quotesData } = useSelector((state) => state?.trade);
  const [scriptQuote, setScriptQuote] = useState(null);
  const updatedData = quotesData[script?.tv_token];
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchScriptPrice = async () => {
      try {
        if (script) {
          const response = await axiosInstance.get(`/script/quote?scriptId=${script._id}`);
          const quote = Object.values(response.data.data)[0];
          setScriptQuote(quote);
        }
      } catch (err) {
        console.log('ERR', err);
      }
    };
    fetchScriptPrice();
  }, [script]);

  const defaultValues = {
    quantity: 1,
    limit: 0,
    orderType: 'MARKET',
  };

  const schema = yup.object().shape({
    quantity: yup
      .number()
      .typeError('Quantity must be a number')
      .required('Quantity is required')
      .positive('Quantity must be a positive number')
      .test('decimal-validation', 'Quantity must be multiple of 0.10', (value) => {
        if (['COMEX', 'NSEIX', 'WINDEX'].includes(script?.exchange?.name)) {
          return (value * 10) % 1 === 0; // Check if multiple of 0.10
        }
        return Number.isInteger(value);
      }),
    limit: yup.number().when('orderType', {
      is: 'LIMIT',
      then: yup
        .number()
        .typeError('Limit must be a number')
        .required('Limit is required')
        .positive('Limit must be a positive number'),
    }),
    orderType: yup.string().required('Order type is required').oneOf(['MARKET', 'LIMIT'], 'Invalid order type'),
  });

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = methods;

  const orderType = watch('orderType');

  useEffect(() => {
    if (orderType === 'LIMIT') {
      setValue('limit', updatedData.depth?.buy[0]?.price || 0);
    }
  }, [orderType, scriptQuote, setValue]);

  // console.log('SCRIPT', script);
  const onSubmit = async (data, event) => {
    try {
      if (data.orderType === 'MARKET') {
        delete data.limit;
      }
      data.scriptId = script._id;
      // data.side = event.nativeEvent.submitter.innerText;
      data.side = event.nativeEvent.submitter.getAttribute('data-side');
      const response = await axiosInstance.post('/trade/add', data);
      enqueueSnackbar(response.data.message);
      if (response.data.tradesCancelled) {
        enqueueSnackbar(response.data.cancelMessage, { persist: true });
      }
      onClose();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error', displayAsDialog: true });
    }
  };

  useEffect(() => {
    // console.log('METHODS', methods.getFieldState('orderType'));
  }, [methods.watch()]);

  const getBuyPriceDisplay = () => {
    if (updatedData?.depth?.sell[0]?.price) {
      return +updatedData.depth.sell[0].price.toFixed(2);
    }
    if (+updatedData?.high) {
      return +updatedData.close.toFixed(2);
    }
    return 0;
  };

  const getSellPriceDisplay = () => {
    if (updatedData?.depth?.buy[0]?.price) {
      return +updatedData.depth.buy[0].price.toFixed(2);
    }
    if (+updatedData?.high) {
      return +updatedData.close.toFixed(2);
    }
    return 0;
  };

  const getBidValue = () => {
    if (updatedData?.depth?.buy[0]?.price) {
      return +updatedData.depth.buy[0].price.toFixed(2);
    }
    if (+updatedData?.high) {
      return +updatedData.close.toFixed(2);
    }
    return 0;
  };

  const getAskValue = () => {
    if (updatedData?.depth?.sell[0]?.price) {
      return +updatedData.depth.sell[0].price.toFixed(2);
    }
    if (+updatedData?.high) {
      return +updatedData.close.toFixed(2);
    }
    return 0;
  };

  if (!script) return null;

  return (
    <Wrapper
      isMobile={isMobile}
      open={open}
      onClose={onClose}
      children={
        <Box width={{ xs: '100%', sm: '350px' }} marginX={'auto'} border={'none'}>
          <IconButton 
            sx={{ 
              position: 'absolute', 
              top: 8, 
              right: 8,
              color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
              '&:hover': { 
                color: (theme) => theme.palette.mode === 'dark' ? '#f8fafc' : '#1e293b',
                backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(148, 163, 184, 0.1)' : 'rgba(100, 116, 139, 0.1)'
              }
            }} 
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography 
            variant="h4" 
            textAlign={'center'} 
            pb={0.25} // Reduced from 0.5
            sx={{
              fontFamily: '"Inter", "Roboto", sans-serif',
              fontSize: '1.25rem', // Reduced from 1.5rem
              // fontWeight: 700,
              color: (theme) => theme.palette.mode === 'dark' ? '#f8fafc' : '#1e293b',
              letterSpacing: '-0.01em',
              mb: 1 // Reduced from 2
            }}
          >
            {modifyScriptName(script.tradingsymbol)}
          </Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} sx={{ width: '100%' }}>
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.5)' : 'rgba(241, 245, 249, 0.6)',
                borderRadius: '8px', // Reduced from 12px
                p: 1.5, // Reduced from 2
                mb: 1.5, // Reduced from 3
              }}
            >
              <RHFToggleButtonGroup
                name="orderType"
                label="Order Type"
                defaultValue={defaultValues.orderType}
                sx={{ 
                  width: '100%',
                  '& .MuiToggleButtonGroup-root': {
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    border: (theme) => `1px solid ${theme.palette.mode === 'dark' ? '#334155' : '#e2e8f0'}`,
                  },
                  '& .MuiToggleButton-root': {
                    fontFamily: '"Inter", "Roboto", sans-serif',
                    fontSize: '0.875rem',
                    color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
                    borderRadius: 0,
                    py: 0.75,
                    border: 'none',
                    '&.Mui-selected': {
                      color: (theme) => theme.palette.mode === 'dark' ? '#f8fafc' : '#1e293b',
                      backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#334155' : '#fff',
                      borderBottom: (theme) => `2px solid ${theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb'}`
                    },
                    '&:hover': {
                      backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(96, 165, 250, 0.08)' : 'rgba(37, 99, 235, 0.08)'
                    }
                  }
                }}
                options={[
                  { label: 'MARKET', value: 'MARKET' },
                  { label: 'LIMIT', value: 'LIMIT' },
                ]}
              />
            </Box>
            
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.5)' : 'rgba(241, 245, 249, 0.6)',
                borderRadius: '8px', // Reduced from 12px
                p: 1.5, // Reduced from 2
                mb: 1.5, // Reduced from 3
              }}
            >
              <RHFTextField
                name="quantity"
                label="Quantity"
                type="number"
                step={['COMEX', 'NSEIX', 'WINDEX'].includes(script?.exchange?.name) ? '0.10' : '1'}
                InputProps={{
                  sx: {
                    backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.6)' : '#fff',
                    borderRadius: '8px',
                    '& .MuiInputBase-input': {
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      // fontWeight: 500,
                      color: (theme) => theme.palette.mode === 'dark' ? '#f8fafc' : '#1e293b',
                    }
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        sx={{
                          backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.7)' : 'rgba(226, 232, 240, 0.7)',
                          borderRadius: '8px',
                          width: '28px',
                          height: '28px',
                          '&:hover': {
                            backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.9)' : 'rgba(226, 232, 240, 0.9)',
                          }
                        }}
                        onClick={() => {
                          const step = ['COMEX', 'NSEIX', 'WINDEX'].includes(script?.exchange?.name) ? 0.1 : 1;
                          const currentValue = Number((methods.getValues().quantity || 0).toFixed(2));
                          methods.setValue('quantity', Math.max(step, Number((currentValue - step).toFixed(2))));
                        }}
                      >
                        <Remove fontSize="small" sx={{ color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b' }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="center">
                      <IconButton
                        sx={{
                          backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.7)' : 'rgba(226, 232, 240, 0.7)',
                          borderRadius: '8px',
                          width: '28px',
                          height: '28px',
                          '&:hover': {
                            backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.9)' : 'rgba(226, 232, 240, 0.9)',
                          }
                        }}
                        onClick={() => {
                          const step = ['COMEX', 'NSEIX', 'WINDEX'].includes(script?.exchange?.name) ? 0.1 : 1;
                          const currentValue = Number((methods.getValues().quantity || 0).toFixed(2));
                          methods.setValue('quantity', Number((currentValue + step).toFixed(2)));
                        }}
                      >
                        <Add fontSize="small" sx={{ color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b' }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              
              {methods.getValues().orderType === 'LIMIT' && (
                <RHFTextField 
                  name="limit" 
                  label="Limit Price" 
                  type="number"
                  sx={{ mt: 2 }}
                  InputProps={{
                    sx: {
                      backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.6)' : '#fff',
                      borderRadius: '8px',
                      '& .MuiInputBase-input': {
                        fontFamily: '"Inter", "Roboto", sans-serif',
                        // fontWeight: 500,
                        color: (theme) => theme.palette.mode === 'dark' ? '#f8fafc' : '#1e293b',
                      }
                    }
                  }}
                />
              )}
            </Box>
            
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 1, mb: 1.5 }}>
              <LoadingButton
                type="submit"
                data-side="BUY"
                sx={{
                  flex: 1,
                  minHeight: '42px',
                  height: 'auto',
                  background: (theme) => theme.palette.mode === 'dark' ? '#4ade80' : '#16a34a',
                  color: '#ffffff',
                  '&:hover': { 
                    background: (theme) => theme.palette.mode === 'dark' ? '#22c55e' : '#15803d'
                  },
                  '&.Mui-disabled': {
                    backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#374151' : '#e5e7eb',
                    color: (theme) => theme.palette.mode === 'dark' ? '#6b7280' : '#9ca3af'
                  },
                  p: 0.75,
                  textTransform: 'uppercase',
                  display: 'flex',
                  flexDirection: 'column',
                  fontFamily: '"Inter", "Roboto", sans-serif',
                  fontSize: '0.875rem',
                  borderRadius: '8px',
                  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
                }}
                loading={isSubmitting}
              >
                {methods.getValues().orderType === 'MARKET' ? 'Buy' : 'Buy Order'}
                {methods.getValues().orderType === 'MARKET' && (
                  <Typography 
                    variant="h6"
                    sx={{
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      // fontWeight: 700,
                      fontSize: '1.125rem'
                    }}
                  >
                    {getBuyPriceDisplay()}
                  </Typography>
                )}
              </LoadingButton>
              <LoadingButton
                type="submit"
                data-side="SELL"
                sx={{
                  flex: 1,
                  minHeight: '42px',
                  height: 'auto',
                  background: (theme) => theme.palette.mode === 'dark' ? '#ef4444' : '#dc2626',
                  color: '#ffffff',
                  '&:hover': { 
                    background: (theme) => theme.palette.mode === 'dark' ? '#dc2626' : '#b91c1c'
                  },
                  '&.Mui-disabled': {
                    backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#374151' : '#e5e7eb',
                    color: (theme) => theme.palette.mode === 'dark' ? '#6b7280' : '#9ca3af'
                  },
                  p: 0.75,
                  textTransform: 'uppercase',
                  display: 'flex',
                  flexDirection: 'column',
                  fontFamily: '"Inter", "Roboto", sans-serif',
                  fontSize: '0.875rem',
                  borderRadius: '8px',
                  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
                }}
                loading={isSubmitting}
              >
                {methods.getValues().orderType === 'MARKET' ? 'Sell' : 'Sell Order'}
                {methods.getValues().orderType === 'MARKET' && (
                  <Typography 
                    variant="h6"
                    sx={{
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      // fontWeight: 700,
                      fontSize: '1.125rem'
                    }}
                  >
                    {getSellPriceDisplay()}
                  </Typography>
                )}
              </LoadingButton>
            </Box>
            
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.5)' : 'rgba(241, 245, 249, 0.6)',
                borderRadius: '8px', // Reduced from 12px
                p: 1.5, // Reduced from 2
              }}
            >
              <Typography 
                variant="subtitle2" 
                sx={{ 
                  fontFamily: '"Inter", "Roboto", sans-serif',
                  fontSize: '0.85rem',
                  // fontWeight: 600,
                  color: (theme) => theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
                  mb: 1, // Reduced from 1.5
                  textTransform: 'uppercase',
                  letterSpacing: '0.05em'
                }}
              >
                Market Information
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: 1, // Reduced from 2
                }}
              >
                <Box>
                  {methods.getValues().orderType === 'LIMIT' && (
                    <QuoteValueItem
                      label={'Bid'}
                      value={getBidValue()}
                    />
                  )}
                  <QuoteValueItem label={'Open'} value={updatedData?.open?.toFixed(2) || 'N/A'} />
                  <QuoteValueItem label={'High'} value={updatedData?.high?.toFixed(2) || 'N/A'} />
                  <QuoteValueItem label={'UC'} value={scriptQuote?.upper_circuit_limit?.toFixed(2) || 'N/A'} />
                </Box>
                <Box>
                  {methods.getValues().orderType === 'LIMIT' && (
                    <QuoteValueItem
                      label={'Ask'}
                      value={getAskValue()}
                    />
                  )}
                  <QuoteValueItem label={'Close'} value={updatedData?.close?.toFixed(2) || 'N/A'} />
                  <QuoteValueItem label={'Low'} value={updatedData?.low?.toFixed(2) || 'N/A'} />
                  <QuoteValueItem label={'LC'} value={scriptQuote?.lower_circuit_limit?.toFixed(2) || 'N/A'} />
                </Box>
              </Box>
            </Box>
          </FormProvider>
        </Box>
      }
    />
  );
}

export default BuyScriptDrawer;
