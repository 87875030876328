import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Divider, Grid, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { fDateTime } from '../../../utils/formatTime';
import { Wrapper } from '../../../sections/@dashboard/Trade/watchlist/BuyScriptDrawer';

// const modalStyle = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 600,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

const fieldStyle = {
  fontWeight: 'bold',
};

function ActivityDetailLogModal({ open, onClose, log }) {
  const renderDetail = (label, value) => (
    <Grid container spacing={2} sx={{ mb: 1 }}>
      <Grid item xs={4} sx={fieldStyle}>
        <Typography variant="body2" textAlign={'left'}>
          {label}:
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography
          variant="body2"
          textAlign={'left'}
          sx={{
            color:
              (label === 'ErrorMessage' && 'red') ||
              (label === 'Status' && value === 'fail' && 'red') ||
              (label === 'Status' && value === 'success' && '#16A34A'),
            textTransform: label === 'Status' ? 'uppercase' : '',
          }}
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Wrapper open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: {
            xs: 'calc(100% - 32px)', 
            sm: '450px',
            md: '500px'
          },
          maxHeight: {
            xs: 'calc(100vh - 32px)',
            sm: '90vh'
          },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: { xs: 2, sm: 3 },
          borderRadius: 2,
          overflow: 'auto'
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            backgroundColor: '#fff', // Adding white background for the close button
            '&:hover': { backgroundColor: '#f0f0f0' }, // Hover effect for the close button
          }}
        >
          <Close sx={{ color: '#3f3f3f' }} />
        </IconButton>
        <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
          Log Details
        </Typography>
        <Divider sx={{ marginY: 2 }} />
        {log && (
          <>
            {renderDetail('Type', log.type)}
            {renderDetail('Message', log.message)}
            {renderDetail('Date', fDateTime(log.createdAt))}
            {renderDetail('User', `${log.userId?.username} (${log.userId?.role?.rolename})`)}
            {renderDetail('Creator', `${log.creator?.username} (${log.creator?.role?.rolename})`)}
            {renderDetail(
              'Device Info',
              `${log.deviceDetail?.os?.name || 'Unknown'} ${log.deviceDetail?.os?.version || 'Unknown'} - ${
                log.deviceDetail?.device?.brand || 'Unknown'
              } ${log.deviceDetail?.device?.model || 'Unknown'}`
            )}
            {renderDetail('IP Address', log.ipAddress)}
            {renderDetail('Status', log.status)}
            {log.status === 'fail' && renderDetail('ErrorMessage', log?.errormessage)}
          </>
        )}
      </Box>
    </Wrapper>
  );
}

ActivityDetailLogModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  log: PropTypes.object,
};

export default ActivityDetailLogModal;
