/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Typography, Box, Grid, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import modifyScriptName from '../../../../utils/modifyScriptName';
import { fDateTime, fMonthDateAllTimes } from '../../../../utils/formatTime';
import { fNumber } from '../../../../utils/formatNumber';

const TradeHistoryRow = ({ trade }) => {
  const { type, buy, sell, buyBrokerage, sellBrokerage, boughtAt, soldAt, script, profit, side, updatedAt } = trade;
  const theme = useTheme();

  const getTradeColor = () => {
    if (['OPEN', 'ORDER', 'PENDING'].includes(type)) {
      return theme => side === 'BUY'
        ? theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb'  // Blue for buy
        : theme.palette.mode === 'dark' ? '#ef4444' : '#dc2626'; // Red for sell
    }
    return theme => theme.palette.mode === 'dark' ? '#f8fafc' : '#1e293b';
  };

  const getProfitColor = () => {
    return theme => profit > 0
      ? theme.palette.mode === 'dark' ? '#4ade80' : '#16A34A'  // Green for profit
      : theme.palette.mode === 'dark' ? '#ef4444' : '#dc2626'; // Red for loss
  };

  return (
    <Paper
      elevation={0}
      sx={{
        mb: 0.5,
        borderRadius: '6px',
        overflow: 'hidden',
        backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#1e293b' : '#ffffff',
        boxShadow: '0 1px 2px rgba(0,0,0,0.08)',
      }}
    >
      <Box sx={{ p: 1 }}>
        {/* Header - Script Name and Trade Type */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.75 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.75 }}>
            <Typography 
              variant="subtitle2" 
              sx={{ 
                fontFamily: '"Inter", "Roboto", sans-serif',
                color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155',
                fontSize: '0.85rem'
              }}
            >
              {modifyScriptName(script.tradingsymbol)}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontFamily: '"Inter", "Roboto", sans-serif',
                color: getTradeColor,
                textTransform: 'uppercase',
                fontSize: '0.75rem'
              }}
            >
              {type === 'ORDER' && `Close ${side === 'BUY' ? 'Sell' : 'Buy'}`}
              {type === 'CLOSE' && `${side.toLowerCase()}`}
              {type === 'OPEN' && `${side.toLowerCase()}`}
              {['EXECUTED', 'CANCELLED'].includes(type) &&
                `Limit ${type.toLowerCase()} ${side.toLowerCase()}`}
            </Typography>
          </Box>
          
          <Typography 
            variant="caption"
            sx={{
              fontFamily: '"Inter", "Roboto", sans-serif',
              fontSize: '0.75rem',
              color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
            }}
          >
            {script.exchange.name}
          </Typography>

          {type === 'ORDER' && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography 
                variant="caption"
                sx={{
                  fontFamily: '"Inter", "Roboto", sans-serif',
                  fontSize: '1.0rem',
                  color: getProfitColor()
                }}
              >
                {fNumber(profit)}
              </Typography>
            </Box>
          )}
        </Box>



        {/* Trade Details Grid */}
        <Grid container spacing={1}>
          {/* Left Column - Buy/Sell Details */}
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.25 }}>
              {/* Buy Details */}
              {buy && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography 
                    variant="caption"
                    sx={{
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      fontSize: '0.75rem',
                      color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b'
                    }}
                  >
                    Buy:
                  </Typography>
                  <Typography 
                    variant="caption"
                    sx={{
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      fontSize: '0.75rem',
                      // fontWeight: 600,
                      color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155'
                    }}
                  >
                    {fNumber(buy)}
                  </Typography>
                </Box>
              )}

              {/* Sell Details */}
              {sell && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography 
                    variant="caption"
                    sx={{
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      fontSize: '0.75rem',
                      color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b'
                    }}
                  >
                    Sell:
                  </Typography>
                  <Typography 
                    variant="caption"
                    sx={{
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      fontSize: '0.75rem',
                      // fontWeight: 600,
                      color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155'
                    }}
                  >
                    {fNumber(sell)}
                  </Typography>
                </Box>
              )}

              {/* Quantity */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography 
                  variant="caption"
                  sx={{
                    fontFamily: '"Inter", "Roboto", sans-serif',
                    fontSize: '0.75rem',
                    color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b'
                  }}
                >
                  Qty:
                </Typography>
                <Typography 
                  variant="caption"
                  sx={{
                    fontFamily: '"Inter", "Roboto", sans-serif',
                    fontSize: '0.75rem',
                    // fontWeight: 600,
                    color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155'
                  }}
                >
                  {trade.type === 'ORDER'
                    ? trade.quantity
                    : trade.type === 'OPEN' || trade.type === 'CLOSE'
                    ? trade.side === 'BUY'
                      ? trade.buyQuantity
                      : trade.sellQuantity
                    : trade.quantity}
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Right Column - Time and P/L Details */}
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.25 }}>
              {/* Timestamps */}
              {type === 'ORDER' && (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography 
                      variant="caption"
                      sx={{
                        fontFamily: '"Inter", "Roboto", sans-serif',
                        fontSize: '0.75rem',
                        color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b'
                      }}
                    >
                      Buy:
                    </Typography>
                    <Typography 
                      variant="caption"
                      sx={{
                        fontFamily: '"Inter", "Roboto", sans-serif',
                        fontSize: '0.75rem',
                        color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155'
                      }}
                    >
                      {fDateTime(boughtAt)}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography 
                      variant="caption"
                      sx={{
                        fontFamily: '"Inter", "Roboto", sans-serif',
                        fontSize: '0.75rem',
                        color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b'
                      }}
                    >
                      Sell:
                    </Typography>
                    <Typography 
                      variant="caption"
                      sx={{
                        fontFamily: '"Inter", "Roboto", sans-serif',
                        fontSize: '0.75rem',
                        color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155'
                      }}
                    >
                      {fDateTime(soldAt)}
                    </Typography>
                  </Box>
                </>
              )}

{(type === 'OPEN' || type === 'CLOSE') && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Typography 
                      variant="caption"
                      sx={{
                        fontFamily: '"Inter", "Roboto", sans-serif',
                        fontSize: '0.75rem',
                        color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b'
                      }}
                    >
                      {'Brokerage:'}
                    </Typography>
                    <Typography 
                      variant="caption"
                      sx={{
                        fontFamily: '"Inter", "Roboto", sans-serif',
                        fontSize: '0.75rem',
                        color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155'
                      }}
                    >
                      {side === 'BUY' ? fNumber(buyBrokerage) : fNumber(sellBrokerage)}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Typography 
                      variant="caption"
                      sx={{
                        fontFamily: '"Inter", "Roboto", sans-serif',
                        fontSize: '0.75rem',
                        color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b'
                      }}
                    >
                      {side === 'BUY' ? 'Bought At:' : 'Sold At:'}
                    </Typography>
                    <Typography 
                      variant="caption"
                      sx={{
                        fontFamily: '"Inter", "Roboto", sans-serif',
                        fontSize: '0.75rem',
                        color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155'
                      }}
                    >
                      {side === 'BUY' ? fMonthDateAllTimes(boughtAt) : fMonthDateAllTimes(soldAt)}
                    </Typography>
                  </Box>
                  
                </Box>
              )}

              {/* Status Update Time for EXECUTED/CANCELLED */}
              {['EXECUTED', 'CANCELLED'].includes(type) && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography 
                    variant="caption"
                    sx={{
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      fontSize: '0.75rem',
                      color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b'
                    }}
                  >
                    {type === 'CANCELLED' ? 'Cancel:' : 'Update:'}
                  </Typography>
                  <Typography 
                    variant="caption"
                    sx={{
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      fontSize: '0.75rem',
                      color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155'
                    }}
                  >
                    {fDateTime(updatedAt)}
                  </Typography>
                </Box>
              )}

              {/* Profit/Loss and Brokerage */}
              {type === 'ORDER' && (
                <>
                  
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography 
                      variant="caption"
                      sx={{
                        fontFamily: '"Inter", "Roboto", sans-serif',
                        fontSize: '0.75rem',
                        color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b'
                      }}
                    >
                      Brk:
                    </Typography>
                    <Typography 
                      variant="caption"
                      sx={{
                        fontFamily: '"Inter", "Roboto", sans-serif',
                        fontSize: '0.75rem',
                        color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155'
                      }}
                    >
                      {fNumber(buyBrokerage + sellBrokerage)}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default TradeHistoryRow;
