import React, { useState } from 'react';
import { Box, Button, Container, MenuItem, Select, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/Page';
import useAuth from '../../hooks/useAuth';
import axiosInstance from '../../utils/axios';

const GenerateReport = () => {
  const [selectedWeek, setSelectedWeek] = useState('CURRENT');
  const [loading, setLoading] = useState(false); // Loading state
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleDownloadReport = async (week, userId, enqueueSnackbar) => {
    try {
      const response = await axiosInstance.get(`/trade/client-settlement-report/${userId}?week=${week}`, {
        responseType: 'blob',
      });
  
      const fileBlob = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = window.URL.createObjectURL(fileBlob);
      window.open(fileURL, '_blank');
    } catch (error) {
        console.log("errror response",error)
      if (error && error instanceof Blob) {
        // const { data } = error;
        if (error.type === 'application/json') {
          error.text().then((text) => {
            const jsonError = JSON.parse(text);
            enqueueSnackbar(jsonError.message || 'An error occurred.', { variant: 'error' });
          });
        } else {
          enqueueSnackbar('Unexpected error format received.', { variant: 'error' });
        }
      } else{
        enqueueSnackbar(`Error:  ${error.message}`, { variant: 'error' });
      }
    }
  };

  const handleGenerateReport = async () => {
    if (!user?._id) {
      enqueueSnackbar('User ID is missing. Please log in again.', { variant: 'error' });
      return;
    }
    setLoading(true); // Set loading to true
    try {
      await handleDownloadReport(selectedWeek, user._id, enqueueSnackbar);
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  return (
    <Page title="Generate Report">
      <Container>
        <Box sx={{ mb: 3, mt: 2, position: 'relative' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography
              variant="h6"
              sx={{
                color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155',
                fontSize: '1rem',
                fontWeight: 600,
              }}
            >
              Generate Bill
            </Typography>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
              sx={{
                color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
                borderColor: (theme) => theme.palette.mode === 'dark' ? '#334155' : '#e2e8f0',
                '&:hover': {
                  borderColor: (theme) => theme.palette.mode === 'dark' ? '#475569' : '#cbd5e1',
                  backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(51, 65, 85, 0.08)' : 'rgba(226, 232, 240, 0.08)'
                }
              }}
            >
              Back
            </Button>
          </Box>

          <Select
            value={selectedWeek}
            onChange={(e) => setSelectedWeek(e.target.value)}
            displayEmpty
            fullWidth
            sx={{ mb: 2 }}
          >
            <MenuItem value="CURRENT">Current Week</MenuItem>
            <MenuItem value="PREVIOUS">Previous Week</MenuItem>
            <MenuItem value="LAST_PREVIOUS">Last Previous Week</MenuItem>
          </Select>

          <Button
            variant="contained"
            onClick={handleGenerateReport}
            disabled={loading} // Disable button when loading
            sx={{ width: '100%',py:1,textTransform:'uppercase' }}
          >
            {loading ? 'Generating...' : 'Generate'}
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

export default GenerateReport;