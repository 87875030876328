import numeral from 'numeral';

// ----------------------------------------------------------------------
const numberFormatter = new Intl.NumberFormat('en-IN', {
  maximumFractionDigits: 3,
});

// const fractionalNumberFormatter = new Intl.NumberFormat('en-IN', {
//   maximumFractionDigits: 2,
// });

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

// export function fNumber(number) {
//   // return numberFormatter.format(number);
//   return numeral(number).format();
// }
export function fNumber(number, digits = 2) {
  if (number === '-') {
    return '-';
  }

  // Create a dynamic number formatter with specified decimal places
  const dynamicFormatter = new Intl.NumberFormat('en-IN', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });

  // Format the number
  const formattedNumber = dynamicFormatter.format(number);
  const [integerPart, decimalPart] = formattedNumber.split('.');

  // If no decimal part, return just the integer
  if (!decimalPart) return integerPart;

  // Return formatted number with smaller decimal font
  return (
    <>
      {integerPart}.<span style={{ fontSize: '0.75em' }}>{decimalPart}</span>
    </>
  );
}

export function fSideColor(side) {
  if (side === 'BUY') {
    return '#16A34A';
  }
  return 'red';
}

export function fNumberDecimal(number) {
  return numberFormatter.format(number);
  // return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function indianCurrencyToNumber(indianCurrencyString) {
  // Remove currency symbol (₹) and commas
  const sanitizedString = indianCurrencyString.replaceAll(/[^0-9.-]+/g, '');
  // Parse the sanitized string as a float
  const number = parseFloat(sanitizedString);
  // eslint-disable-next-line no-restricted-globals
  return isNaN(number) ? 0 : number; // Return 0 if parsing fails
}

export function fColorNumber(number) {
  if (number >= 0) return '#16A34A';
  if (number < 0) return 'red';
  return 'text';
}
