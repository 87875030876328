import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { broadCastMessageOperations } from '../../../redux/slices/operator';
import { dispatch } from '../../../redux/store';
import useSettings from '../../../hooks/useSettings';

const Messages = () => {
  const { themeMode } = useSettings();
  const messages = useSelector((state) => state?.operator?.broadCastMessage?.data || []);

  useEffect(() => {
    dispatch(broadCastMessageOperations());
  }, []);

  const sortedMessages = [...messages].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const formatDate = (date) => {
    const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
    return new Intl.DateTimeFormat('en-US', options).format(new Date(date));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        width: '100%',
        backgroundColor: 'transparent',
      }}
    >
      {sortedMessages.map((message) => (
        <Card
          key={message._id}
          sx={{
            backgroundColor: (theme) => 
              theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.4)' : 'rgba(241, 245, 249, 0.6)',
            borderRadius: '8px',
            transition: 'background-color 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: (theme) => 
                theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.6)' : 'rgba(241, 245, 249, 0.8)',
            }
          }}
        >
          <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
            <Typography
              dangerouslySetInnerHTML={{
                __html: message.message.replace(/\n/g, '<br />'),
              }}
              sx={{
                fontSize: '0.875rem',
                lineHeight: 1.5,
                color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155',
                fontFamily: '"Inter", "Roboto", sans-serif',
              }}
            />
            <Typography
              sx={{
                mt: 1,
                textAlign: 'right',
                fontSize: '0.85rem',
                color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
                fontFamily: '"Inter", "Roboto", sans-serif',
              }}
            >
              {formatDate(message.createdAt)}
            </Typography>
          </CardContent>
        </Card>
      ))}
      {messages.length === 0 && (
        <Typography
          sx={{
            textAlign: 'center',
            color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
            fontFamily: '"Inter", "Roboto", sans-serif',
            fontSize: '0.875rem',
            mt: 2
          }}
        >
          No messages to display
        </Typography>
      )}
    </Box>
  );
};

export default Messages;
