import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tab, Tabs, Typography, AppBar, Box, IconButton, TextField, Modal } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { FilterList, Search } from '@mui/icons-material';
// import { useTheme } from '@mui/material/styles';
import Page from '../../../../components/Page';
import { getFilterableTradeHistory } from '../../../../redux/slices/trades';
import TradeHistoryRow from './TradeHistoryRow';
import TradeHistoryFilterCard from '../../../../pages/dashboard/operator/TradeHistoryFilterCard';

const TradeHistory = () => {
  const dispatch = useDispatch();
  const { ftradeHistory, fbookedPl, fbrokerage } = useSelector((state) => state?.trade);
  // const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showFilterPopup, setShowFilterPopup] = useState(false);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSwipeChangeIndex = (index) => {
    setTabValue(index);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getFilterableTradeHistory());
      } catch (error) {
        console.error('Error fetching trades:', error);
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tabValue]);

  const handleSearchIconClick = () => setShowSearchBar((prev) => !prev);
  const handleFilterIconClick = () => setShowFilterPopup(true);
  const handleClosePopup = () => setShowFilterPopup(false);

  const filteredTradeHistory = ftradeHistory.filter((trade) => {
    const matchesTab =
      (tabValue === 0 && trade.type === 'ORDER') ||
      (tabValue === 1 && trade.type === 'LIMIT') ||
      (tabValue === 2 && (trade.type === 'CLOSE' || trade.type === 'OPEN')) ||
      (tabValue !== 0 && tabValue === 1 && tabValue !== 2 && ['EXECUTED', 'CANCELLED'].includes(trade.type));

    const matchesSearch = searchTerm
      ? trade?.script?.tradingsymbol && trade?.script?.tradingsymbol.toLowerCase().includes(searchTerm.toLowerCase())
      : true;

    return matchesTab && matchesSearch;
  });

  // eslint-disable-next-line react/prop-types
  const HeaderItem = ({ label, value }) => (
    <Box
      display="flex"
      flexDirection={{ xs: 'row', sm: 'row' }}
      justifyContent={'space-between'}
      flexGrow={1}
      alignItems={{ xs: 'flex-start', sm: 'center' }}
      sx={{ 
        padding: 1, 
        border: '1px solid',
        borderColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(145, 158, 171, 0.12)' : 'rgba(145, 158, 171, 0.24)',
        borderRadius: '8px',
        marginBottom: { xs: 1, sm: 0 },
        backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(145, 158, 171, 0.08)' : 'rgba(145, 158, 171, 0.04)'
      }}
    >
      <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
        {label}
      </Typography>
      <Typography variant="body2" sx={{ fontWeight: 600 }}>
        {value}
      </Typography>
    </Box>
  );

  return (
    <Page title="Trade History" sx={{ 
      px: { xs: 1, sm: 2 }, 
      backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#0f172a' : '#f8fafc',
      minHeight: '100%',
      py: 2,
    }}>
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        sx={{
          borderRadius: '12px',
          overflow: 'hidden',
          backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.5)' : '#ffffff',
          boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
          mb: 2
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 1
          }}
        >
          <IconButton 
            onClick={handleFilterIconClick}
            sx={{ 
              color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b'
            }}
          >
            <FilterList />
          </IconButton>

          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            sx={{
              minHeight: '42px',
              '& .MuiTab-root': {
                fontFamily: '"Inter", "Roboto", sans-serif',
                fontSize: '0.85rem',
                // fontWeight: 600,
                color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
                textTransform: 'uppercase',
                letterSpacing: '0.05em',
                minHeight: '42px',
                padding: '4px 12px',
                '&.Mui-selected': {
                  color: (theme) => theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb',
                  // fontWeight: 700
                },
                '&:hover': {
                  color: (theme) => theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb',
                  opacity: 0.8
                }
              },
              '& .MuiTabs-indicator': {
                backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb',
                height: '3px'
              }
            }}
          >
            <Tab label="TRADES" />
            <Tab label="LIMITS" />
            <Tab label="DEALS" />
          </Tabs>

          <IconButton 
            onClick={handleSearchIconClick}
            sx={{ 
              color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b'
            }}
          >
            <Search />
          </IconButton>
        </Box>
        
        {showSearchBar && (
          <Box sx={{ p: 2, backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.4)' : '#f1f5f9' }}>
            <TextField
              placeholder="Search by script name..."
              variant="outlined"
              fullWidth
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.6)' : '#ffffff',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(148, 163, 184, 0.2)' : 'rgba(100, 116, 139, 0.2)',
                  },
                  '&:hover fieldset': {
                    borderColor: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: (theme) => theme.palette.mode === 'dark' ? '#4ade80' : '#16A34A',
                  },
                },
              }}
            />
          </Box>
        )}
      </AppBar>

      <Modal
        open={showFilterPopup}
        onClose={handleClosePopup}
        aria-labelledby="filter-popup-title"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box sx={{ width: { xs: '90%', sm: '60%', md: '40%' } }}>
          <TradeHistoryFilterCard onClose={handleClosePopup} />
        </Box>
      </Modal>

      <SwipeableViews
        index={tabValue}
        onChangeIndex={handleSwipeChangeIndex}
        style={{ minHeight: 'calc(100vh - 160px)', marginTop: '10px'}}
      >
        <Box role="tabpanel" hidden={tabValue !== 0}>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: 1,pb:3 }}>
            {filteredTradeHistory.length > 0 ? (
              <>
                {filteredTradeHistory.map((trade) => (
                  <TradeHistoryRow trade={trade} key={trade._id} />
                ))}

                {!showSearchBar && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'row' },
                      justifyContent: 'space-between',
                      gap: 2,
                      mt: 2,
                      p: 2,
                      borderRadius: '12px',
                      backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.5)' : '#ffffff',
                      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
                    }}
                  >
                    <HeaderItem label="BOOKED P/L" value={fbookedPl?.toFixed(2)} />
                    <HeaderItem label="BROKERAGE" value={fbrokerage?.toFixed(2)} />
                    <HeaderItem label="GROSS P/L" value={(fbookedPl - fbrokerage)?.toFixed(2)} />
                  </Box>
                )}
              </>
            ) : (
              <Typography
                variant="h6"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                  py: 3,
                  color: 'text.secondary',
                  fontSize: { xs: '0.95rem', sm: '1.125rem' },
                  fontWeight: 500
                }}
              >
                NO TRADES
              </Typography>
            )}
          </Box>
        </Box>

        <Box role="tabpanel" hidden={tabValue !== 1}>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: 1 }}>
            {filteredTradeHistory.length > 0 ? (
              filteredTradeHistory.map((trade) => (
                <TradeHistoryRow trade={trade} key={trade._id} />
              ))
            ) : (
              <Typography
                variant="h6"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                  py: 3,
                  color: 'text.secondary',
                  fontSize: { xs: '0.95rem', sm: '1.125rem' },
                  fontWeight: 500
                }}
              >
                NO LIMITS
              </Typography>
            )}
          </Box>
        </Box>

        <Box role="tabpanel" hidden={tabValue !== 2}>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: 1,pb:4 }}>
            {filteredTradeHistory.length > 0 ? (
              filteredTradeHistory.map((trade) => (
                <TradeHistoryRow trade={trade} key={trade._id} />
              ))
            ) : (
              <Typography
                variant="h6"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                  py: 3,
                  color: 'text.secondary',
                  fontSize: { xs: '0.95rem', sm: '1.125rem' },
                  fontWeight: 500
                }}
              >
                NO DEALS
              </Typography>
            )}
          </Box>
        </Box>
      </SwipeableViews>
    </Page>
  );
};

export default TradeHistory;
