import React from 'react';
import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

const LoadingState = () => (
  <Box 
    sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '40vh',
      gap: 2
    }}
  >
    <LoadingButton
      loading
      sx={{
        '.MuiCircularProgress-root': {
          color: (theme) => theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb',
          width: '28px !important',
          height: '28px !important'
        }
      }}
    />
    <Typography 
      sx={{
        fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, sans-serif',
        color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
        fontSize: '0.85rem',
        letterSpacing: '0.02em'
      }}
    >
      Loading watchlist...
    </Typography>
  </Box>
);

export default LoadingState;