import { Container, Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Page from '../../../components/Page';
import Messages from '../operator/Messages';
import useSettings from '../../../hooks/useSettings';

export default function Notifications() {
  const navigate = useNavigate();
  const { themeMode } = useSettings();

  return (
    <Page title="Settings - Notifications">
      <Container>
        <Box sx={{ mt: 2.5 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography 
              variant="h6" 
              sx={{ 
                color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155',
                fontSize: '1rem',
                fontWeight: 600 
              }}
            >
              Notifications
            </Typography>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
              sx={{
                color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
                borderColor: (theme) => theme.palette.mode === 'dark' ? '#334155' : '#e2e8f0',
                '&:hover': {
                  borderColor: (theme) => theme.palette.mode === 'dark' ? '#475569' : '#cbd5e1',
                  backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(51, 65, 85, 0.08)' : 'rgba(226, 232, 240, 0.08)'
                }
              }}
            >
              Back
            </Button>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Messages />
          </Box>
        </Box>
      </Container>
    </Page>
  );
}