/* eslint-disable react/prop-types */
import React from 'react';
import { 
  Typography, 
  Box, 
  Accordion, 
  AccordionDetails, 
  Grid, 
  AccordionSummary,
  Paper,
  Divider,
  useTheme,
  useMediaQuery,
  alpha
} from '@mui/material';
import { ExpandMore, AccountBalanceWallet } from '@mui/icons-material';
import { isNaN } from 'lodash';
import { calculateActiveProfitLoss } from '../../../../utils/modifyScriptName';
import { setShowAccountDetails } from '../../../../redux/slices/trades';
import { dispatch } from '../../../../redux/store';
import { fNumber } from '../../../../utils/formatNumber';

const HeaderItem = ({ label, value, live }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let color = 'text.primary'; // default color

  if (live) {
    if (value > 0) {
      color = 'success.dark'; // Darker green for positive values
    } else if (value < 0) {
      color = 'error.main'; // Red for negative values
    }
  }
  return (
    <Box sx={{ 
      py: isMobile ? 0.5 : 0.75,
      px: 0.5,
      borderRadius: 1,
      '&:hover': { 
        bgcolor: alpha(theme.palette.background.default, 0.6) 
      }
    }}>
      <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
        <Grid item>
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ 
              // fontWeight: 500,
              fontSize: isMobile ? '0.85rem' : '0.875rem',
              whiteSpace: 'nowrap'
            }}
          >
            {label}
          </Typography>
        </Grid>
        <Grid item>
          <Typography 
            variant="body2" 
            color={color} 
            // fontWeight={live ? 700 : 600}
            sx={{ 
              transition: 'all 0.2s ease',
              fontSize: isMobile ? '0.85rem' : '0.875rem',
              whiteSpace: 'nowrap',
              ...(live && value !== 0 && {
                animation: 'pulse 1.5s infinite',
                '@keyframes pulse': {
                  '0%': { opacity: 0.8 },
                  '50%': { opacity: 1 },
                  '100%': { opacity: 0.8 },
                }
              })
            }}
          >
            {fNumber(value.toFixed(0))}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const PortfolioHeader = ({
  balance,
  bookedPL,
  brokerage,
  blockedMargin,
  activeTrades,
  priceChangeConfig,
  showAccountDetails,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  let activeProfitLoss = 0;

  if (priceChangeConfig) {
    const response = calculateActiveProfitLoss(activeTrades, priceChangeConfig);
    activeProfitLoss = response.overallProfitLoss;
  }
  if (isNaN(activeProfitLoss)) {
    activeProfitLoss = 0;
  }

  const ledgerBalance = balance + bookedPL - brokerage;
  const assetsValue = ledgerBalance + activeProfitLoss;
  const freeMargin = assetsValue - blockedMargin;

  const handleAccordionChange = (event, isExpanded) => {
    dispatch(setShowAccountDetails(isExpanded));
  };

  // Calculate the Net P/L value
  const netPL = bookedPL - brokerage + activeProfitLoss;

  // Helper function to determine the appropriate color based on value
  const getValueColor = (value) => {
    if (value > 0) return theme.palette.success.dark; // Darker green for positive values
    if (value < 0) return theme.palette.error.main;
    return 'text.secondary';
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper 
        elevation={3} 
        sx={{ 
          borderRadius: 2, 
          overflow: 'hidden',
          border: `1px solid ${alpha(theme.palette.primary.main, 0.15)}`,
          mb: 2,
          boxShadow: `0 4px 6px ${alpha(theme.palette.common.black, 0.08)}, 
                      inset 0 1px 0 ${alpha(theme.palette.common.white, 0.15)}`,
          position: 'relative',
          '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '100%',
            background: `linear-gradient(to bottom, 
                        ${alpha(theme.palette.background.paper, 0.6)}, 
                        ${theme.palette.background.paper})`,
            pointerEvents: 'none',
            zIndex: 0,
          }
        }}
      >
        <Accordion 
          disableGutters 
          expanded={showAccountDetails} 
          onChange={handleAccordionChange}
          sx={{ 
            boxShadow: 'none',
            '&:before': { display: 'none' },
            bgcolor: 'transparent',
            position: 'relative',
            zIndex: 1
          }}
        >
          <AccordionSummary 
            expandIcon={
              <ExpandMore 
                sx={{ 
                  color: theme.palette.primary.main,
                  fontSize: isMobile ? 24 : 28
                }} 
              />
            }
            sx={{ 
              px: isMobile ? 1 : 2,
              minHeight: 56,
              '&.Mui-expanded': {
                minHeight: 56,
                borderBottom: `1px solid ${alpha(theme.palette.divider, 0.5)}`
              }
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: isMobile ? 0.75 : 1.5,
              width: '100%',
              justifyContent: 'space-between' 
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: isMobile ? 0.75 : 1.5 }}>
                <AccountBalanceWallet 
                  sx={{ 
                    color: theme.palette.primary.black,
                    fontSize: isMobile ? 20 : 24,
                    filter: 'drop-shadow(0 1px 1px rgba(0,0,0,0.15))'
                  }} 
                />
                <Typography 
                  variant="h6" 
                  sx={{ 
                    // fontWeight: 700,
                    fontSize: isMobile ? '0.75rem' : '1.2rem',
                    textTransform: 'uppercase',
                    color: theme.palette.primary.white,
                    letterSpacing: isMobile ? '0.3px' : '0.5px'
                  }}
                >
                  Account Details
                </Typography>
              </Box>
              
              {!showAccountDetails && (
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: getValueColor(netPL),
                    fontSize: isMobile ? '0.75rem' : '1.2rem',
                    // fontWeight: 800,
                    mr: isMobile ? 0.5 : 2,
                    textShadow: (theme) => theme.palette.mode === 'dark' 
                      ? '0 1px 1px rgba(0,0,0,0.7), 0 2px 4px rgba(0,0,0,0.2)' 
                      : '0 1px 1px rgba(255,255,255,0.9), 0 -1px 1px rgba(0,0,0,0.2)',
                    letterSpacing: '0.01em',
                    transition: 'all 0.2s ease',
                    padding: '2px 8px',
                    borderRadius: '4px',
                    backgroundColor: (theme) => theme.palette.mode === 'dark'
                      ? 'rgba(255,255,255,0.05)'
                      : 'rgba(0,0,0,0.03)',
                    backdropFilter: 'blur(8px)',
                    '&:hover': {
                      transform: 'translateY(-1px)',
                      backgroundColor: (theme) => theme.palette.mode === 'dark'
                        ? 'rgba(255,255,255,0.08)'
                        : 'rgba(0,0,0,0.05)',
                    }
                  }}
                >
                  Gross : {fNumber(netPL.toFixed(0))}
                </Typography>
              )}
            </Box>
          </AccordionSummary>
          
          <AccordionDetails sx={{ p: isMobile ? 1.25 : 2, pt: isMobile ? 0.75 : 1.5 }}>
            <Grid container spacing={isMobile ? 0 : 2}>
              
            
              {/* First column */}
              <Grid item xs={6} sm={6} md={6} sx={{ pr: isMobile ? 0.5 : 'inherit' }}>
                <HeaderItem label="Credit" value={balance} />
                <HeaderItem label="Used Margin" value={blockedMargin} />
                <HeaderItem label="Active P/L" live value={activeProfitLoss} />
                <HeaderItem label="Free Margin"  value={freeMargin} />
              </Grid>
              
              {/* Second column */}
              <Grid item xs={6} sm={6} md={6} sx={{ pl: isMobile ? 0.5 : 'inherit' }}>
                <HeaderItem label="Booked P/L" value={bookedPL} />
                <HeaderItem label="Commission" value={brokerage} />
                <HeaderItem label="Net P/L" live value={bookedPL - brokerage} />
                <HeaderItem label="Gross P/L" live value={(netPL)} />
                <Divider sx={{ my: 0.5, opacity: 0 }} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </Box>
  );
};

export default PortfolioHeader;
