/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Typography, Box, Paper, Grid } from '@mui/material';
import { ArrowRightAltSharp } from '@mui/icons-material';
import modifyScriptName from '../../../../utils/modifyScriptName';
import CloseTradeModal from './CloseTradeModal';
import PriceWithColor from '../watchlist/PriceWithColor';
import { fNumber } from '../../../../utils/formatNumber';
import { fDateTime } from '../../../../utils/formatTime';
import useResponsive from '../../../../hooks/useResponsive';

const TradeCard = ({ trade, priceChangeConfig, closeFromTrade = false, closeFromPortfolio = false }) => {
  const { script, side, quantity, buy, sell, type, margin, boughtBy, soldBy, boughtAt, soldAt } = trade;
  const isMobile = useResponsive('down', 'sm');

  const [openModal, setOpenModal] = useState(false);
  let multiplicationFactor = 1;
  const currentPrice = script?.depth ? script.depth[side === 'BUY' ? 'buy' : 'sell'][0].price : 0;
  const currentSegement = trade?.script?.exchange?.name;
  if (priceChangeConfig && ['MCX', 'COMEX', 'WINDEX', 'NSEIX'].includes(currentSegement)) {
    multiplicationFactor =
      priceChangeConfig[currentSegement][trade.script.name] || priceChangeConfig[currentSegement].default || 1;
  }

  // Calculate active profit/loss
  const activeProfitLoss =
    side === 'BUY'
      ? (currentPrice - buy) * quantity * multiplicationFactor
      : (sell - currentPrice) * quantity * multiplicationFactor;

  const openModalHandler = () => setOpenModal(true);
  const closeModalHandler = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          mb: 0.75,
          borderRadius: '8px',
          overflow: 'hidden',
          backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#1e293b' : '#ffffff',
          boxShadow: '0 1px 2px rgba(0,0,0,0.08)',
          pointerEvents: openModal ? 'none' : 'auto',
          cursor: (closeFromTrade || closeFromPortfolio) ? 'pointer' : 'default',
          transition: 'all 0.2s ease',
          '&:hover': {
            transform: (closeFromTrade || closeFromPortfolio) ? 'translateY(-1px)' : 'none',
            boxShadow: (closeFromTrade || closeFromPortfolio) ? '0 4px 6px rgba(0,0,0,0.1)' : '0 1px 2px rgba(0,0,0,0.08)',
          }
        }}
        onClick={(closeFromTrade || closeFromPortfolio) ? openModalHandler : undefined}
      >
        <Grid container spacing={0} sx={{ p: 1.25 }}>
          {/* Left Column - Script and Side */}
          <Grid item xs={6} sm={5}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
              <Typography 
                variant="subtitle2"
                sx={{
                  fontFamily: '"Inter", "Roboto", sans-serif',
                  // fontWeight: 600,
                  fontSize: '0.85rem',
                  color: (theme) => theme.palette.mode === 'dark' ? '#f8fafc' : '#1e293b',
                  mr: 1
                }}
              >
                {modifyScriptName(script.tradingsymbol)}
              </Typography>
              <Typography 
                variant="caption" 
                sx={{
                  textTransform: 'capitalize',
                  color: side === 'BUY' 
                    ? (theme) => theme.palette.mode === 'dark' ? '#3b82f6' : '#2563eb'  // Blue for buy
                    : (theme) => theme.palette.mode === 'dark' ? '#ef4444' : '#dc2626', // Red for sell
                  fontFamily: '"Inter", "Roboto", sans-serif',
                  // fontWeight: 600,
                  fontSize: '0.75rem',
                }}
              >
                {side.toLowerCase()} {quantity}
              </Typography>
            </Box>
            
            {/* Buy/Sell Price */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
              <Typography 
                variant="caption"
                sx={{
                  fontFamily: '"Inter", "Roboto", sans-serif',
                  fontSize: '0.75rem',
                  // fontWeight: 600,
                  color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
                  mr: 0.5
                }}
              >
                {side === 'BUY' ? 'Buy:' : 'Sell:'}
              </Typography>
              <Typography 
                variant="caption"
                sx={{
                  fontFamily: '"Inter", "Roboto", sans-serif',
                  fontSize: '0.75rem',
                  // fontWeight: 600,
                  color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155',
                }}
              >
                {side === 'BUY' ? fNumber(buy) : fNumber(sell)}
              </Typography>
              
              {type === 'OPEN' && (
                <>
                  <ArrowRightAltSharp sx={{ 
                    fontSize: '14px', 
                    color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
                    mx: 0.25
                  }} />
                  <Typography 
                    variant="caption"
                    sx={{
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      fontSize: '0.75rem',
                      // fontWeight: 600,
                      color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155',
                    }}
                  >
                    {fNumber(currentPrice?.toFixed(2))}
                  </Typography>
                </>
              )}
            </Box>
            
            {/* Timestamp for closeFromTrade */}
            {closeFromTrade && (
              <Typography 
                variant="caption"
                sx={{
                  fontFamily: '"Inter", "Roboto", sans-serif',
                  fontSize: '0.75rem',
                  color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
                  display: 'block'
                }}
              >
                {trade.side === 'BUY'
                  ? `${fDateTime(boughtAt)} (${boughtBy})`
                  : `${fDateTime(soldAt)} (${soldBy})`}
              </Typography>
            )}
          </Grid>
          
          {/* Right Column - Margin and P/L */}
          <Grid item xs={6} sm={7} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {/* Margin Display */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5, width: '100%', justifyContent: 'flex-end' }}>
              <Typography 
                variant="caption"
                sx={{
                  fontFamily: '"Inter", "Roboto", sans-serif',
                  fontSize: '0.75rem',
                  // fontWeight: 600,
                  color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155',
                  textAlign: 'right',
                  minWidth: '50px'
                }}
              >
                {type === 'OPEN' ? (
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: +activeProfitLoss < 0 
                        ? (theme) => theme.palette.mode === 'dark' ? '#ef4444' : '#dc2626'  // Red for loss
                        : (theme) => theme.palette.mode === 'dark' ? '#16A34A' : '#16a34a', // Green for profit
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      // fontWeight: 700,
                      fontSize: '0.95rem',
                      textAlign: 'right',
                      minWidth: '70px'
                    }}
                  >
                    {fNumber(activeProfitLoss.toFixed(2))}
                  </Typography>
                ) : (
                  <PriceWithColor
                    price={script?.depth ? script?.depth[side === 'BUY' ? 'sell' : 'buy'][0].price : 0}
                    variant="body2"
                    digits={script?.exchange?.name === 'COMEX' && script?.name === 'SILVER' ? 4 : 2}
                    sx={{
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      // fontWeight: 700,
                      fontSize: '0.95rem',
                      textAlign: 'right',
                      minWidth: '70px'
                    }}
                  />
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <CloseTradeModal
        isOpen={openModal}
        onClose={closeModalHandler}
        trade={trade}
        updatedQuantity={quantity}
        closeFromTrade={closeFromTrade}
        closeFromPortfolio={closeFromPortfolio}
      />
    </>
  );
};

export default TradeCard;
