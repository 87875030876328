/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Typography, Box, Grid, Paper } from '@mui/material';
import modifyScriptName from '../../../../utils/modifyScriptName';
import { fDateTime } from '../../../../utils/formatTime';
import { fNumber } from '../../../../utils/formatNumber';

const TradesRow = ({ trade, showTime = false }) => {
  const { type, buy, sell, boughtAt, soldAt, script, profit, brokerage, side, updatedAt, boughtBy, soldBy } = trade;

  // Determine color based on trade type and side
  const getTradeColor = () => {
    if (['OPEN', 'ORDER', 'PENDING'].includes(type)) {
      if (side === 'BUY') {
        return theme => theme.palette.mode === 'dark' ? '#60a5fa' : '#2563eb';
      }
      return theme => theme.palette.mode === 'dark' ? '#ef4444' : '#dc2626';
    }
    return theme => theme.palette.mode === 'dark' ? '#f8fafc' : '#1e293b';
  };

  const getProfitColor = () => {
    return profit > 0 
      ? (theme => theme.palette.mode === 'dark' ? '#4ade80' : '#16a34a')  // Green for profit
      : (theme => theme.palette.mode === 'dark' ? '#ef4444' : '#dc2626'); // Red for loss
  };

  return (
    <Paper
      elevation={0}
      sx={{
        mb: 0.75,
        borderRadius: '12px',
        overflow: 'hidden',
        backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#1e293b' : '#ffffff',
        boxShadow: '0 1px 2px rgba(0,0,0,0.08)',
        transition: 'all 0.2s ease',
        '&:hover': {
          transform: 'translateY(-1px)',
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
        }
      }}
    >
      <Box sx={{ 
        p: 2,
        width: '100%',
        borderBottom: (theme) => `1px solid ${theme.palette.mode === 'dark' ? '#334155' : '#e2e8f0'}`
      }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          {/* First row */}
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 1 }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
              <Typography 
                variant="subtitle1"
                sx={{
                  fontFamily: '"Inter", "Roboto", sans-serif',
                  // fontWeight: 600,
                  fontSize: '0.875rem',
                  color: (theme) => theme.palette.mode === 'dark' ? '#f8fafc' : '#1e293b',
                }}
              >
                {modifyScriptName(script.tradingsymbol)}
              </Typography>
              <Typography 
                variant="subtitle1" 
                sx={{
                  textTransform: "capitalize",
                  color: getTradeColor(),
                  fontFamily: '"Inter", "Roboto", sans-serif',
                  // fontWeight: 600,
                  fontSize: '0.675rem',
                }}
              >
                {type === 'ORDER' && `${showTime ? `Close ${side === 'BUY' ? 'Sell ' : 'Buy '}` : ''}`}
                {type === 'OPEN' && ` New ${side.toLowerCase()}`}
                {['EXECUTED', 'CANCELLED'].includes(type) && `${type.toLowerCase()} ${side.toLowerCase()} `}
                Qty : {trade.quantity}
              </Typography>
            </Box>
            {type === 'ORDER' && !showTime && (
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  marginLeft: 'auto',
                  color: getProfitColor(),
                  fontFamily: '"Inter", "Roboto", sans-serif',
                  // fontWeight: 700,
                  fontSize: '0.875rem',
                }}
              >
                {fNumber(profit?.toFixed(2) || 0)}
              </Typography>
            )}
          </Box>
          
          {/* Additional information rows */}
          {type === 'ORDER' && showTime && (
            <Box 
              sx={{ 
                display: 'flex', 
                flexDirection: 'row', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                mt: 1,
                backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.4)' : 'rgba(241, 245, 249, 0.6)',
                borderRadius: '8px',
                p: 1.5
              }}
            >
              <Grid container columnGap={1} direction="column" display={'grid'} gridTemplateColumns={'1fr 3fr'}>
                <Grid item display={'grid'}>
                  <Typography 
                    variant="body2"
                    sx={{
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      fontSize: '0.85rem',
                      color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
                    }}
                  >
                    BoughtAt:
                  </Typography>
                  <Typography 
                    variant="body2"
                    sx={{
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      fontSize: '0.85rem',
                      color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
                      mt: 0.5
                    }}
                  >
                    SoldAt:
                  </Typography>
                </Grid>
                <Grid item display={'grid'}>
                  <Typography 
                    variant="body2"
                    sx={{
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      fontSize: '0.85rem',
                      color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155',
                    }}
                  >
                    {boughtAt ? fDateTime(boughtAt) : '-'}
                    {` (${boughtBy})`}
                  </Typography>
                  <Typography 
                    variant="body2"
                    sx={{
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      fontSize: '0.85rem',
                      color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155',
                      mt: 0.5
                    }}
                  >
                    {soldAt ? fDateTime(soldAt) : '-'}
                    {` (${soldBy})`}
                  </Typography>
                </Grid>
              </Grid>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  color: getProfitColor(),
                  fontFamily: '"Inter", "Roboto", sans-serif',
                  // fontWeight: 700,
                  fontSize: '1rem',
                }}
              >
                {fNumber(profit?.toFixed(2) || 0)}
              </Typography>
            </Box>
          )}
          
          {['EXECUTED', 'CANCELLED'].includes(type) && (
            <Box 
              sx={{ 
                mt: 1,
                backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.4)' : 'rgba(241, 245, 249, 0.6)',
                borderRadius: '8px',
                p: 1
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs={5}>
                  <Typography 
                    variant="body2"
                    sx={{
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      fontSize: '0.85rem',
                      // fontWeight: 500,
                      color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
                    }}
                  >
                    {type === 'CANCELLED' ? 'Cancelled At' : 'Updated At'}:
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography 
                    variant="body2"
                    sx={{
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      fontSize: '0.85rem',
                      // fontWeight: 500,
                      color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155',
                    }}
                  >
                    {fDateTime(updatedAt)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          
          {type === 'OPEN' && (
            <Box 
              sx={{ 
                mt: 1,
                backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(30, 41, 59, 0.4)' : 'rgba(241, 245, 249, 0.6)',
                borderRadius: '8px',
                p: 1
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs={5}>
                  <Typography 
                    variant="body2"
                    sx={{
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      fontSize: '0.85rem',
                      // fontWeight: 500,
                      color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
                    }}
                  >
                    {side === 'BUY' ? 'Bought At' : 'Sold At'}:
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography 
                    variant="body2"
                    sx={{
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      fontSize: '0.85rem',
                      // fontWeight: 500,
                      color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155',
                    }}
                  >
                    {fDateTime(side === 'BUY' ? boughtAt : soldAt)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          
          <Grid 
            container 
            spacing={2} 
          >
            <Grid item xs={['EXECUTED', 'PENDING', 'CANCELLED'].includes(trade.type) ? 12 : 6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                {buy && (
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography 
                      variant="body2"
                      sx={{
                        fontFamily: '"Inter", "Roboto", sans-serif',
                        fontSize: '0.85rem',
                        color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
                      }}
                    >
                      Buy:
                    </Typography>
                    <Typography 
                      variant="body2"
                      sx={{
                        fontFamily: '"Inter", "Roboto", sans-serif',
                        fontSize: '0.85rem',
                        // fontWeight: 600,
                        color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155',
                      }}
                    >
                      {fNumber(buy?.toFixed(2)) || '--'}
                    </Typography>
                  </Box>
                )}
                {sell && (
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 0.5 }}>
                    <Typography 
                      variant="body2"
                      sx={{
                        fontFamily: '"Inter", "Roboto", sans-serif',
                        fontSize: '0.85rem',
                        color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
                      }}
                    >
                      Sell:
                    </Typography>
                    <Typography 
                      variant="body2"
                      sx={{
                        fontFamily: '"Inter", "Roboto", sans-serif',
                        fontSize: '0.85rem',
                        // fontWeight: 600,
                        color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155',
                      }}
                    >
                      {fNumber(sell?.toFixed(2)) || '--'}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
            {['OPEN', 'ORDER'].includes(trade.type) && (
              <Grid item xs={6}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography 
                    variant="body2"
                    sx={{
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      fontSize: '0.85rem',
                      color: (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',
                    }}
                  >
                    Brokerage:
                  </Typography>
                  <Typography 
                    variant="body2"
                    sx={{
                      fontFamily: '"Inter", "Roboto", sans-serif',
                      fontSize: '0.85rem',
                      // fontWeight: 600,
                      color: (theme) => theme.palette.mode === 'dark' ? '#e2e8f0' : '#334155',
                    }}
                  >
                    {fNumber(brokerage?.toFixed(2)) || '--'}
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};

export default TradesRow;
